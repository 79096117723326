<template>
	<div class="questions" v-if="$can('question_view')">
		<v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
			<span>{{ message }}</span>
		</v-snackbar>
		<v-container>
			<v-layout column>
				<div v-if="view == 'default'">
					<v-card outlined class="mt-5 pa-3" style="border-radius: 25px;">
						<v-flex xs12 class="mt-10 mb-2">
							<div class="mx-5">
								<v-layout row wrap>
									<v-flex xs12 md4>
										<h1>Questions</h1>
									</v-flex>
									<v-flex xs12 md4>
										<div class="pa-1">
											<v-text-field class="text_field header" outlined dense label="Search"
												append-icon="mdi-undo-variant" @click:append="resetSearch()"
												v-on:keyup.enter="search" v-model="searchTerm"
												@input="enableSearch()"></v-text-field>
										</div>
									</v-flex>
									<v-flex xs12 md2>
										<div class="pa-1">
											<v-btn class="secondary white--text text-none" depressed block @click="search"
												:loading="searchLoader" :disabled="searchButtonDisabled">
												Search
												<v-icon right>mdi-magnify</v-icon>
											</v-btn>
										</div>
									</v-flex>
									<v-flex xs12 md2>
										<div class="pa-1">
											<v-btn block depressed class="primary text-none" @click="changeView('create')"
												v-if="$can('question_create')">
												Add Question
												<v-icon right>mdi-plus-circle-outline</v-icon>
											</v-btn>
										</div>
									</v-flex>
								</v-layout>
							</div>
						</v-flex>
						<div class="mx-5 mt-9">
							<v-progress-linear v-if="questionLoader" height="1" indeterminate
							color="primary"></v-progress-linear>
						</div>
						<v-divider class="mx-5"></v-divider>						
						<v-flex xs12 class="mt-5">
							<div v-if="questions.length == 0">
								<v-card elevation="0">
									<v-layout row wrap>
										<v-flex xs12 md1>
											<v-container fill-height fluid>
												<v-row align="center" justify="center">
													<v-col class="text-center">
														<v-icon large class="text--text">
															mdi-alert-circle-outline
														</v-icon>
													</v-col>
												</v-row>
											</v-container>
										</v-flex>
										<v-flex xs12 md11>
											<v-container fill-height fluid>
												<v-row align="center" justify="center">
													<v-col class="text-center"> No Question Found </v-col>
												</v-row>
											</v-container>
										</v-flex>
									</v-layout>
								</v-card>
							</div>
							<div v-else>
								<div class="hidden-sm-and-down">
									<v-card elevation="0" class="pa-2">
										<v-simple-table>
											<template v-slot:default>
												<thead class="header">
													<tr class="tableHeader">
														<th class="text-left text--text">Order</th>
														<th class="text-left text--text">Question</th>
														<th class="text-left text--text">Question Type</th>

														<th class="text-left text--text">Active</th>
														<th class="text-right text--text">Actions</th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="item in questions" :key="item.id">
														<td>
															{{ item.number }}
														</td>
														<td>{{ item.question }}</td>
														<td>{{ item.question_type.name }}</td>

														<td>
															<div v-if="item.active == 1">Yes</div>
															<div v-else>No</div>
														</td>
														<td>
															<div align="right">
																<v-tooltip top v-if="$can('question_view')">
																	<template v-slot:activator="{ on, attrs }">
																		<v-btn icon class="button mr-1 universal--text"
																			@click="showQuestion(item)">
																			<v-icon small> mdi-eye </v-icon>
																		</v-btn>
																	</template>
																	<span>View</span>
																</v-tooltip>
																<v-tooltip top v-if="$can('question_edit')">
																	<template v-slot:activator="{ on, attrs }">
																		<v-btn icon class="button mr-1 success--text"
																			@click="editQuestion(item)" v-bind="attrs"
																			v-on="on">
																			<v-icon small> mdi-pencil </v-icon>
																		</v-btn>
																	</template>
																	<span>Edit</span>
																</v-tooltip>

																<v-tooltip top v-if="$can('question_archive')">
																	<template v-slot:activator="{ on, attrs }">
																		<v-btn icon class="button mr-1 error--text"
																			@click="deleteQuestion(item)"
																			:loading="loading && questionIndex == item.id"
																			v-bind="attrs" v-on="on">
																			<v-icon small> mdi-delete </v-icon>
																		</v-btn>
																	</template>
																	<span>delete</span>
																</v-tooltip>

																<v-tooltip top
																	v-if="$can('question_activate') && item.active == 0">
																	<template v-slot:activator="{ on, attrs }">
																		<v-btn icon class="button mr-1 red--text"
																			@click="activateQuestion(item)"
																			:loading="activateLoading && questionIndex == item.id"
																			v-bind="attrs" v-on="on">
																			<v-icon small> mdi-toggle-switch-off-outline
																			</v-icon>
																		</v-btn>
																	</template>
																	<span>Activate Question</span>
																</v-tooltip>
																<v-tooltip top
																	v-if="$can('question_deactivate') && item.active == 1">
																	<template v-slot:activator="{ on, attrs }">
																		<v-btn icon class="button mr-1 green--text"
																			@click="deactivateQuestion(item)"
																			:loading="deactivateLoading && questionIndex == item.id"
																			v-bind="attrs" v-on="on">
																			<v-icon small> mdi-toggle-switch </v-icon>
																		</v-btn>
																	</template>
																	<span>Deactivate Question</span>
																</v-tooltip>
																<v-tooltip top v-if="$can('log_view')">
																	<template v-slot:activator="{ on, attrs }">
																		<v-btn icon class="button mr-1 purple--text"
																			@click="viewLog(item)" v-bind="attrs" v-on="on">
																			<v-icon small> mdi-script-text </v-icon>
																		</v-btn>
																	</template>
																	<span>Log</span>
																</v-tooltip>

															</div>
														</td>
													</tr>
												</tbody>
											</template>
										</v-simple-table>
									</v-card>

								</div>
								<div class="hidden-md-and-up">
									<v-layout column>
										<template v-for="(question, index) in questions">
											<div :key="index">
												<v-flex xs12 class="mb-2">
													<v-card elevation="0">
														<div class="pa-5">
															<v-layout column>
																<v-flex xs12>
																	<v-layout row wrap>
																		<v-flex xs11>
																			<div class="title primary--text ml-3">
																				<b>{{ question.number }}. {{ question.question }}</b>
																			</div>
																		</v-flex>
																		<v-flex xs1>
																			<v-btn depressed class="red--text" :loading="loading && questionIndex == question.id
																				" icon @click="deleteQuestion(question)" v-if="$can('question_archive')">
																				<v-icon> mdi-delete </v-icon>
																			</v-btn>
																		</v-flex>
																	</v-layout>
																</v-flex>
																<v-divider class="mt-2"></v-divider>
																<v-flex xs12 class="mt-1">
																	<v-layout column>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Question Type</b>
																			</div>
																			<div>{{ question.question_type.name }}</div>
																		</v-flex>

																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Active</b>
																			</div>
																			<div>
																				<div v-if="question.active == 1">Yes</div>
																				<div v-else>No</div>
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-5">
																			<v-layout row wrap>
																				<v-flex xs12>
																					<div class="pa-1">
																						<v-btn v-if="$can('question_view')"
																							depressed class="
                                                  primary
                                                  text-none
                                                  white--text
                                                " block @click="showQuestion(question)">View
																							<v-icon right> mdi-eye </v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																				<v-flex xs12>
																					<div class="pa-1">
																						<v-btn depressed
																							class="blue text-none white--text"
																							block
																							@click="editQuestion(question)"
																							v-if="$can('question_edit')">Edit
																							<v-icon right>
																								mdi-border-color
																							</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																				<v-flex xs12>
																					<div class="pa-1">
																						<v-btn depressed
																							class="green text-none white--text"
																							block
																							@click="activateQuestion(question)"
																							:loading="activateLoading && questionIndex == question.id"
																							v-if="$can('question_activate') && question.active == 0">Activate
																							Question
																							<v-icon right>
																								mdi-toggle-switch-off-outline
																							</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																				<v-flex xs12>
																					<div class="pa-1">
																						<v-btn depressed
																							class="red text-none white--text"
																							block
																							@click="deactivateQuestion(question)"
																							:loading="deactivateLoading && questionIndex == question.id"
																							v-if="$can('question_deactivate') && question.active == 1">Deactivate
																							Question
																							<v-icon right>
																								mdi-toggle-switch
																							</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																				<v-flex xs12>
																					<div class="pa-1">
																						<v-btn v-if="$can('log_view')"
																							depressed class="
                                                  purple
                                                  text-none
                                                  white--text
                                                " block @click="viewLog(question)">Log
																							<v-icon right> mdi-script-text
																							</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																			</v-layout>
																		</v-flex>
																	</v-layout>
																</v-flex>
															</v-layout>
														</div>
													</v-card>
												</v-flex>
											</div>
										</template>
									</v-layout>
								</div>
							</div>
						</v-flex>
					</v-card>

					<v-layout column class="mt-5">
						<v-flex xs12>
							<div align="center">
								<v-pagination v-if="length != 0" :length="length" total-visible="10"
									v-model="questionPagination.current_page" @input="changePage()" circle>
								</v-pagination>
							</div>
						</v-flex>
						<v-flex xs12>
							<div v-if="length != 0" align="center" class="mt-5">
								<b class="primary--text">Total: </b>{{ questionPagination.total | formatNumber }}
							</div>
						</v-flex>
					</v-layout>
				</div>
				<div v-if="view == 'create'">
					<v-flex class="mt-5">
						<v-card outlined>
							<v-card-title class="tableHeader text--text">
								<v-spacer></v-spacer>
								<div v-if="newQuestion.id == null">Add Question</div>
								<div v-else>Edit Question</div>
								<v-spacer></v-spacer>
								<v-btn icon @click="changeView('default')">
									<v-icon class="text--text"> mdi-close </v-icon>
								</v-btn>
							</v-card-title>
							<v-card-text>
								<v-form v-model="valid" ref="form" lazy-validation>
									<v-layout column>
										<v-flex xs12>
											<v-layout row wrap class="mt-5">
												<v-flex xs12 md4>
													<div class="mt-3 mx-1">
														<div>Question Type<span class="error--text">*</span></div>
														<v-select :items="formData.question_types"
															v-model="newQuestion.question_type_id"
															class="text_field background" item-value="id" item-text="name"
															outlined dense :rules="inputRules"></v-select>
													</div>
												</v-flex>
												<v-flex xs12 md7>
													<div class="mt-3 mx-1">
														<div>Question<span class="error--text">*</span></div>
														<v-text-field class="text_field background" outlined dense
															v-model="newQuestion.question"
															:rules="inputRules"></v-text-field>
													</div>
												</v-flex>
												<v-flex xs12 md1>
													<div class="mt-3 mx-1">
														<div>Number<span class="error--text">*</span></div>
														<v-text-field class="text_field background" outlined dense
															v-model="newQuestion.number" :rules="inputRules"></v-text-field>
													</div>
												</v-flex>
												<v-flex xs12 md10>
													<div class="mt-3 mx-1">
														<div>Description</div>
														<v-text-field class="text_field background" outlined dense
															v-model="newQuestion.description"></v-text-field>
													</div>
												</v-flex>
												<v-flex xs12 md1>
													<div class="mt-3 mx-1">
														<div>Characters</div>
														<v-text-field class="text_field background" outlined dense
															v-model="newQuestion.length"></v-text-field>
													</div>
												</v-flex>
												<v-flex xs12 md1>
													<div class="mt-9 mx-1">
														<v-checkbox v-model="newQuestion.required" :rules="inputRules"
														label="Required"></v-checkbox>
													</div>
												</v-flex>
											</v-layout>
										</v-flex>
										<v-flex xs12>
											<div v-if="newQuestion.question_type_id == 1" class="mt-3">

											</div>
											<div v-if="newQuestion.question_type_id == 2 || newQuestion.question_type_id == 3"
												class="mt-3">
												<div class="pa-3 mt-5">
													<v-layout column>
														<v-flex xs12>
															<div align="right">
																<v-btn depressed class="primary text-none mb-3"
																	@click="addSingleSelectMultipleChoiceOptionField()">
																	Add Option
																	<v-icon right> mdi-plus </v-icon>
																</v-btn>
															</div>
														</v-flex>
														<v-form v-model="valid" ref="singleSelectMultipleChoiceOptionForm"
															lazy-validation>
															<template
																v-for="(singleSelectMultipleChoiceOption, index) in newQuestion.single_select_multiple_choice_options">
																<div :key="index">
																	<v-flex xs12 class="mt-4">
																		<v-layout row wrap>
																			<v-flex xs12 md1>
																				<div class="mt-2">Option {{ index + 1 }}<span
																						class="error--text">*</span></div>
																			</v-flex>
																			<v-flex xs11 md10>
																				<v-text-field class="text_field background"
																					outlined dense
																					v-model="newQuestion.single_select_multiple_choice_options[index].value"
																					:rules="inputRules">
																				</v-text-field>
																			</v-flex>
																			<v-flex xs1>
																				<div class="mx-3">
																					<v-btn icon class="red--text"
																						@click="removeSingleSelectMultipleChoiceOption(index)">
																						<v-icon> mdi-close-circle </v-icon>
																					</v-btn>
																				</div>
																			</v-flex>
																		</v-layout>
																	</v-flex>
																</div>
															</template>
														</v-form>
													</v-layout>
												</div>
											</div>
											<div v-if="newQuestion.question_type_id == 4 || newQuestion.question_type_id == 5"
												class="mt-3">
												<div class="pa-3 mt-5">
													<v-layout column>
														<v-flex xs12>
															<div align="right">
																<v-btn depressed class="primary text-none mb-3"
																	@click="addMultiPartSingleSelectMultipleChoiceQuestionField()">
																	Add Question
																	<v-icon right> mdi-plus </v-icon>
																</v-btn>
															</div>
														</v-flex>
														<v-form v-model="valid"
															ref="multiPartSingleSelectMultipleChoiceQuestionForm"
															lazy-validation>
															<template
																v-for="(multiPartSingleSelectMultipleChoiceQuestion, index) in newQuestion.multi_part_single_select_multiple_choice_questions">
																<div :key="index">

																	<v-flex xs12 class="mt-10">
																		<v-layout row wrap>
																			<v-flex xs12 md1>
																				<div class="mt-2">Question {{ index + 1
																				}}<span class="error--text">*</span>
																				</div>
																			</v-flex>
																			<v-flex xs11 md10>
																				<v-text-field class="text_field background"
																					outlined dense
																					v-model="newQuestion.multi_part_single_select_multiple_choice_questions[index].value"
																					:rules="inputRules">
																				</v-text-field>
																			</v-flex>
																			<v-flex xs1>
																				<div class="mx-3">
																					<v-btn icon class="red--text"
																						@click="removeMultiPartSingleSelectMultipleChoiceQuestion(index)">
																						<v-icon> mdi-close-circle </v-icon>
																					</v-btn>
																				</div>
																			</v-flex>
																		</v-layout>
																	</v-flex>
																	<v-flex xs12 class="mt-4">
																		<v-layout column>
																			<v-flex xs12>
																				<div align="right">
																					<v-btn depressed
																						class="primary text-none mb-1 mt-5 mr-10"
																						@click="addMultiPartSingleSelectMultipleChoiceOptionField(index)">
																						Add Option
																						<v-icon right> mdi-plus </v-icon>
																					</v-btn>
																				</div>
																			</v-flex>

																			<v-form v-model="valid"
																				ref="singleSelectMultipleChoiceOptionForm"
																				lazy-validation>
																				<template
																					v-for="(singleSelectMultipleChoiceOption, index2) in newQuestion.multi_part_single_select_multiple_choice_questions[index].options">
																					<div :key="index2">
																						<v-flex xs12 class="mt-4">
																							<div class="ml-5">
																								<v-layout row wrap>
																									<v-flex xs12 md1>
																										<div class="mt-2">
																											Option {{
																												index2 + 1 }}<span
																												class="error--text">*</span>
																										</div>
																									</v-flex>
																									<v-flex xs11 md10>
																										<v-text-field
																											class="text_field background"
																											outlined dense
																											v-model="newQuestion.multi_part_single_select_multiple_choice_questions[index].options[index2].value"
																											:rules="inputRules">
																										</v-text-field>
																									</v-flex>
																									<v-flex xs1>
																										<div class="mx-3">
																											<v-btn icon
																												class="red--text"
																												@click="removeMultiPartSingleSelectMultipleChoiceQuestion(index, index2)">
																												<v-icon>
																													mdi-close-circle
																												</v-icon>
																											</v-btn>
																										</div>
																									</v-flex>
																								</v-layout>
																							</div>
																						</v-flex>
																					</div>
																				</template>
																			</v-form>

																		</v-layout>

																	</v-flex>
																	<v-flex xs12 class="mt-9">
																		<v-divider></v-divider>
																	</v-flex>
																</div>
															</template>
														</v-form>
													</v-layout>
												</div>
											</div>
										</v-flex>
									</v-layout>

								</v-form>
							</v-card-text>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn depressed class="primary text-none mt-3" @click="save" :disabled="!valid"
									:loading="loading">
									Save
									<v-icon right> mdi-content-save </v-icon>
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-flex>
				</div>
				<div v-if="view == 'show'">
					<v-card outlined class="mt-5">
						<v-card-title class="tableHeader text--text">
							View Question
							<v-spacer></v-spacer>
							<v-btn icon @click="changeView('default')">
								<v-icon class="text--text"> mdi-close </v-icon>
							</v-btn>
						</v-card-title>
						<v-card-text>
							<div>
								<v-flex xs12 class="mt-10">
									<v-layout row wrap>
										<v-flex xs1>
											<div align="center">
												{{ assignedQuestion.number }}.
											</div>
										</v-flex>
										<v-flex xs11>
											<div>
												<b>{{ assignedQuestion.question }}<span class="red--text" v-if="assignedQuestion.required == 1">*</span></b>
											</div>
											<div class="font-italic caption">{{ assignedQuestion.description }}</div>
											<div v-if="assignedQuestion.question_type.name ==
												'Single Select Multiple Choice Question'
												">
												<v-radio-group v-model="singleSelect[assignedQuestion.id]" column>
													<template v-for="(
											option, index
										) in assignedQuestion.multi_choice_options">
														<div :key="index">
															<v-radio :label="option.option" :value="option.id"
																@change="checkOther(assignedQuestion.id, option, question)"></v-radio>
														</div>
													</template>
													<div v-if="otherQuestions[assignedQuestion.id] == true">
														<v-text-field class="text_field mt-2" outlined
															v-model="openEnded[assignedQuestion.id]"
															label="Please specify.."
															@input="trackInputField(assignedQuestion.id)"
															required></v-text-field>
													</div>
													<div v-if="childQuestions.length != 0" class="mt-3">
														<template
															v-for="(question, index) in childQuestions[assignedQuestion.id]">
															<div :key="index">
																<v-flex xs12 class="my-2">
																	<v-card :loading="loading" class="mx-auto"
																		elevation="0">
																		<v-card-text>
																			<v-layout row wrap>
																				<v-flex xs1>
																					<div align="center">
																						{{ index + 1 }}.
																					</div>
																				</v-flex>
																				<v-flex xs11>
																					<div>
																						<b>{{ assignedQuestion.question
																						}}</b>
																					</div>
																					<div v-if="assignedQuestion.question_type.name ==
																						'Single Select Multiple Choice Question'
																						">
																						<v-radio-group
																							v-model="singleSelect[assignedQuestion.id]"
																							column>
																							<template v-for="(
																								option, index
																							) in assignedQuestion.multi_choice_options">
																								<div :key="index">
																									<v-radio
																										:label="option.option"
																										:value="option.id"
																										@change="checkOther(assignedQuestion.id, option, question)"></v-radio>
																								</div>
																							</template>
																							<div
																								v-if="otherQuestions[assignedQuestion.id] == true">
																								<v-text-field
																									class="text_field mt-2"
																									outlined
																									v-model="openEnded[assignedQuestion.id]"
																									label="Please specify.."
																									@input="trackInputField(assignedQuestion.id)"
																									required></v-text-field>
																							</div>
																							<div
																								v-if="childQuestions.length != 0">
																								{{
																									childQuestions[assignedQuestion.id]
																								}}
																							</div>
																						</v-radio-group>
																					</div>
																					<div v-if="assignedQuestion.question_type.name ==
																							'Multi Part Single Select Multiple Choice Question'
																							">
																						<div class="mt-1">
																							<template v-for="(
																								multi_question, index
																							) in assignedQuestion.multi_part_questions">
																								<div :key="index">
																									{{
																										multi_question.question
																									}}
																									<v-radio-group
																										v-model="multiPartSingleAnswers[multi_question.id]
																												" column>
																										<template
																											v-for="(
																												option, index
																												) in multi_question.multi_part_options">
																											<div
																												:key="index">
																												<v-radio
																													:label="option.option"
																													:value="option.id"
																													@change="calculateProgress()"></v-radio>
																											</div>
																										</template>
																									</v-radio-group>
																								</div>
																							</template>
																						</div>
																					</div>
																					<div v-if="assignedQuestion.question_type.name ==
																						'Multi Select Multiple Choice Questions'
																						">
																						<div>
																							<template v-for="(
																									option, index
																								) in assignedQuestion.multi_choice_options">
																								<v-checkbox
																									v-model="multiSelects"
																									:key="index"
																									:label="option.option"
																									:value="option.id"
																									@change="calculateProgress()"></v-checkbox>
																							</template>

																						</div>
																					</div>
																					<div v-if="assignedQuestion.question_type.name ==
																						'Multi Part Multi Select Multiple Choice Question'
																						">
																						<div>
																							<template v-for="(
																								multi_question, index
																							) in assignedQuestion.multi_part_questions">
																								<div :key="index">
																									{{
																										multi_question.question
																									}}
																									<template v-for="(
																										option, index
																									) in multi_question.multi_part_options">
																										<v-checkbox
																											v-model="multiPartMultiAnswers"
																											:key="index"
																											:label="option.option"
																											:value="option.id"
																											@change="calculateProgress()"></v-checkbox>
																									</template>
																								</div>
																							</template>
																						</div>
																					</div>
																					<div v-if="assignedQuestion.question_type.name ==
																						'Open Ended'
																						">
																						<div class="mt-3 mb-5">
																							<v-text-field
																								class="text_field"
																								outlined
																								v-model="openEnded[assignedQuestion.id]"
																								@input="trackInputField(assignedQuestion.id)"
																								required></v-text-field>
																						</div>
																					</div>
																				</v-flex>
																			</v-layout>
																		</v-card-text>
																	</v-card>
																</v-flex>
															</div>
														</template>













													</div>
												</v-radio-group>
											</div>
											<div v-if="assignedQuestion.question_type.name ==
												'Multi Part Single Select Multiple Choice Question'
												">
												<div class="mt-4">
													<template v-for="(
											multi_question, index
										) in assignedQuestion.multi_part_questions">
														<div :key="index">
															{{ multi_question.question }}
															<v-radio-group v-model="multiPartSingleAnswers[multi_question.id]
																" column>
																<template v-for="(
												option, index
												) in multi_question.multi_part_options">
																	<div :key="index">
																		<v-radio :label="option.option"
																			:value="option.id"
																			@change="calculateProgress()">
																		</v-radio>
																	</div>
																</template>
															</v-radio-group>
															<v-text-field v-if="multi_question.question == 'Other'"
																class="text_field mb-6" outlined
																v-model="openEnded[assignedQuestion.id]"
																label="Please specify.."
																@input="trackInputField(assignedQuestion.id)"
																required></v-text-field>
														</div>
													</template>
												</div>
											</div>
											<div v-if="assignedQuestion.question_type.name ==
												'Multi Select Multiple Choice Questions'
												">
												<div class="mt-4">
													<template v-for="(
												option, index
											) in assignedQuestion.multi_choice_options">
														<v-checkbox v-model="multiSelects" :key="index"
															:label="option.option" :value="option.id"
															@change="calculateProgress()"></v-checkbox>
													</template>

												</div>
											</div>
											<div v-if="assignedQuestion.question_type.name ==
												'Multi Part Multi Select Multiple Choice Question'
												">
												<div class="mt-4">
													<template v-for="(
											multi_question, index
										) in assignedQuestion.multi_part_questions">
														<div :key="index">
															{{ multi_question.question }}
															<template v-for="(
												option, index
											) in multi_question.multi_part_options">
																<v-checkbox v-model="multiPartMultiAnswers"
																	:key="index" :label="option.option"
																	:value="option.id"
																	@change="calculateProgress()"></v-checkbox>
															</template>
														</div>
													</template>
												</div>
											</div>
											<div v-if="assignedQuestion.question_type.name ==
												'Open Ended'
												">
												<div class="mt-3 mb-5">
													<v-text-field class="text_field" outlined
														v-model="openEnded[assignedQuestion.id]"
														@input="trackInputField(assignedQuestion.id)"
														required></v-text-field>
												</div>
											</div>
										</v-flex>
									</v-layout>
								</v-flex>
							</div>
						</v-card-text>
					</v-card>
				</div>
			</v-layout>
		</v-container>
		<logData v-if="logDataModel" v-model="logDataModel" />
	</div>
</template>
<script>
import apiCall from "@/utils/api";
import { mapGetters, mapActions } from "vuex";
import logData from "@/views/accessControl/logData";
import Vue from "vue";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import uploadAdapter from '@/uploadAdapter.js';

var numeral = require("numeral");
Vue.filter("formatNumber", function (value) {
	return numeral(value).format("0,0");
});

Vue.use(require("vue-moment"));

export default {
	components: {
		logData,
	},
	data() {
		return {
			path: process.env.VUE_APP_API_URL,
			view: "default",
			loading: false,
			activateLoading: false,
			deactivateLoading: false,

			editor: ClassicEditor,
			editorConfig: {
				extraPlugins: [function (editor) {
					editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
						return new uploadAdapter(loader);
					}
				}],
				language: 'en',
			},

			valid: true,
			message: "",
			color: "",
			delete: false,
			activate: false,
			confirm: false,
			questionIndex: null,
			snackbar: false,
			inputRules: [
				(v) => !!v || "Input is required"
			],
			searchTerm: null,
			searchLoader: false,
			searchButtonDisabled: false,
			filteredQuestions: false,
			newQuestion: {
				id: null,
				question_type_id: null,
				number: null,
				question: null,
				description: null,
				length: null,
				required: null,
				open_ended: null,
				single_select_multiple_choice_options: [],
				multi_part_single_select_multiple_choice_questions: [],

			},
			assignedQuestion: null,
			formData: null,
			logData: {
				message: null,
				model: "App\\Models\\Question",
			},

			progressBar: 0,
			questionCount: 0,

			openEnded: [],
			singleSelect: [],
			multiPartSingleAnswers: [],
			multiPartMultiAnswers: [],
			multiSelects: [],

			otherQuestions: [],
			childQuestions: [],
			counter: 0,

			moduleAnswers: [],
			progressData: {
				id: null,
				progress: null,
			},

		};
	},
	created() {
		if (this.$can("question_view")) {
			this.startQuestionLoader();
			this.fetchQuestions(this.questionPagination.current_page);
			this.getFormData();
		}
	},
	methods: {
		...mapActions([
			"fetchQuestions",
			"startQuestionLoader",
			"stopQuestionLoader",
			"filterQuestions",

			"filterLogData",
			"openLogDataModel",
		]),
		enableSearch() {
			this.searchButtonDisabled = false;
		},
		resetSearch() {
			this.searchTerm = null;
			this.filteredQuestions = true;
			this.startQuestionLoader();
			this.fetchQuestions(1);
		},
		showQuestion(question) {
			this.assignedQuestion = question;
			this.changeView("show");
		},
		editQuestion(question) {
			this.newQuestion.id = question.id;
			this.newQuestion.question_type_id = question.question_type_id;
			this.newQuestion.number = question.number;
			this.newQuestion.question = question.question;
			this.newQuestion.description = question.description;
			this.newQuestion.length = question.length;
			this.newQuestion.required = question.required;

			if (question.question_type_id == 2 || question.question_type_id == 3) {
				for (var i = 0; i < question.multi_choice_options.length; i++) {
					let obj = {}
					obj['id'] = question.multi_choice_options[i].id
					obj['value'] = question.multi_choice_options[i].option

					this.newQuestion.single_select_multiple_choice_options.push(obj)
				}
			}
			if (question.question_type_id == 4 || question.question_type_id == 5) {
				for (var i = 0; i < question.multi_part_questions.length; i++) {

					let obj = {}
					obj['id'] = question.multi_part_questions[i].id
					obj['value'] = question.multi_part_questions[i].question
					obj['options'] = []

					for (var x = 0; x < question.multi_part_questions[i].multi_part_options.length; x++) {
						let objTwo = {}
						objTwo['id'] = question.multi_part_questions[i].multi_part_options[x].id
						objTwo['value'] = question.multi_part_questions[i].multi_part_options[x].option

						obj['options'].push(objTwo)
					}

					console.log(obj)
					this.newQuestion.multi_part_single_select_multiple_choice_questions.push(obj)
				}
			}
			this.changeView("create");
		},
		viewLog(data) {
			this.logData.message = data.id;
			this.filterLogData(this.logData);
			this.openLogDataModel();
		},
		addSingleSelectMultipleChoiceOptionField() {
			let obj = {}
			obj['id'] = null
			obj['value'] = null

			this.newQuestion.single_select_multiple_choice_options.push(obj)
		},
		removeSingleSelectMultipleChoiceOption(index) {
			if (this.newQuestion.single_select_multiple_choice_options.length != 0) {
				this.newQuestion.single_select_multiple_choice_options.splice(index, 1);
			}
		},
		addMultiPartSingleSelectMultipleChoiceQuestionField() {
			let obj = {}
			obj['id'] = null
			obj['value'] = null
			obj['options'] = []

			this.newQuestion.multi_part_single_select_multiple_choice_questions.push(obj)
		},
		removeMultiPartSingleSelectMultipleChoiceQuestion(index) {
			if (this.newQuestion.multi_part_single_select_multiple_choice_questions.length != 0) {
				this.newQuestion.multi_part_single_select_multiple_choice_questions.splice(index, 1);
			}
		},
		addMultiPartSingleSelectMultipleChoiceOptionField(index) {
			let obj = {}
			obj['id'] = null
			obj['value'] = null

			this.newQuestion.multi_part_single_select_multiple_choice_questions[index].options.push(obj)
		},
		removeMultiPartSingleSelectMultipleChoiceQuestion(index, index2) {
			if (this.newQuestion.multi_part_single_select_multiple_choice_questions[index].options.length != 0) {
				this.newQuestion.multi_part_single_select_multiple_choice_questions[index].options.splice(index2, 1);
			}

		},
		checkOther(questionId, option, question) {
			if (option.option == "Other") {
				this.otherQuestions[questionId] = true;
			} else {
				this.otherQuestions[questionId] = false;
				//this.openEnded[questionId] = null;
			}
			if (option.option == "Yes"
			|| option.option == "Referred by a friend"
			|| option.option == "Yes, all of them"

			) {
				const index = this.questions.indexOf(question);
				if(this.childQuestions[questionId] === undefined){
				this.questionCount += this.questions[index].child_questions.length
				}
				
				if (this.questions[index].child_questions.length != 0) {
				this.childQuestions[questionId] = this.questions[index].child_questions
				
				}
				
				
			} else if (option.option == "No" 
			|| option.option == "Event (at school or in college)"
			|| option.option == "Relative"
			|| option.option == "Other"
			|| option.option == "No, none of them"
			|| option.option == "Majority of them"
			|| option.option == "A few of them"
			|| option.option == "Not sure"
			|| option.option == "Not Comfortable Disclosing"
			
			
			) {
				const index = this.questions.indexOf(question);
				if (this.questions[index].child_questions.length != 0) {
				this.childQuestions[questionId] = []
				if(question.question_type.name == 'Single Select Multiple Choice Question'){
					for (let i=0; i<question.child_questions.length; i++){
					if(this.singleSelect[question.child_questions[i].id] != null){
						this.singleSelect[question.child_questions[i].id] = null
						this.questionCount -= 1
					}              
					}
				} else if(question.question_type.name == 'Multi Part Single Select Multiple Choice Question'){
					for (let m=0; m<question.multi_part_questions.length; m++){
					this.multiPartSingleAnswers[question.multi_part_questions[m].id] = null
					}
				} 
				// else if(question.question_type.name == 'Multi Select Multiple Choice Questions'){
				//   for (let k=0; k<question.multi_choice_options.length; k++){
				//     this.multiSelects[question.multi_part_questions[m].id] = false
				//   }
				// }
				}
			}
			this.calculateProgress()
			},
		save() {
			if (this.$refs.form.validate()) {
				if (this.newQuestion.id != null) {
					confirm("Are You Sure You Want to Update Question") && (this.confirm = true);
					if (this.confirm) {
						this.loading = true;
						apiCall({
							url: "/api/question/" + this.newQuestion.id,
							data: this.newQuestion,
							method: "PUT",
						})
							.then((resp) => {
								this.message = "Question Updated Successfully";
								this.color = "orange";
								this.loading = false;
								this.snackbar = true;
								this.confirm = false;
								this.changeView("default");
								this.fetchQuestions(this.questionPagination.current_page);
							})
							.catch((error) => {
								this.message = "An Error Occurred";
								this.color = "error";
								this.loading = false;
								this.snackbar = true;
							});
					}
				} else {
					confirm("Are You Sure You Want to Create Question?") && (this.confirm = true);
					if (this.confirm) {
						this.loading = true;
						apiCall({
							url: "/api/question",
							data: this.newQuestion,
							method: "POST",
						})
							.then((resp) => {
								if (resp.status == 409) {
									this.message = resp.message
									this.color = "error";
									this.loading = false;
									this.snackbar = true;
									this.alertType = "error";
								} else {
									this.message = "Question Added Successfully";
									this.color = "success";
									this.loading = false;
									this.snackbar = true;
									this.changeView("default");
									this.fetchQuestions(this.questionPagination.current_page);
								}
							})
							.catch((error) => {
								this.message = "An Error Occurred Email";
								this.color = "error";
								this.loading = false;
								this.snackbar = true;
								this.changeView("default");
								this.fetchQuestions(this.questionPagination.current_page);
							});
					}
				}
			}
		},
		search() {
			if (this.searchTerm == null) {
				this.searchButtonDisabled = true;
			} else {
				this.searchLoader = true;
				this.searchButtonDisabled = false;
				this.startQuestionLoader();
				apiCall({
					url: "/api/question?type=search&search=" + this.searchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.filterQuestions(resp);
						this.searchLoader = false;
						this.stopQuestionLoader();
						this.filteredQuestions = true;
					})
					.catch((error) => {
						console.log(error.response);
						this.searchLoader = false;
						this.stopQuestionLoader();
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			}
		},
		changeView(view) {
			if (view == "default") {
				this.lockEmail = false;
				this.newQuestion.id = null;
				this.newQuestion.question_type_id = null;
				this.newQuestion.number = null;
				this.newQuestion.question = null;
				this.newQuestion.description = null;
				this.newQuestion.length = null;
				this.newQuestion.required = null;
				this.newQuestion.open_ended = null;
				this.newQuestion.single_select_multiple_choice_options = [];
				this.newQuestion.multi_part_single_select_multiple_choice_questions = [];

			} else if (view == "create") {
				this.getFormData();
			} else if (view == "show") {
			}
			this.view = view;
		},
		changePage() {
			this.startQuestionLoader();
			if (this.filteredQuestions == true) {
				this.searchButtonDisabled = false;
				apiCall({
					url:
						"/api/question?page=" +
						this.questionPagination.current_page +
						"&type=search&search=" +
						this.searchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.filterQuestions(resp);
						this.stopQuestionLoader();
					})
					.catch((error) => {
						this.stopQuestionLoader();
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			} else {
				this.fetchQuestions(this.questionPagination.current_page);
			}
		},
		deleteQuestion(item) {
			confirm("Are You Sure You Want to Delete Question?") && (this.delete = true);
			if (this.delete) {
				this.loading = true;
				this.questionIndex = item.id;
				apiCall({ url: "/api/question/" + item.id, method: "DELETE" })
					.then((resp) => {
						this.message = "Question Deleted Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.questionIndex = null;
						this.fetchQuestions(this.questionPagination.current_page);
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.questionIndex = null;
						console.log(error.response);
					});
			}
		},
		activateQuestion(item) {
			confirm("Are You Sure You Want to Activate Question?") && (this.activate = true);
			if (this.activate) {
				this.activateLoading = true;
				this.questionIndex = item.id;
				this.newQuestion.id = item.id;
				apiCall({
					url: "/api/question?type=activate",
					data: this.newQuestion,
					method: "POST"
				})
					.then((resp) => {
						this.message = "Question Question Activated Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.activateLoading = false;
						this.activate = false;
						this.questionIndex = null;
						this.newQuestion.id = null;
						this.changeView("default");
						this.fetchQuestions(this.questionPagination.current_page);
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.activateLoading = false;
						this.activate = false;
						this.questionIndex = null;
						this.newQuestion.id = null;
						console.log(error.response);
					});
			}
		},
		deactivateQuestion(item) {
			confirm("Are You Sure You Want to Deactivate Question?") && (this.activate = true);
			if (this.activate) {
				this.deactivateLoading = true;
				this.questionIndex = item.id;
				this.newQuestion.id = item.id;
				apiCall({
					url: "/api/question?type=deactivate",
					data: this.newQuestion,
					method: "POST"
				})
					.then((resp) => {
						this.message = "Question Question Deactivated Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.deactivateLoading = false;
						this.activate = false;
						this.questionIndex = null;
						this.newQuestion.id = null;
						this.changeView("default");
						this.fetchQuestions(this.questionPagination.current_page);
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.deactivateLoading = false;
						this.activate = false;
						this.questionIndex = null;
						this.newQuestion.id = null;
						console.log(error);
					});
			}
		},
		getFormData() {
			apiCall({
				url: "/api/question?type=formData",
				method: "GET",
			})
				.then((resp) => {
					this.formData = resp;
				})
				.catch((error) => {
					this.message = "An Error Occurred; Couldn't Get Form Data";
					this.color = "error";
				});
		},
	},
	computed: {
		...mapGetters(["questions", "questionPagination", "questionLoader", "logDataModel"]),
		length: function () {
			return Math.ceil(
				this.questionPagination.total / this.questionPagination.per_page
			);
		},
	},
};
</script>
  