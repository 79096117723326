import apiCall from '@/utils/api'

const state = {
	teams: [],
	publicTeams: [],
	allTeams: [],
    teamPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
	publicTeamPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    teamLoader: false
};

const getters = {
	teams: (state) => state.teams,
	publicTeams: (state) => state.publicTeams,
	allTeams: (state) => state.allTeams,
    teamLoader: (state) => state.teamLoader,
    teamPagination: (state) => state.teamPagination,
	publicTeamPagination: (state) => state.publicTeamPagination,
}

const actions = {
	async fetchAllTeams({commit}, page) {
		const response = await apiCall({url: `/api/team?type=all`, method: 'GET' });
		commit('setAllTeams', response)
        commit('stopLoader', response)
	},
    async fetchTeams({commit}, page) {
		const response = await apiCall({url: `/api/team?page=${page}`, method: 'GET' });
		commit('setTeams', response)
        commit('stopLoader', response)
	},
	async fetchPublicTeams({commit}, page) {
		const response = await apiCall({url: `/api/team?type=public&page=${page}`, method: 'GET' });
		commit('setPublicTeams', response)
        commit('stopLoader', response)
	},
    async filterTeams({commit, state},resp){
		commit('setTeams', resp)
	},
	async startTeamLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopTeamLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setTeams: (state, teams) => {
		state.teams = teams.data
		state.teamPagination.current_page = teams.current_page
		state.teamPagination.total = teams.total
		state.teamPagination.per_page = teams.per_page
	},
	setPublicTeams: (state, teams) => {
		state.publicTeams = teams.data
		state.publicTeamPagination.current_page = teams.current_page
		state.publicTeamPagination.total = teams.total
		state.publicTeamPagination.per_page = teams.per_page
	},
	setAllTeams: (state, teams) => {
		state.allTeams = teams
	},
    startLoader: (state) => state.teamLoader = true,
	stopLoader: (state) => state.teamLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
