<template>
  <div>
    <v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
      <span>{{ message }}</span>
    </v-snackbar>
    <v-app-bar style="background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5));"
      :color="headerColor" elevate-on-scroll app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="hidden-md-and-up primary--text">
      </v-app-bar-nav-icon>
      <v-btn small depressed class="transparent transparent--text hidden-sm-and-down text-none"
        v-if="!isAuthenticated">Get Started
      </v-btn>
      <v-btn v-if="isAuthenticated" small depressed class="transparent transparent--text text-none hidden-sm-and-down">
        
        <v-avatar>
          <v-icon> mdi-account-circle </v-icon>
        </v-avatar>
        <div class="ml-2">{{ getProfile.first_name }} {{ getProfile.last_name }}</div>
        
        <v-icon small right>mdi-chevron-down</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <a @click="goToHome()">
        <v-img max-width="220" max-height="64" class="mt-2 hidden-sm-and-down" src="img/logo2.png">
        </v-img>
      </a>
      <a @click="goToHome()">
        <v-img max-width="200" max-height="64" class="mt-3 ml-2 hidden-sm-and-down" src="img/ibiz-logo.png">
        </v-img>
      </a>

      <a @click="goToHome()">
        <v-img max-width="180" max-height="64" class="mt-2 hidden-md-and-up" src="img/logo2.png">
        </v-img>
      </a>
      <a @click="goToHome()">
        <v-img max-width="160" max-height="64" class="mt-3 ml-2 hidden-md-and-up" src="img/ibiz-logo.png">
        </v-img>
      </a>
      <v-spacer></v-spacer>
      <v-btn small depressed class="secondary white--text hidden-sm-and-down text-none" @click="changeLoginCardState"
        v-if="!isAuthenticated">Get Started
        <v-icon right small>mdi-location-enter</v-icon>
      </v-btn>

      <v-menu left offset-y v-if="isAuthenticated">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small v-bind="attrs" v-on="on" depressed class="secondary text-none hidden-sm-and-down">
            <v-avatar size="36" v-if="getProfile.profile_pic != null">
              <img :src="path + '/profile_pics/' + getProfile.profile_pic" alt="PPIC" />
            </v-avatar>
            <v-avatar v-else>
              <v-icon> mdi-account-circle </v-icon>
            </v-avatar>
            <div class="ml-2">{{ getProfile.first_name }} {{ getProfile.last_name }}</div>
            
            <v-icon small right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item router to="/profile">
            <v-list-item-title class="body-2">Profile</v-list-item-title>
          </v-list-item>

          <v-list-item router to="/dashboard" v-if="$can('dashboard_view')">
            <v-list-item-title class="body-2">Dashboard</v-list-item-title>
          </v-list-item>
          <v-list-item router to="/roles" v-if="$can('role_view')">
            <v-list-item-title class="body-2">Roles</v-list-item-title>
          </v-list-item>
          <v-list-item router to="/logs" v-if="$can('log_view')">
            <v-list-item-title class="body-2">Logs</v-list-item-title>
          </v-list-item>
          <v-list-item router to="/users" v-if="$can('user_view')">
            <v-list-item-title class="body-2">Users</v-list-item-title>
          </v-list-item>
          <v-list-item router to="/questions" v-if="$can('question_view')">
            <v-list-item-title class="body-2">Questions</v-list-item-title>
          </v-list-item>
          <v-list-item router to="/programs" v-if="$can('program_view')">
            <v-list-item-title class="body-2">Programs</v-list-item-title>
          </v-list-item>
          <v-list-item router to="/applications" v-if="$can('application_view')">
            <v-list-item-title class="body-2">Applications</v-list-item-title>
          </v-list-item>
          <v-list-item router to="/track-applications" v-if="$can('application_view')">
            <v-list-item-title class="body-2">Track Applications</v-list-item-title>
          </v-list-item>
          <v-list-item router to="/groups" v-if="$can('group_view')">
            <v-list-item-title class="body-2">Rating and Selection Centre</v-list-item-title>
          </v-list-item>
          <v-list-item router to="/contact-us-admin" v-if="$can('contact_us_view')">
            <v-list-item-title class="body-2">Contact Us</v-list-item-title>
          </v-list-item>
          <v-list-item router to="/news-feed-admin" v-if="$can('news_feed_view')">
            <v-list-item-title class="body-2">News Feed</v-list-item-title>
          </v-list-item>
          <v-list-item router to="/story-admin" v-if="$can('story_view')">
            <v-list-item-title class="body-2">Story</v-list-item-title>
          </v-list-item>
          <v-list-item router to="/gallery-admin" v-if="$can('gallery_view')">
            <v-list-item-title class="body-2">Gallery</v-list-item-title>
          </v-list-item>
          <v-list-item router to="/team-admin" v-if="$can('team_view')">
            <v-list-item-title class="body-2">Team</v-list-item-title>
          </v-list-item>
          <v-list-item router to="/past-cohort-admin" v-if="$can('past_cohort_view')">
            <v-list-item-title class="body-2">Past Cohort</v-list-item-title>
          </v-list-item>
          <v-list-item router to="/question" v-if="$can('Question_view')">
            <v-list-item-title class="body-2">Question</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="signOut">
            <v-list-item-title class="body-2">Sign Out</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <template v-slot:extension>
        <v-spacer></v-spacer>
        <v-btn small text class="text-none mr-1 hidden-sm-and-down primary white--text" router to="/"
          v-if="$route.name == 'Dashboard'" style="font-family: 'SC Prosper Sans Regular';">
          <v-icon small left>mdi-home</v-icon>
          Home
        </v-btn>
        <v-btn small text class="text-none mr-1 hidden-sm-and-down" router to="/" v-if="$route.name != 'Dashboard'">
          <v-icon small left>mdi-home</v-icon>
          <div style="font-family: 'SC Prosper Sans Regular';"> Home</div>
        </v-btn>
        <v-btn small text class="text-none mr-1 hidden-sm-and-down primary white--text" router to="/about"
          v-if="$route.name == 'About'">
          <v-icon small left>mdi-information-variant</v-icon>
          About
        </v-btn>
        <v-btn small text class="text-none mr-1 hidden-sm-and-down" router to="/about" v-if="$route.name != 'About'">
          <v-icon small left>mdi-information-variant</v-icon>
          About
        </v-btn>
        <v-btn small text class="text-none mr-1 hidden-sm-and-down primary white--text" router to="/cohort-7"
          v-if="$route.name == 'Cohort7'">
          <v-icon small left>mdi-numeric-7</v-icon>
          Cohort 7
        </v-btn>
        <v-btn small text class="text-none mr-1 hidden-sm-and-down" router to="/cohort-7" v-if="$route.name != 'Cohort7'">
          <v-icon small left>mdi-numeric-7</v-icon>
          Cohort 7
        </v-btn>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small text v-bind="attrs" v-on="on" class="text-none mr-1 hidden-sm-and-down">
              <v-icon small left>mdi-account-group</v-icon>
              Past Cohorts
              <v-icon small right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>

          <v-list>
            <div v-if="allPastCohorts.length != 0">
              <template v-for="(cohort, index) in allPastCohorts">
                <v-list-item router :to="'/past-cohorts/'+cohort.slug" v-if="$route.slug == cohort.slug" color="primary">
                  <v-list-item-icon>
                    <v-icon>mdi-numeric-{{ index+1 }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="caption">{{ cohort.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item router :to="'/past-cohorts/'+cohort.slug" v-if="$route.slug != cohort.slug">
                  <v-list-item-icon>
                    <v-icon>mdi-numeric-{{ index+1 }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="caption">{{ cohort.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </div>
            <div v-else>
              <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-alert-circle-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="caption">No Previous Cohorts</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
            </div>
          </v-list>

        </v-menu>
        <v-btn small text class="text-none mr-1 hidden-sm-and-down primary white--text" router to="/news-feed"
          v-if="$route.name == 'NewsFeed'">
          <v-icon small left>mdi-newspaper</v-icon>
          News Feed
        </v-btn>
        <v-btn small text class="text-none mr-1 hidden-sm-and-down" router to="/news-feed"
          v-if="$route.name != 'NewsFeed'">
          <v-icon small left>mdi-newspaper</v-icon>
          News Feed
        </v-btn>

        <v-btn small text class="text-none mr-1 hidden-sm-and-down primary white--text" router to="/stories"
          v-if="$route.name == 'Stories'">
          <v-icon small left>mdi-script-text</v-icon>
          Stories
        </v-btn>
        <v-btn small text class="text-none mr-1 hidden-sm-and-down" router to="/stories" v-if="$route.name != 'Stories'">
          <v-icon small left>mdi-script-text</v-icon>
          Stories
        </v-btn>

        <v-btn small text class="text-none mr-1 hidden-sm-and-down primary white--text" router to="/gallery"
          v-if="$route.name == 'Gallery'">
          <v-icon small left>mdi-panorama-variant-outline</v-icon>
          Gallery
        </v-btn>
        <v-btn small text class="text-none mr-1 hidden-sm-and-down" router to="/gallery" v-if="$route.name != 'Gallery'">
          <v-icon small left>mdi-panorama-variant-outline</v-icon>
          Gallery
        </v-btn>

        <v-btn small text class="text-none mr-1 hidden-sm-and-down primary white--text" router to="/team"
          v-if="$route.name == 'Team'">
          <v-icon small left>mdi-account-hard-hat</v-icon>
          Team
        </v-btn>
        <v-btn small text class="text-none mr-1 hidden-sm-and-down" router to="/team" v-if="$route.name != 'Team'">
          <v-icon small left>mdi-account-hard-hat</v-icon>
          Team
        </v-btn>

        <v-btn small text class="text-none mr-1 hidden-sm-and-down primary white--text" router to="/faqs"
          v-if="$route.name == 'FAQs'">
          <v-icon small left>mdi-frequently-asked-questions</v-icon>
          FAQs
        </v-btn>
        <v-btn small text class="text-none mr-1 hidden-sm-and-down" router to="/faqs" v-if="$route.name != 'FAQs'">
          <v-icon small left>mdi-frequently-asked-questions</v-icon>
          FAQs
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn small depressed class="secondary white--text hidden-md-and-up text-none" @click="changeLoginCardState"
        v-if="!isAuthenticated">Get Started
        <v-icon right small>mdi-location-enter</v-icon>
      </v-btn>
      </template>
    </v-app-bar>
    <v-navigation-drawer disable-resize-watcher app v-model="drawer">

      <v-list-group :value="false" prepend-icon="mdi-account-circle" v-if="isAuthenticated">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ getProfile.first_name }}</v-list-item-title>
            </v-list-item-content>

          </template>
          <v-list-item router to="/profile">
            <v-list-item-title class="body-2">Profile</v-list-item-title>
          </v-list-item>

          <v-list-item router to="/dashboard" v-if="$can('dashboard_view')">
            <v-list-item-title class="body-2">Dashboard</v-list-item-title>
          </v-list-item>
          <v-list-item router to="/roles" v-if="$can('role_view')">
            <v-list-item-title class="body-2">Roles</v-list-item-title>
          </v-list-item>
          <v-list-item router to="/logs" v-if="$can('log_view')">
            <v-list-item-title class="body-2">Logs</v-list-item-title>
          </v-list-item>
          <v-list-item router to="/users" v-if="$can('user_view')">
            <v-list-item-title class="body-2">Users</v-list-item-title>
          </v-list-item>
          <v-list-item router to="/questions" v-if="$can('question_view')">
            <v-list-item-title class="body-2">Questions</v-list-item-title>
          </v-list-item>
          <v-list-item router to="/programs" v-if="$can('program_view')">
            <v-list-item-title class="body-2">Programs</v-list-item-title>
          </v-list-item>
          <v-list-item router to="/applications" v-if="$can('application_view')">
            <v-list-item-title class="body-2">Applications</v-list-item-title>
          </v-list-item>
          <v-list-item router to="/track-applications" v-if="$can('application_view')">
            <v-list-item-title class="body-2">Track Applications</v-list-item-title>
          </v-list-item>
          <v-list-item router to="/groups" v-if="$can('group_view')">
            <v-list-item-title class="body-2">Rating and Selection Centre</v-list-item-title>
          </v-list-item>
          <v-list-item router to="/contact-us-admin" v-if="$can('contact_us_view')">
            <v-list-item-title class="body-2">Contact Us</v-list-item-title>
          </v-list-item>
          <v-list-item router to="/news-feed-admin" v-if="$can('news_feed_view')">
            <v-list-item-title class="body-2">News Feed</v-list-item-title>
          </v-list-item>
          <v-list-item router to="/story-admin" v-if="$can('story_view')">
            <v-list-item-title class="body-2">Story</v-list-item-title>
          </v-list-item>
          <v-list-item router to="/gallery-admin" v-if="$can('gallery_view')">
            <v-list-item-title class="body-2">Gallery</v-list-item-title>
          </v-list-item>
          <v-list-item router to="/team-admin" v-if="$can('team_view')">
            <v-list-item-title class="body-2">Team</v-list-item-title>
          </v-list-item>
          <v-list-item router to="/past-cohort-admin" v-if="$can('past_cohort_view')">
            <v-list-item-title class="body-2">Past Cohort</v-list-item-title>
          </v-list-item>
          <v-list-item router to="/question" v-if="$can('Question_view')">
            <v-list-item-title class="body-2">Question</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          

      </v-list-group>
      <v-list dense nav>
        <v-list-item router to="/" class="primary white--text" v-if="$route.name == 'Dashboard'">
          <v-list-item-icon>
            <v-icon class="white--text">mdi-home</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>
        <v-list-item router to="/" class="white primary--text" v-if="$route.name != 'Dashboard'">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>

          <v-list-item-title class="grey--text text--darken-2">Home</v-list-item-title>
        </v-list-item>

        <v-list-item router to="/about" class="primary white--text" v-if="$route.name == 'About'">
          <v-list-item-icon>
            <v-icon class="white--text">mdi-information-variant</v-icon>
          </v-list-item-icon>

          <v-list-item-title>About</v-list-item-title>
        </v-list-item>
        <v-list-item router to="/about" class="white primary--text" v-if="$route.name != 'About'">
          <v-list-item-icon>
            <v-icon>mdi-information-variant</v-icon>
          </v-list-item-icon>

          <v-list-item-title class="grey--text text--darken-2">About</v-list-item-title>
        </v-list-item>

        <v-list-item router to="/cohort-7" class="primary white--text" v-if="$route.name == 'Cohort7'">
          <v-list-item-icon>
            <v-icon class="white--text">mdi-numeric-7</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Cohort 7</v-list-item-title>
        </v-list-item>
        <v-list-item router to="/cohort-7" class="white primary--text" v-if="$route.name != 'Cohort7'">
          <v-list-item-icon>
            <v-icon>mdi-numeric-7</v-icon>
          </v-list-item-icon>

          <v-list-item-title class="grey--text text--darken-2">Cohort 7</v-list-item-title>
        </v-list-item>

        <v-list-group :value="false" prepend-icon="mdi-account-group">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Past Cohorts</v-list-item-title>
            </v-list-item-content>

          </template>
          <div v-if="allPastCohorts.length != 0">
            <template v-for="(cohort, index) in allPastCohorts">
              <v-list-item router :to="'/past-cohorts/'+cohort.slug" v-if="$route.slug == cohort.slug" class="primary white--text">
                <v-list-item-icon>
                  <v-icon>mdi-numeric-{{ index+1 }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="caption">{{ cohort.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item router :to="'/past-cohorts/'+cohort.slug" v-if="$route.slug != cohort.slug">
                <v-list-item-icon>
                  <v-icon>mdi-numeric-{{ index+1 }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="grey--text text--darken-2">{{ cohort.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </div>
          <div v-else>
            <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-alert-circle-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="caption">No Previous Cohorts</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
          </div>
        </v-list-group>

        <v-list-item router to="/news-feed" class="primary white--text" v-if="$route.name == 'NewsFeed'">
          <v-list-item-icon>
            <v-icon class="white--text">mdi-newspaper</v-icon>
          </v-list-item-icon>

          <v-list-item-title>News Feed</v-list-item-title>
        </v-list-item>
        <v-list-item router to="/news-feed" class="white primary--text" v-if="$route.name != 'NewsFeed'">
          <v-list-item-icon>
            <v-icon>mdi-newspaper</v-icon>
          </v-list-item-icon>

          <v-list-item-title class="grey--text text--darken-2">News Feed</v-list-item-title>
        </v-list-item>

        <v-list-item router to="/stories" class="primary white--text" v-if="$route.name == 'Stories'">
          <v-list-item-icon>
            <v-icon class="white--text">mdi-script-text</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Stories</v-list-item-title>
        </v-list-item>
        <v-list-item router to="/stories" class="white primary--text" v-if="$route.name != 'Stories'">
          <v-list-item-icon>
            <v-icon>mdi-script-text</v-icon>
          </v-list-item-icon>

          <v-list-item-title class="grey--text text--darken-2">Stories</v-list-item-title>
        </v-list-item>

        <v-list-item router to="/gallery" class="primary white--text" v-if="$route.name == 'Gallery'">
          <v-list-item-icon>
            <v-icon class="white--text">mdi-panorama-variant-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Gallery</v-list-item-title>
        </v-list-item>
        <v-list-item router to="/gallery" class="white primary--text" v-if="$route.name != 'Gallery'">
          <v-list-item-icon>
            <v-icon>mdi-panorama-variant-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-title class="grey--text text--darken-2">Gallery</v-list-item-title>
        </v-list-item>

        <v-list-item router to="/team" class="primary white--text" v-if="$route.name == 'Team'">
          <v-list-item-icon>
            <v-icon class="white--text">mdi-account-hard-hat</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Team</v-list-item-title>
        </v-list-item>
        <v-list-item router to="/team" class="white primary--text" v-if="$route.name != 'Team'">
          <v-list-item-icon>
            <v-icon>mdi-account-hard-hat</v-icon>
          </v-list-item-icon>

          <v-list-item-title class="grey--text text--darken-2">Team</v-list-item-title>
        </v-list-item>

        <v-list-item router to="/faqs" class="primary white--text" v-if="$route.name == 'FAQs'">
          <v-list-item-icon>
            <v-icon class="white--text">mdi-frequently-asked-questions</v-icon>
          </v-list-item-icon>

          <v-list-item-title>FAQs</v-list-item-title>
        </v-list-item>
        <v-list-item router to="/faqs" class="white primary--text" v-if="$route.name != 'FAQs'">
          <v-list-item-icon>
            <v-icon>mdi-frequently-asked-questions</v-icon>
          </v-list-item-icon>

          <v-list-item-title class="grey--text text--darken-2">FAQs</v-list-item-title>
        </v-list-item>
        <v-list-item @click="signOut" class="red lighten-4" v-if="isAuthenticated">
          <v-list-item-icon>
            <v-icon>mdi-lock</v-icon>
          </v-list-item-icon>
            <v-list-item-title class="body-2">Sign Out</v-list-item-title>
          </v-list-item>

      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
import apiCall from "@/utils/api";
import Vue from "vue";
import { AUTH_LOGOUT } from "@/store/actions/auth";
import { USER_REQUEST } from "@/store/actions/user";
import { mapGetters, mapActions } from "vuex";
// import i18n from "@/plugins/i18n";

export default {
  components: {},
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      color: "",
      message: "",
      snackbar: false,
      drawer: false,
    };
  },
  watch: {
    darkState() {
      this.handledarkmode();
    },
  },
  created() {
    this.fetchAllPastCohorts()
  },
  mounted() {
    if (this.isAuthenticated) {
      this.$store.dispatch(USER_REQUEST);
    }
  },
  methods: {
    ...mapActions([
      "changeDarkState",
      "changeLoginCardState",
      "fetchAllPastCohorts"
    ]),

    signOut: function () {
      this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push("/"));
    },
    goToHome() {
      this.$router.push("/");
    },
    handledarkmode() {
      if (this.darkState == true) {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    },

  },
  computed: {
    ...mapGetters([
      "getProfile",
      "darkState",
      "isAuthenticated",
      "headerColor",
      "notifications",
      "unreadMessages",
      "allPastCohorts"
    ]),
  },
};
</script>
