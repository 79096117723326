import apiCall from '@/utils/api'

const state = {
	stories: [],
	allStories: [],
    storyPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    storyLoader: false
};

const getters = {
	stories: (state) => state.stories,
	allStories: (state) => state.allStories,
    storyLoader: (state) => state.storyLoader,
    storyPagination: (state) => state.storyPagination
}

const actions = {
	async fetchAllStories({commit}, page) {
		const response = await apiCall({url: `/api/story?type=all`, method: 'GET' });
		commit('setAllStories', response)
        commit('stopLoader', response)
	},
    async fetchStories({commit}, page) {
		const response = await apiCall({url: `/api/story?page=${page}`, method: 'GET' });
		commit('setStories', response)
        commit('stopLoader', response)
	},
    async filterStories({commit, state},resp){
		commit('setStories', resp)
	},
	async startStoryLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopStoryLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setStories: (state, stories) => {
		state.stories = stories.data
		state.storyPagination.current_page = stories.current_page
		state.storyPagination.total = stories.total
		state.storyPagination.per_page = stories.per_page
	},
	setAllStories: (state, stories) => {
		state.allStories = stories
	},
    startLoader: (state) => state.storyLoader = true,
	stopLoader: (state) => state.storyLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
