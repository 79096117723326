<template>
    <div class="welcome">
        <v-layout column>
            <v-flex xs12>
                <v-img dark height="250" src="img/page-title-cohorts-5-scaled.jpg">
                    <div align="center" class="font-weight-black display-1 mt-16">
                        Cohort 7
                    </div>
                    <div align="center" class="mt-2">
                        #BecauseSheCan
                    </div>
                    <!-- <div align="center" class="mt-2 hidden-sm-and-down">
                        <v-layout row wrap> -->
                            <!-- <v-flex xs12>
                                <div align="center" class="mt-5 mx-1">
                                    <a href="docs/wit-cohort6-programme.pdf" target="_blank">
                                        <v-btn outlined class="white primary--text text-none">
                                            View Programme
                                        </v-btn>
                                    </a>
                                </div>
                            </v-flex> -->
                            <!-- <v-flex xs12 md6>
                                <div align="left" class="mt-5 mx-1">
                                    <a href="https://vc4a.com/ibizafrica/standard-chartered-women-in-tech-cohort-6-program/"
                                        target="_blank">
                                        <v-btn depressed class="secondary text-none">
                                            Apply Here
                                        </v-btn>
                                    </a>
                                </div>
                            </v-flex> -->
                        <!-- </v-layout>
                    </div> -->
                    <div align="center" class="mt-2 hidden-md-and-up">
                        <v-layout row wrap>
                            <v-flex xs12>
                                <div align="center" class="mt-5 mx-1">
                                    <a href="docs/wit-cohort6-programme.pdf" target="_blank">
                                        <v-btn outlined class="white primary--text text-none">
                                            View Programme
                                        </v-btn>
                                    </a>
                                </div>
                            </v-flex>
                            <!-- <v-flex xs12 md6>
                                <div align="center" class="mt-2 mx-1 mb-3">
                                    <a href="https://vc4a.com/ibizafrica/standard-chartered-women-in-tech-cohort-6-program/"
                                        target="_blank">
                                        <v-btn depressed class="secondary text-none">
                                            Apply Here
                                        </v-btn>
                                    </a>
                                </div>
                            </v-flex> -->
                        </v-layout>
                    </div>

                </v-img>
            </v-flex>
            <v-flex xs12 class="white">
                <v-container>
                    <v-layout column class="my-16">
                        <v-flex xs12>
                            <div align="center" class="black--text font-weight-black display-1">Top 15</div>
                        </v-flex>
                        <v-flex xs12 class="mt-10">
                            <div align="center">
                                <v-card elevation="0" class="pa-3"shaped outlined max-width="1200">
                                    <v-layout row wrap>
                                        <v-flex xs12 md6 style="border-radius: 20px 0px 0px 4px; background-image: linear-gradient(to bottom right, rgba(43,62,136,1), rgba(44,99,173,0.8));">
                                            <v-layout column>
                                                <v-flex xs12>
                                                    <div align="left"
                                                        class="sec--text font-weight-black mt-12 display-1 ml-8">
                                                        Meira Education Solutions
                                                    </div>
                                                    <div align="left"
                                                        class="sec--text font-weight-black headline ml-8">
                                                        (EdTech)
                                                    </div>
                                                    <div align="left" class="headline white--text font-weight-black mt-4 mx-8" style="line-height: 1.7;">
                                                        Meira Education Solutions is an EdTech Solutions provider that builds a comprehensive school search portal and introduces the right tech tools to learning institutions in Kenya.
                                                    </div>
                                                    <div align="left"
                                                        class="white--text font-weight-black mt-12 headline ml-8">
                                                        #SCWomenInTech #BecauseSheCan
                                                    </div>
                                                </v-flex>
                                                <v-flex xs12 class="white mt-16" style="position: absolute; bottom: 0; width: 50%">
                                                    <v-layout row wrap class="my-1">
                                                        <v-flex xs6>
                                                            <div align="center" class="mt-2">
                                                                <v-img max-width="150" src="img/logo.png"> </v-img>
                                                            </div>  
                                                        </v-flex>
                                                        <v-flex xs6>
                                                            <div align="center">
                                                                <v-img max-width="150" src="img/ibiz-black.png"> </v-img>
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex xs12 md6>
                                            <div >
                                                <v-img v-if="echorich == 'image'" height="690"
                                                    src="img/cohort7/meira.png"
                                                    style="border-radius: 0px 4px 20px 0px;">
                                                </v-img>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </v-card>
                            </div>
                        </v-flex>
                        <v-flex xs12 class="mt-10">
                            <div align="center">
                                <v-card elevation="0" class="pa-3"shaped outlined max-width="1200">
                                    <v-layout row wrap>
                                        <v-flex xs12 md6>
                                            <div >
                                                <v-img v-if="echorich == 'image'" height="690"
                                                    src="img/cohort7/moma.png"
                                                    style="border-radius: 20px 0px 0px 4px;">
                                                </v-img>
                                            </div>
                                        </v-flex>
                                        <v-flex xs12 md6 style="border-radius: 0px 4px 20px 0px; background-image: linear-gradient(to bottom right, rgba(43,62,136,1), rgba(44,99,173,0.8));">
                                            <v-layout column>
                                                <v-flex xs12>
                                                    <div align="left"
                                                        class="sec--text font-weight-black mt-12 display-1 ml-8">
                                                        MOMA Renewable Energy
                                                    </div>
                                                    <div align="left"
                                                        class="sec--text font-weight-black headline ml-8">
                                                        (Renewable Tech)
                                                    </div>
                                                    <div align="left" class="headline white--text font-weight-black mt-4 mx-8" style="line-height: 1.7;">
                                                       MOMA Renewable Energy is a producer of liquid renewable fuels derived from food waste and byproducts.
                                                    </div>
                                                    <div align="left"
                                                        class="white--text font-weight-black mt-12 headline ml-8">
                                                        #SCWomenInTech #BecauseSheCan
                                                    </div>
                                                </v-flex>
                                                <v-flex xs12 class="white mt-16" style="position: absolute; bottom: 0; width: 50%">
                                                    <v-layout row wrap class="my-1">
                                                        <v-flex xs6>
                                                            <div align="center" class="mt-2">
                                                                <v-img max-width="150" src="img/logo.png"> </v-img>
                                                            </div>  
                                                        </v-flex>
                                                        <v-flex xs6>
                                                            <div align="center">
                                                                <v-img max-width="150" src="img/ibiz-black.png"> </v-img>
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                    </v-layout>
                                </v-card>
                            </div>
                        </v-flex>
                        <v-flex xs12 class="mt-10">
                            <div align="center">
                                <v-card elevation="0" class="pa-3"shaped outlined max-width="1200">
                                    <v-layout row wrap>
                                        <v-flex xs12 md6 style="border-radius: 20px 0px 0px 4px; background-image: linear-gradient(to bottom right, rgba(43,62,136,1), rgba(44,99,173,0.8));">
                                            <v-layout column>
                                                <v-flex xs12>
                                                    <div align="left"
                                                        class="sec--text font-weight-black mt-12 display-1 ml-8">
                                                        PsychCare Clinic
                                                    </div>
                                                    <div align="left"
                                                        class="sec--text font-weight-black headline ml-8">
                                                        (Health Tech)
                                                    </div>
                                                    <div align="left" class="headline white--text font-weight-black mt-4 mx-8" style="line-height: 1.7;">
                                                        PsychCare Clinic is a level 2 facility offering mental healthcare services and regulated by the Kenya Medical Practitioners and Dentists board.
                                                    </div>
                                                    <div align="left"
                                                        class="white--text font-weight-black mt-12 headline ml-8">
                                                        #SCWomenInTech #BecauseSheCan
                                                    </div>
                                                </v-flex>
                                                <v-flex xs12 class="white mt-16" style="position: absolute; bottom: 0; width: 50%">
                                                    <v-layout row wrap class="my-1">
                                                        <v-flex xs6>
                                                            <div align="center" class="mt-2">
                                                                <v-img max-width="150" src="img/logo.png"> </v-img>
                                                            </div>  
                                                        </v-flex>
                                                        <v-flex xs6>
                                                            <div align="center">
                                                                <v-img max-width="150" src="img/ibiz-black.png"> </v-img>
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex xs12 md6>
                                            <div >
                                                <v-img v-if="echorich == 'image'" height="690"
                                                    src="img/cohort7/psychcare.png"
                                                    style="border-radius: 0px 4px 20px 0px;">
                                                </v-img>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </v-card>
                            </div>
                        </v-flex>
                        <v-flex xs12 class="mt-10">
                            <div align="center">
                                <v-card elevation="0" class="pa-3"shaped outlined max-width="1200">
                                    <v-layout row wrap>
                                        <v-flex xs12 md6>
                                            <div >
                                                <v-img v-if="echorich == 'image'" height="690"
                                                    src="img/cohort7/smartreja.png"
                                                    style="border-radius: 20px 0px 0px 4px;">
                                                </v-img>
                                            </div>
                                        </v-flex>
                                        <v-flex xs12 md6 style="border-radius: 0px 4px 20px 0px; background-image: linear-gradient(to bottom right, rgba(43,62,136,1), rgba(44,99,173,0.8));">
                                            <v-layout column>
                                                <v-flex xs12>
                                                    <div align="left"
                                                        class="sec--text font-weight-black mt-12 display-1 ml-8">
                                                        Smart Reja
                                                    </div>
                                                    <div align="left"
                                                        class="sec--text font-weight-black headline ml-8">
                                                        (Retail Tech)
                                                    </div>
                                                    <div align="left" class="headline white--text font-weight-black mt-4 mx-8" style="line-height: 1.7;">
                                                       Smart Reja is a data-driven platform providing tools for retailers to optimize inventory, make data-driven decisions and increase sales.
                                                    </div>
                                                    <div align="left"
                                                        class="white--text font-weight-black mt-12 headline ml-8">
                                                        #SCWomenInTech #BecauseSheCan
                                                    </div>
                                                </v-flex>
                                                <v-flex xs12 class="white mt-16" style="position: absolute; bottom: 0; width: 50%">
                                                    <v-layout row wrap class="my-1">
                                                        <v-flex xs6>
                                                            <div align="center" class="mt-2">
                                                                <v-img max-width="150" src="img/logo.png"> </v-img>
                                                            </div>  
                                                        </v-flex>
                                                        <v-flex xs6>
                                                            <div align="center">
                                                                <v-img max-width="150" src="img/ibiz-black.png"> </v-img>
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                    </v-layout>
                                </v-card>
                            </div>
                        </v-flex>
                        <v-flex xs12 class="mt-10">
                            <div align="center">
                                <v-card elevation="0" class="pa-3"shaped outlined max-width="1200">
                                    <v-layout row wrap>
                                        <v-flex xs12 md6 style="border-radius: 20px 0px 0px 4px; background-image: linear-gradient(to bottom right, rgba(43,62,136,1), rgba(44,99,173,0.8));">
                                            <v-layout column>
                                                <v-flex xs12>
                                                    <div align="left"
                                                        class="sec--text font-weight-black mt-12 display-1 ml-8">
                                                        Voda Water
                                                    </div>
                                                    <div align="left"
                                                        class="sec--text font-weight-black headline ml-8">
                                                        (Hydro Tech)
                                                    </div>
                                                    <div align="left" class="headline white--text font-weight-black mt-4 mx-8" style="line-height: 1.7;">
                                                        Voda Water is a for-profit social enterprise dedicated to improving global health through water.
                                                    </div>
                                                    <div align="left"
                                                        class="white--text font-weight-black mt-12 headline ml-8">
                                                        #SCWomenInTech #BecauseSheCan
                                                    </div>
                                                </v-flex>
                                                <v-flex xs12 class="white mt-16" style="position: absolute; bottom: 0; width: 50%">
                                                    <v-layout row wrap class="my-1">
                                                        <v-flex xs6>
                                                            <div align="center" class="mt-2">
                                                                <v-img max-width="150" src="img/logo.png"> </v-img>
                                                            </div>  
                                                        </v-flex>
                                                        <v-flex xs6>
                                                            <div align="center">
                                                                <v-img max-width="150" src="img/ibiz-black.png"> </v-img>
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex xs12 md6>
                                            <div >
                                                <v-img v-if="echorich == 'image'" height="690"
                                                    src="img/cohort7/vodawater.png"
                                                    style="border-radius: 0px 4px 20px 0px;">
                                                </v-img>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </v-card>
                            </div>
                        </v-flex>
                        <v-flex xs12 class="mt-10">
                            <div align="center">
                                <v-card elevation="0" class="pa-3"shaped outlined max-width="1200">
                                    <v-layout row wrap>
                                        <v-flex xs12 md6>
                                            <div >
                                                <v-img v-if="echorich == 'image'" height="690"
                                                    src="img/cohort7/zaoshinani.png"
                                                    style="border-radius: 20px 0px 0px 4px;">
                                                </v-img>
                                            </div>
                                        </v-flex>
                                        <v-flex xs12 md6 style="border-radius: 0px 4px 20px 0px; background-image: linear-gradient(to bottom right, rgba(43,62,136,1), rgba(44,99,173,0.8));">
                                            <v-layout column>
                                                <v-flex xs12>
                                                    <div align="left"
                                                        class="sec--text font-weight-black mt-12 display-1 ml-8">
                                                        Zaoshinani
                                                    </div>
                                                    <div align="left"
                                                        class="sec--text font-weight-black headline ml-8">
                                                        (Agri-Fintech)
                                                    </div>
                                                    <div align="left" class="headline white--text font-weight-black mt-4 mx-8" style="line-height: 1.7;">
                                                       Zaoshinani is a Fintech driving agricultural transformation using technology and data to steer financial inclusion among smallholder farmers in rural Kenya
                                                    </div>
                                                    <div align="left"
                                                        class="white--text font-weight-black mt-12 headline ml-8">
                                                        #SCWomenInTech #BecauseSheCan
                                                    </div>
                                                </v-flex>
                                                <v-flex xs12 class="white mt-16" style="position: absolute; bottom: 0; width: 50%">
                                                    <v-layout row wrap class="my-1">
                                                        <v-flex xs6>
                                                            <div align="center" class="mt-2">
                                                                <v-img max-width="150" src="img/logo.png"> </v-img>
                                                            </div>  
                                                        </v-flex>
                                                        <v-flex xs6>
                                                            <div align="center">
                                                                <v-img max-width="150" src="img/ibiz-black.png"> </v-img>
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                    </v-layout>
                                </v-card>
                            </div>
                        </v-flex>
                        <v-flex xs12 class="mt-10">
                            <div align="center">
                                <v-card elevation="0" class="pa-3"shaped outlined max-width="1200">
                                    <v-layout row wrap>
                                        <v-flex xs12 md6 style="border-radius: 20px 0px 0px 4px; background-image: linear-gradient(to bottom right, rgba(43,62,136,1), rgba(44,99,173,0.8));">
                                            <v-layout column>
                                                <v-flex xs12>
                                                    <div align="left"
                                                        class="sec--text font-weight-black mt-12 display-1 ml-8">
                                                        Zidallie
                                                    </div>
                                                    <div align="left"
                                                        class="sec--text font-weight-black headline ml-8">
                                                        (Transport Tech)
                                                    </div>
                                                    <div align="left" class="headline white--text font-weight-black mt-4 mx-8" style="line-height: 1.7;">
                                                        Zidallie is a provider of transport solutions that ensures safe and efficient transportation for students.
                                                    </div>
                                                    <div align="left"
                                                        class="white--text font-weight-black mt-12 headline ml-8">
                                                        #SCWomenInTech #BecauseSheCan
                                                    </div>
                                                </v-flex>
                                                <v-flex xs12 class="white mt-16" style="position: absolute; bottom: 0; width: 50%">
                                                    <v-layout row wrap class="my-1">
                                                        <v-flex xs6>
                                                            <div align="center" class="mt-2">
                                                                <v-img max-width="150" src="img/logo.png"> </v-img>
                                                            </div>  
                                                        </v-flex>
                                                        <v-flex xs6>
                                                            <div align="center">
                                                                <v-img max-width="150" src="img/ibiz-black.png"> </v-img>
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex xs12 md6>
                                            <div >
                                                <v-img v-if="echorich == 'image'" height="690"
                                                    src="img/cohort7/zidallie.png"
                                                    style="border-radius: 0px 4px 20px 0px;">
                                                </v-img>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </v-card>
                            </div>
                        </v-flex>
                        <v-flex xs12 class="mt-10">
                            <div align="center">
                                <v-card elevation="0" class="pa-3"shaped outlined max-width="1200">
                                    <v-layout row wrap>
                                        <v-flex xs12 md6>
                                            <div >
                                                <v-img v-if="echorich == 'image'" height="690"
                                                    src="img/cohort7/aerialgis.png"
                                                    style="border-radius: 20px 0px 0px 4px;">
                                                </v-img>
                                            </div>
                                        </v-flex>
                                        <v-flex xs12 md6 style="border-radius: 0px 4px 20px 0px; background-image: linear-gradient(to bottom right, rgba(43,62,136,1), rgba(44,99,173,0.8));">
                                            <v-layout column>
                                                <v-flex xs12>
                                                    <div align="left"
                                                        class="sec--text font-weight-black mt-12 display-1 ml-8">
                                                        AerialGIS
                                                    </div>
                                                    <div align="left"
                                                        class="sec--text font-weight-black headline ml-8">
                                                        (Virtual Reality Tech)
                                                    </div>
                                                    <div align="left" class="headline white--text font-weight-black mt-4 mx-8" style="line-height: 1.7;">
                                                       AerialGIS is a web application that seamlessly fuses subdivision maps with interactive mapping and VR technology to virtually transport property seekers to the property location on the map
                                                    </div>
                                                    <div align="left"
                                                        class="white--text font-weight-black mt-12 headline ml-8">
                                                        #SCWomenInTech #BecauseSheCan
                                                    </div>
                                                </v-flex>
                                                <v-flex xs12 class="white mt-16" style="position: absolute; bottom: 0; width: 50%">
                                                    <v-layout row wrap class="my-1">
                                                        <v-flex xs6>
                                                            <div align="center" class="mt-2">
                                                                <v-img max-width="150" src="img/logo.png"> </v-img>
                                                            </div>  
                                                        </v-flex>
                                                        <v-flex xs6>
                                                            <div align="center">
                                                                <v-img max-width="150" src="img/ibiz-black.png"> </v-img>
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                    </v-layout>
                                </v-card>
                            </div>
                        </v-flex>
                        <v-flex xs12 class="mt-10">
                            <div align="center">
                                <v-card elevation="0" class="pa-3"shaped outlined max-width="1200">
                                    <v-layout row wrap>
                                        <v-flex xs12 md6 style="border-radius: 20px 0px 0px 4px; background-image: linear-gradient(to bottom right, rgba(43,62,136,1), rgba(44,99,173,0.8));">
                                            <v-layout column>
                                                <v-flex xs12>
                                                    <div align="left"
                                                        class="sec--text font-weight-black mt-12 display-1 ml-8">
                                                        Africa Stem Girl
                                                    </div>
                                                    <div align="left"
                                                        class="sec--text font-weight-black headline ml-8">
                                                        (EdTech)
                                                    </div>
                                                    <div align="left" class="headline white--text font-weight-black mt-4 mx-8" style="line-height: 1.7;">
                                                        Africa Stem Girl aims at training individuals aged 7-17 yrs on coding skills.
                                                    </div>
                                                    <div align="left"
                                                        class="white--text font-weight-black mt-12 headline ml-8">
                                                        #SCWomenInTech #BecauseSheCan
                                                    </div>
                                                </v-flex>
                                                <v-flex xs12 class="white mt-16" style="position: absolute; bottom: 0; width: 50%">
                                                    <v-layout row wrap class="my-1">
                                                        <v-flex xs6>
                                                            <div align="center" class="mt-2">
                                                                <v-img max-width="150" src="img/logo.png"> </v-img>
                                                            </div>  
                                                        </v-flex>
                                                        <v-flex xs6>
                                                            <div align="center">
                                                                <v-img max-width="150" src="img/ibiz-black.png"> </v-img>
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex xs12 md6>
                                            <div >
                                                <v-img v-if="echorich == 'image'" height="690"
                                                    src="img/cohort7/africastem.png"
                                                    style="border-radius: 0px 4px 20px 0px;">
                                                </v-img>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </v-card>
                            </div>
                        </v-flex>
                        <v-flex xs12 class="mt-10">
                            <div align="center">
                                <v-card elevation="0" class="pa-3"shaped outlined max-width="1200">
                                    <v-layout row wrap>
                                        <v-flex xs12 md6>
                                            <div >
                                                <v-img v-if="echorich == 'image'" height="690"
                                                    src="img/cohort7/girah.png"
                                                    style="border-radius: 20px 0px 0px 4px;">
                                                </v-img>
                                            </div>
                                        </v-flex>
                                        <v-flex xs12 md6 style="border-radius: 0px 4px 20px 0px; background-image: linear-gradient(to bottom right, rgba(43,62,136,1), rgba(44,99,173,0.8));">
                                            <v-layout column>
                                                <v-flex xs12>
                                                    <div align="left"
                                                        class="sec--text font-weight-black mt-12 display-1 ml-8">
                                                        Girah Heritage Ltd.
                                                    </div>
                                                    <div align="left"
                                                        class="sec--text font-weight-black headline ml-8">
                                                        (Travel Tech)
                                                    </div>
                                                    <div align="left" class="headline white--text font-weight-black mt-4 mx-8" style="line-height: 1.7;">
                                                       Girah Heritage Ltd. is a sustainable tourism company providing unique cultural travel experiences for budget travelers.
                                                    </div>
                                                    <div align="left"
                                                        class="white--text font-weight-black mt-12 headline ml-8">
                                                        #SCWomenInTech #BecauseSheCan
                                                    </div>
                                                </v-flex>
                                                <v-flex xs12 class="white mt-16" style="position: absolute; bottom: 0; width: 50%">
                                                    <v-layout row wrap class="my-1">
                                                        <v-flex xs6>
                                                            <div align="center" class="mt-2">
                                                                <v-img max-width="150" src="img/logo.png"> </v-img>
                                                            </div>  
                                                        </v-flex>
                                                        <v-flex xs6>
                                                            <div align="center">
                                                                <v-img max-width="150" src="img/ibiz-black.png"> </v-img>
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                    </v-layout>
                                </v-card>
                            </div>
                        </v-flex>
                        <v-flex xs12 class="mt-10">
                            <div align="center">
                                <v-card elevation="0" class="pa-3"shaped outlined max-width="1200">
                                    <v-layout row wrap>
                                        <v-flex xs12 md6 style="border-radius: 20px 0px 0px 4px; background-image: linear-gradient(to bottom right, rgba(43,62,136,1), rgba(44,99,173,0.8));">
                                            <v-layout column>
                                                <v-flex xs12>
                                                    <div align="left"
                                                        class="sec--text font-weight-black mt-12 display-1 ml-8">
                                                        Jido
                                                    </div>
                                                    <div align="left"
                                                        class="sec--text font-weight-black headline ml-8">
                                                        (E-commerce)
                                                    </div>
                                                    <div align="left" class="headline white--text font-weight-black mt-4 mx-8" style="line-height: 1.7;">
                                                        Jido is an on-demand delivery management app designed to simplify package deliveries through goods consolidation and using route optimization technology
                                                    </div>
                                                    <div align="left"
                                                        class="white--text font-weight-black mt-12 headline ml-8">
                                                        #SCWomenInTech #BecauseSheCan
                                                    </div>
                                                </v-flex>
                                                <v-flex xs12 class="white mt-16" style="position: absolute; bottom: 0; width: 50%">
                                                    <v-layout row wrap class="my-1">
                                                        <v-flex xs6>
                                                            <div align="center" class="mt-2">
                                                                <v-img max-width="150" src="img/logo.png"> </v-img>
                                                            </div>  
                                                        </v-flex>
                                                        <v-flex xs6>
                                                            <div align="center">
                                                                <v-img max-width="150" src="img/ibiz-black.png"> </v-img>
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex xs12 md6>
                                            <div >
                                                <v-img v-if="echorich == 'image'" height="690"
                                                    src="img/cohort7/jido.png"
                                                    style="border-radius: 0px 4px 20px 0px;">
                                                </v-img>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </v-card>
                            </div>
                        </v-flex>
                        <v-flex xs12 class="mt-10">
                            <div align="center">
                                <v-card elevation="0" class="pa-3"shaped outlined max-width="1200">
                                    <v-layout row wrap>
                                        <v-flex xs12 md6>
                                            <div >
                                                <v-img v-if="echorich == 'image'" height="690"
                                                    src="img/cohort7/gwiji.png"
                                                    style="border-radius: 20px 0px 0px 4px;">
                                                </v-img>
                                            </div>
                                        </v-flex>
                                        <v-flex xs12 md6 style="border-radius: 0px 4px 20px 0px; background-image: linear-gradient(to bottom right, rgba(43,62,136,1), rgba(44,99,173,0.8));">
                                            <v-layout column>
                                                <v-flex xs12>
                                                    <div align="left"
                                                        class="sec--text font-weight-black mt-12 display-1 ml-8">
                                                        Gwiji for Women
                                                    </div>
                                                    <div align="left"
                                                        class="sec--text font-weight-black headline ml-8">
                                                        (Social Enterprise)
                                                    </div>
                                                    <div align="left" class="headline white--text font-weight-black mt-4 mx-8" style="line-height: 1.7;">
                                                       Gwiji for Women is a tech social enterprise that empowers marginalized and underprivileged women from slum communities who work as casual cleaners.
                                                    </div>
                                                    <div align="left"
                                                        class="white--text font-weight-black mt-12 headline ml-8">
                                                        #SCWomenInTech #BecauseSheCan
                                                    </div>
                                                </v-flex>
                                                <v-flex xs12 class="white mt-16" style="position: absolute; bottom: 0; width: 50%">
                                                    <v-layout row wrap class="my-1">
                                                        <v-flex xs6>
                                                            <div align="center" class="mt-2">
                                                                <v-img max-width="150" src="img/logo.png"> </v-img>
                                                            </div>  
                                                        </v-flex>
                                                        <v-flex xs6>
                                                            <div align="center">
                                                                <v-img max-width="150" src="img/ibiz-black.png"> </v-img>
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                    </v-layout>
                                </v-card>
                            </div>
                        </v-flex>
                        <v-flex xs12 class="mt-10">
                            <div align="center">
                                <v-card elevation="0" class="pa-3"shaped outlined max-width="1200">
                                    <v-layout row wrap>
                                        <v-flex xs12 md6 style="border-radius: 20px 0px 0px 4px; background-image: linear-gradient(to bottom right, rgba(43,62,136,1), rgba(44,99,173,0.8));">
                                            <v-layout column>
                                                <v-flex xs12>
                                                    <div align="left"
                                                        class="sec--text font-weight-black mt-12 display-1 ml-8">
                                                        Hayah Cradle to Bloom
                                                    </div>
                                                    <div align="left"
                                                        class="sec--text font-weight-black headline ml-8">
                                                        (Health Tech)
                                                    </div>
                                                    <div align="left" class="headline white--text font-weight-black mt-4 mx-8" style="line-height: 1.7;">
                                                        Hayah Cradle to Bloom utilizes AI for maternal health, enhancing accessibility and sustainability in healthcare, with a focus on preventive maternal and infant health.
                                                    </div>
                                                    <div align="left"
                                                        class="white--text font-weight-black mt-12 headline ml-8">
                                                        #SCWomenInTech #BecauseSheCan
                                                    </div>
                                                </v-flex>
                                                <v-flex xs12 class="white mt-16" style="position: absolute; bottom: 0; width: 50%">
                                                    <v-layout row wrap class="my-1">
                                                        <v-flex xs6>
                                                            <div align="center" class="mt-2">
                                                                <v-img max-width="150" src="img/logo.png"> </v-img>
                                                            </div>  
                                                        </v-flex>
                                                        <v-flex xs6>
                                                            <div align="center">
                                                                <v-img max-width="150" src="img/ibiz-black.png"> </v-img>
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex xs12 md6>
                                            <div >
                                                <v-img v-if="echorich == 'image'" height="690"
                                                    src="img/cohort7/hayah.png"
                                                    style="border-radius: 0px 4px 20px 0px;">
                                                </v-img>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </v-card>
                            </div>
                        </v-flex>
                        <v-flex xs12 class="mt-10">
                            <div align="center">
                                <v-card elevation="0" class="pa-3"shaped outlined max-width="1200">
                                    <v-layout row wrap>
                                        <v-flex xs12 md6>
                                            <div >
                                                <v-img v-if="echorich == 'image'" height="690"
                                                    src="img/cohort7/investa.png"
                                                    style="border-radius: 20px 0px 0px 4px;">
                                                </v-img>
                                            </div>
                                        </v-flex>
                                        <v-flex xs12 md6 style="border-radius: 0px 4px 20px 0px; background-image: linear-gradient(to bottom right, rgba(43,62,136,1), rgba(44,99,173,0.8));">
                                            <v-layout column>
                                                <v-flex xs12>
                                                    <div align="left"
                                                        class="sec--text font-weight-black mt-12 display-1 ml-8">
                                                        Investa Farm
                                                    </div>
                                                    <div align="left"
                                                        class="sec--text font-weight-black headline ml-8">
                                                        (Agri-Fintech)
                                                    </div>
                                                    <div align="left" class="headline white--text font-weight-black mt-4 mx-8" style="line-height: 1.7;">
                                                       Investa Farm is revolutionizing agricultural financing through blockchain and AI technologies, connecting farmers with cryptocurrency lenders worldwide.
                                                    </div>
                                                    <div align="left"
                                                        class="white--text font-weight-black mt-12 headline ml-8">
                                                        #SCWomenInTech #BecauseSheCan
                                                    </div>
                                                </v-flex>
                                                <v-flex xs12 class="white mt-16" style="position: absolute; bottom: 0; width: 50%">
                                                    <v-layout row wrap class="my-1">
                                                        <v-flex xs6>
                                                            <div align="center" class="mt-2">
                                                                <v-img max-width="150" src="img/logo.png"> </v-img>
                                                            </div>  
                                                        </v-flex>
                                                        <v-flex xs6>
                                                            <div align="center">
                                                                <v-img max-width="150" src="img/ibiz-black.png"> </v-img>
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                    </v-layout>
                                </v-card>
                            </div>
                        </v-flex>
                        
                        <v-flex xs12 class="mt-10">
                            <div align="center">
                                <v-card elevation="0" class="pa-3"shaped outlined max-width="1200">
                                    <v-layout row wrap>
                                        <v-flex xs12 md6 style="border-radius: 20px 0px 0px 4px; background-image: linear-gradient(to bottom right, rgba(43,62,136,1), rgba(44,99,173,0.8));">
                                            <v-layout column>
                                                <v-flex xs12>
                                                    <div align="left"
                                                        class="sec--text font-weight-black mt-12 display-1 ml-8">
                                                        Kavalian
                                                    </div>
                                                    <div align="left"
                                                        class="sec--text font-weight-black headline ml-8">
                                                        (Clean Tech)
                                                    </div>
                                                    <div align="left" class="headline white--text font-weight-black mt-4 mx-8" style="line-height: 1.7;">
                                                       Kavalian Limited is a Kenyan clean-tech company on a mission to revolutionize waste management and empower communities throught innovation waste management solutions.
                                                    </div>
                                                    <div align="left"
                                                        class="white--text font-weight-black mt-12 headline ml-8">
                                                        #SCWomenInTech #BecauseSheCan
                                                    </div>
                                                </v-flex>
                                                <v-flex xs12 class="white mt-16" style="position: absolute; bottom: 0; width: 50%">
                                                    <v-layout row wrap class="my-1">
                                                        <v-flex xs6>
                                                            <div align="center" class="mt-2">
                                                                <v-img max-width="150" src="img/logo.png"> </v-img>
                                                            </div>  
                                                        </v-flex>
                                                        <v-flex xs6>
                                                            <div align="center">
                                                                <v-img max-width="150" src="img/ibiz-black.png"> </v-img>
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex xs12 md6>
                                            <div >
                                                <v-img v-if="echorich == 'image'" height="690"
                                                    src="img/cohort7/kavalian.png"
                                                    style="border-radius: 0px 4px 20px 0px;">
                                                </v-img>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </v-card>
                            </div>
                        </v-flex>
                    </v-layout>
                    <v-layout column class="my-16 hidden-md-and-up">
                        <v-flex xs12>
                            <div align="center" class="black--text font-weight-black display-1">Top 15</div>
                        </v-flex>
                        <v-flex xs12 class="mt-10">
                            <v-card elevation="0" shaped outlined color="grey lighten-4"
                                style="border-radius: 20px 20px 20px 20px;">
                                <v-layout row wrap>
                                    <v-flex xs12 md2>
                                        <div class="mx-3">
                                            <v-img v-if="echorichMobile == 'image'" height="525"
                                                src="img/cohort6/ecorich-solutions.webp"
                                                style="border-radius: 20px 20px 20px 20px;">
                                                <div class="d-flex transition-fast-in-fast-out v-card--reveal white--text"
                                                    style="height: 100%;">
                                                    <a style="text-decoration: none;" @click="echorichMobile = 'video'">
                                                        <v-icon size="100px" class="white--text">
                                                            mdi-play-circle-outline
                                                        </v-icon>
                                                    </a>
                                                </div>
                                            </v-img>
                                            <video v-if="echorichMobile == 'video'"
                                                poster="img/cohort6/ecorich-solutions.webp"
                                                src="img/cohort6/ecorich-portrait.mp4" width="100%" autoplay controls
                                                style="border-radius: 20px 20px 20px 20px;">
                                            </video>
                                        </div>
                                    </v-flex>
                                    <v-flex xs12 md8 class="pa-5" style="border-radius: 20px 20px 20px 20px;">
                                        <v-layout column>
                                            <v-flex xs12 class="my-2">
                                                <div align="center" class="mx-3">
                                                    <v-layout row wrap>
                                                        <v-flex xs1>
                                                            <div align="right" class="mr-1 mt-16">
                                                                <v-img width="20px" src="img/a-1.png">
                                                                </v-img>
                                                            </div>
                                                        </v-flex>
                                                        <v-flex xs10>
                                                            <div align="center"
                                                                class="black--text font-weight-black mt-12 display-1">
                                                                Ecorich Solutions Limited
                                                            </div>
                                                        </v-flex>
                                                        <v-flex xs1>
                                                            <div align="left" class="ml-1 mt-16">
                                                                <v-img width="20px" src="img/a-2.png">
                                                                </v-img>
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>

                                                </div>
                                                <div class="mt-14 mb-12">
                                                    <v-flex xs12>
                                                        <v-layout row wrap>
                                                            <v-flex xs1>

                                                            </v-flex>
                                                            <v-flex xs10>
                                                                <v-divider class="primary mx-10"></v-divider>
                                                                <v-divider class="secondary"></v-divider>
                                                                <v-divider class="primary mx-10"></v-divider>
                                                            </v-flex>
                                                            <v-flex xs1>

                                                            </v-flex>
                                                        </v-layout>
                                                    </v-flex>
                                                </div>
                                                <div align="center" class="mt-4">A start-up that provides cheap
                                                    fertilizer
                                                    using WasteBot, a recycling bin that uses AI-enabled
                                                    recycling to convert waste to valuable and affordable primary farm
                                                    input
                                                    at the point of disposal.</div>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                    <v-flex xs12 md2>
                                        <div class="mb-5">
                                            <div align="center" class="mb-15">
                                                <v-icon size="100px" class="primary--text" style="margin-top: -55px">
                                                    mdi-recycle
                                                </v-icon>
                                                <v-icon size="100px" class="secondary--text" style="margin-top: 90px;">
                                                    mdi-robot-happy
                                                </v-icon>
                                            </div>
                                        </div>

                                    </v-flex>
                                </v-layout>
                                <div class="mx-5">
                                    <v-divider class="primary"></v-divider>
                                </div>
                            </v-card>
                        </v-flex>
                        <v-flex xs12 class="mt-5">
                            <v-card elevation="0" shaped outlined color="grey lighten-4"
                                style="border-radius: 20px 20px 20px 20px;">
                                <v-layout row wrap>
                                    <v-flex xs12 md2>
                                        <div class="mx-3">
                                            <v-img v-if="entoMobile == 'image'" height="525"
                                                src="img/cohort6/ento-farms.webp"
                                                style="border-radius: 20px 20px 20px 20px;">
                                                <div class="d-flex transition-fast-in-fast-out v-card--reveal white--text"
                                                    style="height: 100%;">
                                                    <a style="text-decoration: none;" @click="entoMobile = 'video'">
                                                        <v-icon size="100px" class="white--text">
                                                            mdi-play-circle-outline
                                                        </v-icon>
                                                    </a>
                                                </div>
                                            </v-img>
                                            <video v-if="entoMobile == 'video'" poster="img/cohort6/ento-farms.webp"
                                                src="img/cohort6/ento-portrait.mp4" width="100%" autoplay controls
                                                style="border-radius: 20px 20px 20px 20px;">
                                            </video>
                                        </div>

                                    </v-flex>
                                    <v-flex xs12 md8 class="pa-5" style="border-radius: 20px 20px 20px 20px;">
                                        <v-layout column>
                                            <v-flex xs12 class="my-2">
                                                <div align="center" class="mx-3">
                                                    <v-layout row wrap>
                                                        <v-flex xs1>
                                                            <div align="right" class="mr-1 mt-16">
                                                                <v-img width="20px" src="img/a-1.png">
                                                                </v-img>
                                                            </div>
                                                        </v-flex>
                                                        <v-flex xs10>
                                                            <div align="center"
                                                                class="black--text font-weight-black mt-12 display-1">
                                                                Ento Farms
                                                            </div>
                                                        </v-flex>
                                                        <v-flex xs1>
                                                            <div align="left" class="ml-1 mt-16">
                                                                <v-img width="20px" src="img/a-2.png">
                                                                </v-img>
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>

                                                </div>
                                                <div class="mt-14 mb-12">
                                                    <v-flex xs12>
                                                        <v-layout row wrap>
                                                            <v-flex xs1>

                                                            </v-flex>
                                                            <v-flex xs10>
                                                                <v-divider class="primary mx-10"></v-divider>
                                                                <v-divider class="secondary"></v-divider>
                                                                <v-divider class="primary mx-10"></v-divider>
                                                            </v-flex>
                                                            <v-flex xs1>

                                                            </v-flex>
                                                        </v-layout>
                                                    </v-flex>
                                                </div>
                                                <div align="center" class="mt-4">A start-up that produces protein-rich
                                                    animal feed based on dried, inactive insects that are produced
                                                    through the fermentation of organic waste.</div>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                    <v-flex xs12 md2>
                                        <div class="mb-5">
                                            <div align="center" class="mb-15">
                                                <v-icon size="100px" class="primary--text" style="margin-top: -55px">
                                                    mdi-cow
                                                </v-icon>
                                                <v-icon size="100px" class="secondary--text" style="margin-top: 115px;">
                                                    mdi-bee
                                                </v-icon>
                                            </div>
                                        </div>
                                    </v-flex>

                                </v-layout>
                                <div class="mx-5">
                                    <v-divider class="primary"></v-divider>
                                </div>
                            </v-card>
                        </v-flex>
                        <v-flex xs12 class="mt-5">
                            <v-card elevation="0" shaped outlined color="grey lighten-4"
                                style="border-radius: 20px 20px 20px 20px;">
                                <v-layout row wrap>
                                    <v-flex xs12 md2>
                                        <div class="mx-3">
                                            <v-img v-if="instructMobile == 'image'" height="525"
                                                src="img/cohort6/instruct-africa.webp"
                                                style="border-radius: 20px 20px 20px 20px;">
                                                <div class="d-flex transition-fast-in-fast-out v-card--reveal white--text"
                                                    style="height: 100%;">
                                                    <a style="text-decoration: none;" @click="instructMobile = 'video'">
                                                        <v-icon size="100px" class="white--text">
                                                            mdi-play-circle-outline
                                                        </v-icon>
                                                    </a>
                                                </div>
                                            </v-img>
                                            <video v-if="instructMobile == 'video'"
                                                poster="img/cohort6/instruct-africa.webp"
                                                src="img/cohort6/instruct-portrait.mp4" width="100%" autoplay controls
                                                style="border-radius: 20px 20px 20px 20px;">
                                            </video>
                                        </div>
                                    </v-flex>
                                    <v-flex xs12 md8 class="pa-5" style="border-radius: 20px 20px 20px 20px;">
                                        <v-layout column>
                                            <v-flex xs12 class="my-2">
                                                <div align="center" class="mx-3">
                                                    <v-layout row wrap>
                                                        <v-flex xs1>
                                                            <div align="right" class="mr-1 mt-16">
                                                                <v-img width="20px" src="img/a-1.png">
                                                                </v-img>
                                                            </div>
                                                        </v-flex>
                                                        <v-flex xs10>
                                                            <div align="center"
                                                                class="black--text font-weight-black mt-12 display-1">
                                                                InstructKE
                                                            </div>
                                                        </v-flex>
                                                        <v-flex xs1>
                                                            <div align="left" class="ml-1 mt-16">
                                                                <v-img width="20px" src="img/a-2.png">
                                                                </v-img>
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>

                                                </div>
                                                <div class="mt-14 mb-12">
                                                    <v-flex xs12>
                                                        <v-layout row wrap>
                                                            <v-flex xs1>

                                                            </v-flex>
                                                            <v-flex xs10>
                                                                <v-divider class="primary mx-10"></v-divider>
                                                                <v-divider class="secondary"></v-divider>
                                                                <v-divider class="primary mx-10"></v-divider>
                                                            </v-flex>
                                                            <v-flex xs1>

                                                            </v-flex>
                                                        </v-layout>
                                                    </v-flex>
                                                </div>
                                                <div align="center" class="mt-4">Instruct is a legal tech company that
                                                    leverages tech and data to provide legal assurance to matatu
                                                    drivers and bodaboda operators. Our mission is to democratize access
                                                    to
                                                    justice and legal tools in
                                                    the informal sector.</div>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                    <v-flex xs12 md2>
                                        <div class="mb-5">
                                            <div align="center" class="mb-15">
                                                <v-icon size="100px" class="primary--text" style="margin-top: -55px">
                                                    mdi-gavel
                                                </v-icon>
                                                <v-icon size="100px" class="secondary--text" style="margin-top: 90px;">
                                                    mdi-bus-stop
                                                </v-icon>
                                            </div>
                                        </div>

                                    </v-flex>
                                </v-layout>
                                <div class="mx-5">
                                    <v-divider class="primary"></v-divider>
                                </div>
                            </v-card>
                        </v-flex>
                        <v-flex xs12 class="mt-5">
                            <v-card elevation="0" shaped outlined color="grey lighten-4"
                                style="border-radius: 20px 20px 20px 20px;">
                                <v-layout row wrap>
                                    <v-flex xs12 md2>
                                        <div class="mx-3">
                                            <v-img v-if="recessMobile == 'image'" height="525"
                                                src="img/cohort6/know-learning-limited.webp"
                                                style="border-radius: 20px 20px 20px 20px;">
                                                <div class="d-flex transition-fast-in-fast-out v-card--reveal white--text"
                                                    style="height: 100%;">
                                                    <a style="text-decoration: none;" @click="recessMobile = 'video'">
                                                        <v-icon size="100px" class="white--text">
                                                            mdi-play-circle-outline
                                                        </v-icon>
                                                    </a>
                                                </div>
                                            </v-img>
                                            <video v-if="recessMobile == 'video'"
                                                poster="img/cohort6/know-learning-limited.webp"
                                                src="img/cohort6/recess-portrait.mp4" width="100%" autoplay controls
                                                style="border-radius: 20px 20px 20px 20px;">
                                            </video>
                                        </div>

                                    </v-flex>
                                    <v-flex xs12 md8 class="pa-5" style="border-radius: 20px 20px 20px 20px;">
                                        <v-layout column>
                                            <v-flex xs12 class="my-2">
                                                <div align="center" class="mx-3">
                                                    <v-layout row wrap>
                                                        <v-flex xs1>
                                                            <div align="right" class="mr-1 mt-16">
                                                                <v-img width="20px" src="img/a-1.png">
                                                                </v-img>
                                                            </div>
                                                        </v-flex>
                                                        <v-flex xs10>
                                                            <div align="center"
                                                                class="black--text font-weight-black mt-12 display-1">
                                                                Know Learning Limited
                                                            </div>
                                                        </v-flex>
                                                        <v-flex xs1>
                                                            <div align="left" class="ml-1 mt-16">
                                                                <v-img width="20px" src="img/a-2.png">
                                                                </v-img>
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>

                                                </div>
                                                <div class="mt-14 mb-12">
                                                    <v-flex xs12>
                                                        <v-layout row wrap>
                                                            <v-flex xs1>

                                                            </v-flex>
                                                            <v-flex xs10>
                                                                <v-divider class="primary mx-10"></v-divider>
                                                                <v-divider class="secondary"></v-divider>
                                                                <v-divider class="primary mx-10"></v-divider>
                                                            </v-flex>
                                                            <v-flex xs1>

                                                            </v-flex>
                                                        </v-layout>
                                                    </v-flex>
                                                </div>
                                                <div align="center" class="mt-4">Revolutionizes parental engagement by
                                                    providing a user-friendly platform that bridges the gap
                                                    between schools and parents..</div>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                    <v-flex xs12 md2>
                                        <div class="mb-5">
                                            <div align="center" class="mb-15">
                                                <v-icon size="100px" class="primary--text" style="margin-top: -55px">
                                                    mdi-cast-education
                                                </v-icon>
                                                <v-icon size="100px" class="secondary--text" style="margin-top: 115px;">
                                                    mdi-human-male-female-child
                                                </v-icon>
                                            </div>
                                        </div>
                                    </v-flex>

                                </v-layout>
                                <div class="mx-5">
                                    <v-divider class="primary"></v-divider>
                                </div>
                            </v-card>
                        </v-flex>
                        <v-flex xs12 class="mt-5">
                            <v-card elevation="0" shaped outlined color="grey lighten-4"
                                style="border-radius: 20px 20px 20px 20px;">
                                <v-layout row wrap>
                                    <v-flex xs12 md2>
                                        <div class="mx-3">
                                            <v-img v-if="rheaMobile == 'image'" height="525"
                                                src="img/cohort6/rhea-soil-health-management-it.webp"
                                                style="border-radius: 20px 20px 20px 20px;">
                                                <div class="d-flex transition-fast-in-fast-out v-card--reveal white--text"
                                                    style="height: 100%;">
                                                    <a style="text-decoration: none;" @click="rheaMobile = 'video'">
                                                        <v-icon size="100px" class="white--text">
                                                            mdi-play-circle-outline
                                                        </v-icon>
                                                    </a>
                                                </div>
                                            </v-img>
                                            <video v-if="rheaMobile == 'video'"
                                                poster="img/cohort6/rhea-soil-health-management-it.webp"
                                                src="img/cohort6/rhea-portrait.mp4" width="100%" autoplay controls
                                                style="border-radius: 20px 20px 20px 20px;">
                                            </video>
                                        </div>
                                    </v-flex>
                                    <v-flex xs12 md8 class="pa-5" style="border-radius: 20px 20px 20px 20px;">
                                        <v-layout column>
                                            <v-flex xs12 class="my-2">
                                                <div align="center" class="mx-3">
                                                    <v-layout row wrap>
                                                        <v-flex xs1>
                                                            <div align="right" class="mr-1 mt-16">
                                                                <v-img width="20px" src="img/a-1.png">
                                                                </v-img>
                                                            </div>
                                                        </v-flex>
                                                        <v-flex xs10>
                                                            <div align="center"
                                                                class="black--text font-weight-black mt-12 display-1">
                                                                Rhea Soil Health Management Ltd
                                                            </div>
                                                        </v-flex>
                                                        <v-flex xs1>
                                                            <div align="left" class="ml-1 mt-16">
                                                                <v-img width="20px" src="img/a-2.png">
                                                                </v-img>
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>

                                                </div>
                                                <div class="mt-14 mb-12">
                                                    <v-flex xs12>
                                                        <v-layout row wrap>
                                                            <v-flex xs1>

                                                            </v-flex>
                                                            <v-flex xs10>
                                                                <v-divider class="primary mx-10"></v-divider>
                                                                <v-divider class="secondary"></v-divider>
                                                                <v-divider class="primary mx-10"></v-divider>
                                                            </v-flex>
                                                            <v-flex xs1>

                                                            </v-flex>
                                                        </v-layout>
                                                    </v-flex>
                                                </div>
                                                <div align="center" class="mt-4">Rhea provides access to soil testing
                                                    and
                                                    agricultural extension services to smallholder farmers,
                                                    analyzing soil and providing results and fertilizer recommendations
                                                    through a machine learning-
                                                    powered recommendation system.</div>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                    <v-flex xs12 md2>
                                        <div class="mb-5">
                                            <div align="center" class="mb-15">
                                                <v-icon size="100px" class="primary--text" style="margin-top: -55px">
                                                    mdi-silo
                                                </v-icon>
                                                <v-icon size="100px" class="secondary--text" style="margin-top: 90px;">
                                                    mdi-sprout
                                                </v-icon>
                                            </div>
                                        </div>

                                    </v-flex>
                                </v-layout>
                                <div class="mx-5">
                                    <v-divider class="primary"></v-divider>
                                </div>
                            </v-card>
                        </v-flex>
                        <v-flex xs12 class="mt-5">
                            <v-card elevation="0" shaped outlined color="grey lighten-4"
                                style="border-radius: 20px 20px 20px 20px;">
                                <v-layout row wrap>
                                    <v-flex xs12 md2>
                                        <div class="mx-3">
                                            <v-img height="525" src="img/cohort6/acre-insights.webp"
                                                style="border-radius: 20px 20px 20px 20px;" />
                                        </div>
                                    </v-flex>
                                    <v-flex xs12 md8 class="pa-5" style="border-radius: 20px 20px 20px 20px;">
                                        <v-layout column>
                                            <v-flex xs12 class="my-2">
                                                <div align="center" class="mx-3">
                                                    <v-layout row wrap>
                                                        <v-flex xs1>
                                                            <div align="right" class="mr-1 mt-16">
                                                                <v-img width="20px" src="img/a-1.png">
                                                                </v-img>
                                                            </div>
                                                        </v-flex>
                                                        <v-flex xs10>
                                                            <div align="center"
                                                                class="black--text font-weight-black mt-12 display-1">
                                                                Acre Insights
                                                            </div>
                                                        </v-flex>
                                                        <v-flex xs1>
                                                            <div align="left" class="ml-1 mt-16">
                                                                <v-img width="20px" src="img/a-2.png">
                                                                </v-img>
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>

                                                </div>
                                                <div class="mt-14 mb-12">
                                                    <v-flex xs12>
                                                        <v-layout row wrap>
                                                            <v-flex xs1>

                                                            </v-flex>
                                                            <v-flex xs10>
                                                                <v-divider class="primary mx-10"></v-divider>
                                                                <v-divider class="secondary"></v-divider>
                                                                <v-divider class="primary mx-10"></v-divider>
                                                            </v-flex>
                                                            <v-flex xs1>

                                                            </v-flex>
                                                        </v-layout>
                                                    </v-flex>
                                                </div>
                                                <div align="center" class="mt-4">A firm that uses drones and satellites
                                                    to
                                                    collect data on crop growth, soil moisture, and other
                                                    indicators, then interprets it using advanced analytics and machine
                                                    learning algorithms for informed</div>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                    <v-flex xs12 md2>
                                        <div class="mb-5">
                                            <div align="center" class="mb-15">
                                                <v-icon size="100px" class="primary--text" style="margin-top: -55px">
                                                    mdi-quadcopter
                                                </v-icon>
                                                <v-icon size="100px" class="secondary--text" style="margin-top: 115px;">
                                                    mdi-flower-tulip
                                                </v-icon>
                                            </div>
                                        </div>
                                    </v-flex>

                                </v-layout>
                                <div class="mx-5">
                                    <v-divider class="primary"></v-divider>
                                </div>
                            </v-card>
                        </v-flex>
                        <v-flex xs12 class="mt-5">
                            <v-card elevation="0" shaped outlined color="grey lighten-4"
                                style="border-radius: 20px 20px 20px 20px;">
                                <v-layout row wrap>
                                    <v-flex xs12 md2>
                                        <div class="mx-3">
                                            <v-img height="525" src="img/cohort6/agritech-analytics.webp"
                                                style="border-radius: 20px 20px 20px 20px;" />
                                        </div>
                                    </v-flex>
                                    <v-flex xs12 md8 class="pa-5" style="border-radius: 20px 20px 20px 20px;">
                                        <v-layout column>
                                            <v-flex xs12 class="my-2">
                                                <div align="center" class="mx-3">
                                                    <v-layout row wrap>
                                                        <v-flex xs1>
                                                            <div align="right" class="mr-1 mt-16">
                                                                <v-img width="20px" src="img/a-1.png">
                                                                </v-img>
                                                            </div>
                                                        </v-flex>
                                                        <v-flex xs10>
                                                            <div align="center"
                                                                class="black--text font-weight-black mt-12 display-1">
                                                                Agritech innovation hub
                                                            </div>
                                                        </v-flex>
                                                        <v-flex xs1>
                                                            <div align="left" class="ml-1 mt-16">
                                                                <v-img width="20px" src="img/a-2.png">
                                                                </v-img>
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>

                                                </div>
                                                <div class="mt-14 mb-12">
                                                    <v-flex xs12>
                                                        <v-layout row wrap>
                                                            <v-flex xs1>

                                                            </v-flex>
                                                            <v-flex xs10>
                                                                <v-divider class="primary mx-10"></v-divider>
                                                                <v-divider class="secondary"></v-divider>
                                                                <v-divider class="primary mx-10"></v-divider>
                                                            </v-flex>
                                                            <v-flex xs1>

                                                            </v-flex>
                                                        </v-layout>
                                                    </v-flex>
                                                </div>
                                                <div align="center" class="mt-4">We build the innovation capabilities of
                                                    start-ups in universities in Kenya to help geospatial
                                                    information systems and AgriTech students develop rare and valuable
                                                    tech
                                                    skills.</div>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                    <v-flex xs12 md2>
                                        <div class="mb-5">
                                            <div align="center" class="mb-15">
                                                <v-icon size="100px" class="primary--text" style="margin-top: -55px">
                                                    mdi-earth
                                                </v-icon>
                                                <v-icon size="100px" class="secondary--text" style="margin-top: 90px;">
                                                    mdi-laptop
                                                </v-icon>
                                            </div>
                                        </div>

                                    </v-flex>
                                </v-layout>
                                <div class="mx-5">
                                    <v-divider class="primary"></v-divider>
                                </div>
                            </v-card>
                        </v-flex>
                        <v-flex xs12 class="mt-5">
                            <v-card elevation="0" shaped outlined color="grey lighten-4"
                                style="border-radius: 20px 20px 20px 20px;">
                                <v-layout row wrap>
                                    <v-flex xs12 md2>
                                        <div class="mx-3">
                                            <v-img height="525" src="img/cohort6/imani-health.webp"
                                                style="border-radius: 20px 20px 20px 20px;" />
                                        </div>
                                    </v-flex>
                                    <v-flex xs12 md8 class="pa-5" style="border-radius: 20px 20px 20px 20px;">
                                        <v-layout column>
                                            <v-flex xs12 class="my-2">
                                                <div align="center" class="mx-3">
                                                    <v-layout row wrap>
                                                        <v-flex xs1>
                                                            <div align="right" class="mr-1 mt-16">
                                                                <v-img width="20px" src="img/a-1.png">
                                                                </v-img>
                                                            </div>
                                                        </v-flex>
                                                        <v-flex xs10>
                                                            <div align="center"
                                                                class="black--text font-weight-black mt-12 display-1">
                                                                Imani Health Wallet
                                                            </div>
                                                        </v-flex>
                                                        <v-flex xs1>
                                                            <div align="left" class="ml-1 mt-16">
                                                                <v-img width="20px" src="img/a-2.png">
                                                                </v-img>
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>

                                                </div>
                                                <div class="mt-14 mb-12">
                                                    <v-flex xs12>
                                                        <v-layout row wrap>
                                                            <v-flex xs1>

                                                            </v-flex>
                                                            <v-flex xs10>
                                                                <v-divider class="primary mx-10"></v-divider>
                                                                <v-divider class="secondary"></v-divider>
                                                                <v-divider class="primary mx-10"></v-divider>
                                                            </v-flex>
                                                            <v-flex xs1>

                                                            </v-flex>
                                                        </v-layout>
                                                    </v-flex>
                                                </div>
                                                <div align="center" class="mt-4">An e-wallet that enables people to save
                                                    specifically for healthcare. It breaks down large payments
                                                    into piecemeal payments that one can easily pay over time before
                                                    they
                                                    need the healthcare service.</div>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                    <v-flex xs12 md2>
                                        <div class="mb-5">
                                            <div align="center" class="mb-15">
                                                <v-icon size="100px" class="primary--text" style="margin-top: -55px">
                                                    mdi-credit-card
                                                </v-icon>
                                                <v-icon size="100px" class="secondary--text" style="margin-top: 115px;">
                                                    mdi-hospital-box
                                                </v-icon>
                                            </div>
                                        </div>
                                    </v-flex>

                                </v-layout>
                                <div class="mx-5">
                                    <v-divider class="primary"></v-divider>
                                </div>
                            </v-card>
                        </v-flex>
                        <v-flex xs12 class="mt-5">
                            <v-card elevation="0" shaped outlined color="grey lighten-4"
                                style="border-radius: 20px 20px 20px 20px;">
                                <v-layout row wrap>
                                    <v-flex xs12 md2>
                                        <div class="mx-3">
                                            <v-img height="525" src="img/cohort6/myafya-africa.webp"
                                                style="border-radius: 20px 20px 20px 20px;" />
                                        </div>
                                    </v-flex>
                                    <v-flex xs12 md8 class="pa-5" style="border-radius: 20px 20px 20px 20px;">
                                        <v-layout column>
                                            <v-flex xs12 class="my-2">
                                                <div align="center" class="mx-3">
                                                    <v-layout row wrap>
                                                        <v-flex xs1>
                                                            <div align="right" class="mr-1 mt-16">
                                                                <v-img width="20px" src="img/a-1.png">
                                                                </v-img>
                                                            </div>
                                                        </v-flex>
                                                        <v-flex xs10>
                                                            <div align="center"
                                                                class="black--text font-weight-black mt-12 display-1">
                                                                MyAfyaAfrica
                                                            </div>
                                                        </v-flex>
                                                        <v-flex xs1>
                                                            <div align="left" class="ml-1 mt-16">
                                                                <v-img width="20px" src="img/a-2.png">
                                                                </v-img>
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>

                                                </div>
                                                <div class="mt-14 mb-12">
                                                    <v-flex xs12>
                                                        <v-layout row wrap>
                                                            <v-flex xs1>

                                                            </v-flex>
                                                            <v-flex xs10>
                                                                <v-divider class="primary mx-10"></v-divider>
                                                                <v-divider class="secondary"></v-divider>
                                                                <v-divider class="primary mx-10"></v-divider>
                                                            </v-flex>
                                                            <v-flex xs1>

                                                            </v-flex>
                                                        </v-layout>
                                                    </v-flex>
                                                </div>
                                                <div align="center" class="mt-4">MyAfya Africa is a mental health
                                                    organization and digital-health company dedicated to
                                                    revolutionizing the accessibility and availability of mental health
                                                    services.</div>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                    <v-flex xs12 md2>
                                        <div class="mb-5">
                                            <div align="center" class="mb-15">
                                                <v-icon size="100px" class="primary--text" style="margin-top: -55px">
                                                    mdi-head-snowflake
                                                </v-icon>
                                                <v-icon size="100px" class="secondary--text" style="margin-top: 90px;">
                                                    mdi-face-agent
                                                </v-icon>
                                            </div>
                                        </div>

                                    </v-flex>
                                </v-layout>
                                <div class="mx-5">
                                    <v-divider class="primary"></v-divider>
                                </div>
                            </v-card>
                        </v-flex>
                        <v-flex xs12 class="mt-5">
                            <v-card elevation="0" shaped outlined color="grey lighten-4"
                                style="border-radius: 20px 20px 20px 20px;">
                                <v-layout row wrap>
                                    <v-flex xs12 md2>
                                        <div class="mx-3">
                                            <v-img height="525" src="img/cohort6/panacare.webp"
                                                style="border-radius: 20px 20px 20px 20px;" />
                                        </div>
                                    </v-flex>
                                    <v-flex xs12 md8 class="pa-5" style="border-radius: 20px 20px 20px 20px;">
                                        <v-layout column>
                                            <v-flex xs12 class="my-2">
                                                <div align="center" class="mx-3">
                                                    <v-layout row wrap>
                                                        <v-flex xs1>
                                                            <div align="right" class="mr-1 mt-16">
                                                                <v-img width="20px" src="img/a-1.png">
                                                                </v-img>
                                                            </div>
                                                        </v-flex>
                                                        <v-flex xs10>
                                                            <div align="center"
                                                                class="black--text font-weight-black mt-12 display-1">
                                                                Panacare
                                                            </div>
                                                        </v-flex>
                                                        <v-flex xs1>
                                                            <div align="left" class="ml-1 mt-16">
                                                                <v-img width="20px" src="img/a-2.png">
                                                                </v-img>
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>

                                                </div>
                                                <div class="mt-14 mb-12">
                                                    <v-flex xs12>
                                                        <v-layout row wrap>
                                                            <v-flex xs1>

                                                            </v-flex>
                                                            <v-flex xs10>
                                                                <v-divider class="primary mx-10"></v-divider>
                                                                <v-divider class="secondary"></v-divider>
                                                                <v-divider class="primary mx-10"></v-divider>
                                                            </v-flex>
                                                            <v-flex xs1>

                                                            </v-flex>
                                                        </v-layout>
                                                    </v-flex>
                                                </div>
                                                <div align="center" class="mt-4">A healthcare company that provides
                                                    affordable telemedicine services using USSD technology for
                                                    consultations, remote monitoring, and health management tools
                                                    without
                                                    internet access.</div>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                    <v-flex xs12 md2>
                                        <div class="mb-5">
                                            <div align="center" class="mb-15">
                                                <v-icon size="100px" class="primary--text" style="margin-top: -55px">
                                                    mdi-cellphone-basic
                                                </v-icon>
                                                <v-icon size="100px" class="secondary--text" style="margin-top: 115px;">
                                                    mdi-pill
                                                </v-icon>
                                            </div>
                                        </div>
                                    </v-flex>

                                </v-layout>
                                <div class="mx-5">
                                    <v-divider class="primary"></v-divider>
                                </div>
                            </v-card>
                        </v-flex>
                        <v-flex xs12 class="mt-5">
                            <v-card elevation="0" shaped outlined color="grey lighten-4"
                                style="border-radius: 20px 20px 20px 20px;">
                                <v-layout row wrap>
                                    <v-flex xs12 md2>
                                        <div class="mx-3">
                                            <v-img height="525" src="img/cohort6/saidiwa-rides.webp"
                                                style="border-radius: 20px 20px 20px 20px;" />
                                        </div>
                                    </v-flex>
                                    <v-flex xs12 md8 class="pa-5" style="border-radius: 20px 20px 20px 20px;">
                                        <v-layout column>
                                            <v-flex xs12 class="my-2">
                                                <div align="center" class="mx-3">
                                                    <v-layout row wrap>
                                                        <v-flex xs1>
                                                            <div align="right" class="mr-1 mt-16">
                                                                <v-img width="20px" src="img/a-1.png">
                                                                </v-img>
                                                            </div>
                                                        </v-flex>
                                                        <v-flex xs10>
                                                            <div align="center"
                                                                class="black--text font-weight-black mt-12 display-1">
                                                                Saidiwa Rides
                                                            </div>
                                                        </v-flex>
                                                        <v-flex xs1>
                                                            <div align="left" class="ml-1 mt-16">
                                                                <v-img width="20px" src="img/a-2.png">
                                                                </v-img>
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>

                                                </div>
                                                <div class="mt-14 mb-12">
                                                    <v-flex xs12>
                                                        <v-layout row wrap>
                                                            <v-flex xs1>

                                                            </v-flex>
                                                            <v-flex xs10>
                                                                <v-divider class="primary mx-10"></v-divider>
                                                                <v-divider class="secondary"></v-divider>
                                                                <v-divider class="primary mx-10"></v-divider>
                                                            </v-flex>
                                                            <v-flex xs1>

                                                            </v-flex>
                                                        </v-layout>
                                                    </v-flex>
                                                </div>
                                                <div align="center" class="mt-4">Aims to bridge the gap between
                                                    unreliable
                                                    public transportation and relatively expensive digital taxis
                                                    with a novel driver-passenger pairing model that permits daily
                                                    commuters
                                                    to share taxi seats and
                                                    share costs.</div>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                    <v-flex xs12 md2>
                                        <div class="mb-5">
                                            <div align="center" class="mb-15">
                                                <v-icon size="100px" class="primary--text" style="margin-top: -55px">
                                                    mdi-taxi
                                                </v-icon>
                                                <v-icon size="100px" class="secondary--text" style="margin-top: 90px;">
                                                    mdi-hail
                                                </v-icon>
                                            </div>
                                        </div>

                                    </v-flex>
                                </v-layout>
                                <div class="mx-5">
                                    <v-divider class="primary"></v-divider>
                                </div>
                            </v-card>
                        </v-flex>
                        <v-flex xs12 class="mt-5">
                            <v-card elevation="0" shaped outlined color="grey lighten-4"
                                style="border-radius: 20px 20px 20px 20px;">
                                <v-layout row wrap>
                                    <v-flex xs12 md2>
                                        <div class="mx-3">
                                            <v-img height="525" src="img/cohort6/sign-with-us.webp"
                                                style="border-radius: 20px 20px 20px 20px;" />
                                        </div>
                                    </v-flex>
                                    <v-flex xs12 md8 class="pa-5" style="border-radius: 20px 20px 20px 20px;">
                                        <v-layout column>
                                            <v-flex xs12 class="my-2">
                                                <div align="center" class="mx-3">
                                                    <v-layout row wrap>
                                                        <v-flex xs1>
                                                            <div align="right" class="mr-1 mt-16">
                                                                <v-img width="20px" src="img/a-1.png">
                                                                </v-img>
                                                            </div>
                                                        </v-flex>
                                                        <v-flex xs10>
                                                            <div align="center"
                                                                class="black--text font-weight-black mt-12 display-1">
                                                                Sign With Us
                                                            </div>
                                                        </v-flex>
                                                        <v-flex xs1>
                                                            <div align="left" class="ml-1 mt-16">
                                                                <v-img width="20px" src="img/a-2.png">
                                                                </v-img>
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>

                                                </div>
                                                <div class="mt-14 mb-12">
                                                    <v-flex xs12>
                                                        <v-layout row wrap>
                                                            <v-flex xs1>

                                                            </v-flex>
                                                            <v-flex xs10>
                                                                <v-divider class="primary mx-10"></v-divider>
                                                                <v-divider class="secondary"></v-divider>
                                                                <v-divider class="primary mx-10"></v-divider>
                                                            </v-flex>
                                                            <v-flex xs1>

                                                            </v-flex>
                                                        </v-layout>
                                                    </v-flex>
                                                </div>
                                                <div align="center" class="mt-4">Sign with Us is a Sign language
                                                    training
                                                    institution that teaches sign language to anyone around the
                                                    world who has a passion for sign language, or may interact with a
                                                    deaf
                                                    person in their line of work.</div>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                    <v-flex xs12 md2>
                                        <div class="mb-5">
                                            <div align="center" class="mb-15">
                                                <v-icon size="100px" class="primary--text" style="margin-top: -55px">
                                                    mdi-sign-language
                                                </v-icon>
                                                <v-icon size="100px" class="secondary--text" style="margin-top: 115px;">
                                                    mdi-human-male-board
                                                </v-icon>
                                            </div>
                                        </div>
                                    </v-flex>

                                </v-layout>
                                <div class="mx-5">
                                    <v-divider class="primary"></v-divider>
                                </div>
                            </v-card>
                        </v-flex>
                        <v-flex xs12 class="mt-5">
                            <v-card elevation="0" shaped outlined color="grey lighten-4"
                                style="border-radius: 20px 20px 20px 20px;">
                                <v-layout row wrap>
                                    <v-flex xs12 md2>
                                        <div class="mx-3">
                                            <v-img height="525" src="img/cohort6/tech-tenum.webp"
                                                style="border-radius: 20px 20px 20px 20px;" />
                                        </div>
                                    </v-flex>
                                    <v-flex xs12 md8 class="pa-5" style="border-radius: 20px 20px 20px 20px;">
                                        <v-layout column>
                                            <v-flex xs12 class="my-2">
                                                <div align="center" class="mx-3">
                                                    <v-layout row wrap>
                                                        <v-flex xs1>
                                                            <div align="right" class="mr-1 mt-16">
                                                                <v-img width="20px" src="img/a-1.png">
                                                                </v-img>
                                                            </div>
                                                        </v-flex>
                                                        <v-flex xs10>
                                                            <div align="center"
                                                                class="black--text font-weight-black mt-12 display-1">
                                                                Techtenum Minds Ltd
                                                            </div>
                                                        </v-flex>
                                                        <v-flex xs1>
                                                            <div align="left" class="ml-1 mt-16">
                                                                <v-img width="20px" src="img/a-2.png">
                                                                </v-img>
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>

                                                </div>
                                                <div class="mt-14 mb-12">
                                                    <v-flex xs12>
                                                        <v-layout row wrap>
                                                            <v-flex xs1>

                                                            </v-flex>
                                                            <v-flex xs10>
                                                                <v-divider class="primary mx-10"></v-divider>
                                                                <v-divider class="secondary"></v-divider>
                                                                <v-divider class="primary mx-10"></v-divider>
                                                            </v-flex>
                                                            <v-flex xs1>

                                                            </v-flex>
                                                        </v-layout>
                                                    </v-flex>
                                                </div>
                                                <div align="center" class="mt-4">Techtenum seeks to enhance Kenyan
                                                    hands-on
                                                    skill delivery through an e-learning platform,
                                                    complementing traditional school knowledge.</div>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                    <v-flex xs12 md2>
                                        <div class="mb-5">
                                            <div align="center" class="mb-15">
                                                <v-icon size="100px" class="primary--text" style="margin-top: -55px">
                                                    mdi-chair-school
                                                </v-icon>
                                                <v-icon size="100px" class="secondary--text" style="margin-top: 90px;">
                                                    mdi-tools
                                                </v-icon>
                                            </div>
                                        </div>

                                    </v-flex>
                                </v-layout>
                                <div class="mx-5">
                                    <v-divider class="primary"></v-divider>
                                </div>
                            </v-card>
                        </v-flex>
                        <v-flex xs12 class="mt-5">
                            <v-card elevation="0" shaped outlined color="grey lighten-4"
                                style="border-radius: 20px 20px 20px 20px;">
                                <v-layout row wrap>
                                    <v-flex xs12 md2>
                                        <div class="mx-3">
                                            <v-img height="525" src="img/cohort6/tynka-global.webp"
                                                style="border-radius: 20px 20px 20px 20px;" />
                                        </div>
                                    </v-flex>
                                    <v-flex xs12 md8 class="pa-5" style="border-radius: 20px 20px 20px 20px;">
                                        <v-layout column>
                                            <v-flex xs12 class="my-2">
                                                <div align="center" class="mx-3">
                                                    <v-layout row wrap>
                                                        <v-flex xs1>
                                                            <div align="right" class="mr-1 mt-16">
                                                                <v-img width="20px" src="img/a-1.png">
                                                                </v-img>
                                                            </div>
                                                        </v-flex>
                                                        <v-flex xs10>
                                                            <div align="center"
                                                                class="black--text font-weight-black mt-12 display-1">
                                                                Tynka Global
                                                            </div>
                                                        </v-flex>
                                                        <v-flex xs1>
                                                            <div align="left" class="ml-1 mt-16">
                                                                <v-img width="20px" src="img/a-2.png">
                                                                </v-img>
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>

                                                </div>
                                                <div class="mt-14 mb-12">
                                                    <v-flex xs12>
                                                        <v-layout row wrap>
                                                            <v-flex xs1>

                                                            </v-flex>
                                                            <v-flex xs10>
                                                                <v-divider class="primary mx-10"></v-divider>
                                                                <v-divider class="secondary"></v-divider>
                                                                <v-divider class="primary mx-10"></v-divider>
                                                            </v-flex>
                                                            <v-flex xs1>

                                                            </v-flex>
                                                        </v-layout>
                                                    </v-flex>
                                                </div>
                                                <div align="center" class="mt-4">Tynka connects entrepreneurs, experts,
                                                    and
                                                    investors, simplifying the process of forming
                                                    partnerships. The platform&#39;s open database of investors and
                                                    businesses allows entrepreneurs to
                                                    showcase their projects and attract potential investors.</div>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                    <v-flex xs12 md2>
                                        <div class="mb-5">
                                            <div align="center" class="mb-15">
                                                <v-icon size="100px" class="primary--text" style="margin-top: -55px">
                                                    mdi-handshake
                                                </v-icon>
                                                <v-icon size="100px" class="secondary--text" style="margin-top: 115px;">
                                                    mdi-account-tie-woman
                                                </v-icon>
                                            </div>
                                        </div>
                                    </v-flex>

                                </v-layout>
                                <div class="mx-5">
                                    <v-divider class="primary"></v-divider>
                                </div>
                            </v-card>
                        </v-flex>
                        <v-flex xs12 class="mt-5">
                            <v-card elevation="0" shaped outlined color="grey lighten-4"
                                style="border-radius: 20px 20px 20px 20px;">
                                <v-layout row wrap>
                                    <v-flex xs12 md2>
                                        <div class="mx-3">
                                            <v-img height="525" src="img/cohort6/sol-active.webp"
                                                style="border-radius: 20px 20px 20px 20px;" />
                                        </div>
                                    </v-flex>
                                    <v-flex xs12 md8 class="pa-5" style="border-radius: 20px 20px 20px 20px;">
                                        <v-layout column>
                                            <v-flex xs12 class="my-2">
                                                <div align="center" class="mx-3">
                                                    <v-layout row wrap>
                                                        <v-flex xs1>
                                                            <div align="right" class="mr-1 mt-16">
                                                                <v-img width="20px" src="img/a-1.png">
                                                                </v-img>
                                                            </div>
                                                        </v-flex>
                                                        <v-flex xs10>
                                                            <div align="center"
                                                                class="black--text font-weight-black mt-12 display-1">
                                                                Sol Active
                                                            </div>
                                                        </v-flex>
                                                        <v-flex xs1>
                                                            <div align="left" class="ml-1 mt-16">
                                                                <v-img width="20px" src="img/a-2.png">
                                                                </v-img>
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>

                                                </div>
                                                <div class="mt-14 mb-12">
                                                    <v-flex xs12>
                                                        <v-layout row wrap>
                                                            <v-flex xs1>

                                                            </v-flex>
                                                            <v-flex xs10>
                                                                <v-divider class="primary mx-10"></v-divider>
                                                                <v-divider class="secondary"></v-divider>
                                                                <v-divider class="primary mx-10"></v-divider>
                                                            </v-flex>
                                                            <v-flex xs1>

                                                            </v-flex>
                                                        </v-layout>
                                                    </v-flex>
                                                </div>
                                                <div align="center" class="mt-4">A sustainable activewear brand made
                                                    from
                                                    eco-friendly materials, naturally dyed fabrics sourced
                                                    from Africa that are ethically made.</div>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                    <v-flex xs12 md2>
                                        <div class="mb-5">
                                            <div align="center" class="mb-15">
                                                <v-icon size="100px" class="primary--text" style="margin-top: -55px">
                                                    mdi-run
                                                </v-icon>
                                                <v-icon size="100px" class="secondary--text" style="margin-top: 90px;">
                                                    mdi-leaf-circle
                                                </v-icon>
                                            </div>
                                        </div>

                                    </v-flex>
                                </v-layout>
                                <div class="mx-5">
                                    <v-divider class="primary"></v-divider>
                                </div>
                            </v-card>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-flex>

            <v-flex xs12>
                <v-img dark height="650" src="img/wit-cohort5-intro.jpg">
                    <v-container class="my-10">
                        <div class="pa-3 mt-16">
                            <p class="white--text headline font-weight-black">
                                Cohort 7 Timeline
                            </p>
                            <p class="white--text text--darken-2">
                            <ul>
                                <li>
                                    <b>Call for applications:</b>
                                </li>
                                <li>
                                    <b>Application Shortlisting:</b>
                                </li>
                                <li>
                                    <b>Pitch Preparation Bootcamp:</b>
                                </li>
                                <li>
                                    <b>Pitches:</b>
                                </li>
                                <li>
                                    <b>Top 15 Notification:</b>
                                </li>
                                <li>
                                    <b>Top 15 Incubation:</b>
                                </li>
                                <li>
                                    <b>Cohort 7 & Alumni Townhalls:</b>
                                </li>
                                <li>
                                    <b>Demo Day and Top 7 Award Ceremony:</b>
                                </li>
                                <li>
                                    <b>Acceleration Phase:</b>
                                </li>
                            </ul>
                            </p>
                            <!-- <p class="white--text text--darken-2 mt-10">
                                <b>Date:</b> Applications Ongoing
                            </p>
                            <p class="white--text text--darken-2">
                                <b>Time:</b> Deadline for application 28th May 2023
                            </p> -->
                        </div>
                    </v-container>

                </v-img>
            </v-flex>
        </v-layout>
    </div>
</template>
<style>
/* <-- remove scoped here if you have it*/
.v-carousel__controls {
    background: #fff !important;
    border-radius: 25px;
}

.v-carousel__controls__item {
    color: #007932 !important;
}
</style>
<script>
import apiCall from "@/utils/api";
import { mapState, mapGetters, mapActions } from "vuex";
import Vue from "vue";

export default {
    components: {

    },
    data() {
        return {
            path: process.env.VUE_APP_API_URL,
            color: "",
            message: "",
            snackbar: false,
            drawer: false,

            absolute: true,
            overlay: true,
            opacity: 0.3,

            echorich: 'image',
            ento: 'image',
            instruct: 'image',
            recess: 'image',
            rhea: 'image',

            echorichMobile: 'image',
            entoMobile: 'image',
            instructMobile: 'image',
            recessMobile: 'image',
            rheaMobile: 'image'
        };
    },
    mounted() {
        window.onscroll = () => {
            this.changeColor();
        };
    },
    created() {
        window.scrollTo(0, 0);
        this.changeHeaderColor("white");
        this.changeTitleColor("red--text");
        this.changeButtonColor("red--text hidden-sm-and-down text-none caption");
    },
    methods: {
        ...mapActions([
            "changeHeaderColor",
            "changeTitleColor",
            "changeButtonColor"
        ]),
        changeColor() {
            if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
                this.changeHeaderColor("white");
                this.changeTitleColor("white--text");
                this.changeButtonColor("white--text hidden-sm-and-down text-none caption");
            } else {
                this.changeHeaderColor("white");
                this.changeTitleColor("white--text");
                this.changeButtonColor("white--text hidden-sm-and-down text-none caption");
            }
        },
    },
    computed: {
        ...mapGetters(["darkState"]),
    },
};
</script>
