<template>
    <div class="welcome">
        <v-dialog
            transition="dialog-bottom-transition"
            max-width="1500"
            v-model="dialog"
        >
            <v-card v-if="assignedTeam!=null">
                <v-toolbar
                    color="white"
                    elevation="0"
                    >
                    <v-btn icon class="background ml-1" @click="previousProfile()" v-if="profileIndex!=0">
                        <v-icon class="secondary--text">mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn icon class="background ml-1" disabled v-if="profileIndex==0">
                        <v-icon class="secondary--text">mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn icon class="background ml-1" @click="nextProfile()" v-if="(profileIndex + 1) != publicTeams.length">
                        <v-icon class="primary--text">mdi-chevron-right</v-icon>
                    </v-btn>
                    <v-btn icon class="background ml-1" disabled v-if="(profileIndex + 1) == publicTeams.length">
                        <v-icon class="primary--text">mdi-chevron-right</v-icon>
                    </v-btn>
                    <div class="ml-2">{{ assignedTeam.name }}</div>
                    <v-spacer></v-spacer>
                    <v-btn
                        icon
                        class="background mr-1"
                        @click="dialog = false"
                    >
                    <v-icon class="primary--text">mdi-close</v-icon>
                </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-layout row wrap class="my-5">
                        <v-flex xs12 md3>
                            <div>
                                <v-img max-height="280" contain :src="path + '/storage/team_pics/' + assignedTeam.image" class="mt-5">
                                
                                </v-img>
                            </div>
                            <div align="center" class="secondary--text my-5">
                                <template v-for="role in assignedTeam.team_member_roles">
                                | {{ role.team_role.name }} |
                                </template>
                            </div>
                            <div align="center">
                                <a v-if="assignedTeam.facebook_url != null" :href="assignedTeam.facebook_url"
                                    target="_blank" style="text-decoration: none">
                                    <v-btn class="mx-1" icon>
                                        <v-icon size="20px" color="#3b5998"> mdi-facebook
                                        </v-icon>
                                    </v-btn>
                                </a>
                                <a v-if="assignedTeam.x_url != null" :href="assignedTeam.x_url"
                                    target="_blank" style="text-decoration: none">
                                    <v-btn class="mx-1" icon>
                                        <v-icon size="20px" color="#1DA1F2"> mdi-twitter
                                        </v-icon>
                                    </v-btn>
                                </a>
                                <a v-if="assignedTeam.instagram_url != null" :href="assignedTeam.instagram_url"
                                    target="_blank" style="text-decoration: none">
                                    <v-btn class="mx-1" icon>
                                        <v-icon size="20px" color="#8a3ab9"> mdi-instagram
                                        </v-icon>
                                    </v-btn>
                                </a> 
                                <a v-if="assignedTeam.phone_number != null" :href="'tel:'+assignedTeam.phone_number"
                                    target="_blank" style="text-decoration: none">
                                    <v-btn class="mx-1" icon>
                                        <v-icon size="20px" color="primary"> mdi-phone
                                        </v-icon>
                                    </v-btn>
                                </a> 
                                <a v-if="assignedTeam.email_address != null" :href="'mailto:'+assignedTeam.email_address"
                                    target="_blank" style="text-decoration: none">
                                    <v-btn class="mx-1" icon>
                                        <v-icon size="20px" color="#3e65cf"> mdi-email
                                        </v-icon>
                                    </v-btn>
                                </a>
                                <a v-if="assignedTeam.instagram_url != null" :href="assignedTeam.instagram_url"
                                    target="_blank" style="text-decoration: none">
                                    <v-btn class="mx-1" icon>
                                        <v-icon size="20px" color="#0e76a8"> mdi-linkedin
                                        </v-icon>
                                    </v-btn>
                                </a>
                                <a v-if="assignedTeam.website_url != null" :href="assignedTeam.website_url"
                                    target="_blank" style="text-decoration: none">
                                    <v-btn class="mx-1" icon>
                                        <v-icon size="20px" color="secondary"> mdi-web
                                        </v-icon>
                                    </v-btn>
                                </a>
                                <a v-if="assignedTeam.youtube_url != null" :href="assignedTeam.youtube_url"
                                    target="_blank" style="text-decoration: none">
                                    <v-btn class="mx-1" icon>
                                        <v-icon size="20px" color="#cc181e"> mdi-youtube
                                        </v-icon>
                                    </v-btn>
                                </a>
                            </div>

                        </v-flex>
                        <v-flex xs12 md9>
                            <div class="mx-5 mt-5">
                                <v-layout column>
                                    <v-flex xs12>
                                        <p v-html="assignedTeam.bio"></p>
                                    </v-flex>
                                </v-layout>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-layout column>
            <v-flex xs12>
                <v-img dark height="250" src="img/page-title-cohorts-5-scaled.jpg">
                    <div align="center" class="font-weight-black display-1 mt-16">
                        Team
                    </div>

                </v-img>
            </v-flex>
            <v-progress-linear v-if="teamLoader" height="2" indeterminate color="primary"></v-progress-linear>
            <v-flex xs12 class="white">
                <v-container class="my-10">
                    <div v-if="publicTeams.length!=0">
                        <v-layout column class="my-16">
                            <v-flex xs12>
                                <v-layout row wrap>
                                    <template v-for="(team, index) in publicTeams">
                                        <v-flex xs12 md3>
                                            <v-hover v-slot="{ hover }">
                                                <v-card class="d-flex flex-column mx-1 mt-2" color="grey lighten-4"
                                                    max-width="600" elevation="0" height="690">
                                                    <v-img max-height="280" contain :src="path + '/storage/team_pics/' + team.image" class="blue lighten-5">
                                                        <v-expand-transition>
                                                            <div v-if="hover"
                                                                class="d-flex transition-fast-in-fast-out secondary darken-2 v-card--reveal white--text"
                                                                style="height: 100%;">
                                                                <a style="text-decoration: none" class="white--text" @click="expandProfile(team, index)">{{ team.name }}</a>
                                                            </div>
                                                        </v-expand-transition>
                                                    </v-img>
                                                    <v-card-text class="pt-6" style="position: relative;">
                                                        <div class="font-weight-light mb-2" align="center">
                                                            <a style="text-decoration: none" class="primary--text" @click="expandProfile(team, index)">{{ team.name }}</a>
                                                        </div>
                                                        <v-divider class="my-5 secondary"></v-divider>
                                                        <div class="font-weight-light mb-2" align="center">
                                                            <!-- <div v-html="team.bio"></div> -->
                                                            <v-clamp autoresize :max-lines="4"
                                                                    >{{ removeTags(team.bio) }}
                                                                </v-clamp>
                                                        </div>
                                                        <div align="center" class="mt-10">
                                                                <a v-if="team.facebook_url != null" :href="team.facebook_url"
                                                                    target="_blank" style="text-decoration: none">
                                                                    <v-btn class="mx-1" icon>
                                                                        <v-icon size="20px" color="#3b5998"> mdi-facebook
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </a>
                                                                <a v-if="team.x_url != null" :href="team.x_url"
                                                                    target="_blank" style="text-decoration: none">
                                                                    <v-btn class="mx-1" icon>
                                                                        <v-icon size="20px" color="#1DA1F2"> mdi-twitter
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </a>
                                                                <a v-if="team.instagram_url != null" :href="team.instagram_url"
                                                                    target="_blank" style="text-decoration: none">
                                                                    <v-btn class="mx-1" icon>
                                                                        <v-icon size="20px" color="#8a3ab9"> mdi-instagram
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </a> 
                                                                <a v-if="team.phone_number != null" :href="'tel:'+team.phone_number"
                                                                    target="_blank" style="text-decoration: none">
                                                                    <v-btn class="mx-1" icon>
                                                                        <v-icon size="20px" color="primary"> mdi-phone
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </a> 
                                                                <a v-if="team.email_address != null" :href="'mailto:'+team.email_address"
                                                                    target="_blank" style="text-decoration: none">
                                                                    <v-btn class="mx-1" icon>
                                                                        <v-icon size="20px" color="#3e65cf"> mdi-email
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </a>
                                                                <a v-if="team.linkedin_url != null" :href="team.linkedin_url"
                                                                    target="_blank" style="text-decoration: none">
                                                                    <v-btn class="mx-1" icon>
                                                                        <v-icon size="20px" color="#0e76a8"> mdi-linkedin
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </a>
                                                                <a v-if="team.website_url != null" :href="team.website_url"
                                                                    target="_blank" style="text-decoration: none">
                                                                    <v-btn class="mx-1" icon>
                                                                        <v-icon size="20px" color="secondary"> mdi-web
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </a>
                                                                <a v-if="team.youtube_url != null" :href="team.youtube_url"
                                                                    target="_blank" style="text-decoration: none">
                                                                    <v-btn class="mx-1" icon>
                                                                        <v-icon size="20px" color="#cc181e"> mdi-youtube
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </a>
                                                        </div>
                                                        <div align="center">
                                                            <v-btn depressed class="primary white--text text-none my-5" @click="expandProfile(team, index)">
                                                                View More
                                                            </v-btn>
                                                        </div>

                                                    </v-card-text>
                                                    <v-spacer></v-spacer>
                                                    <div>
                                                        <v-flex xs12>
                                                            <v-layout row wrap>
                                                                <v-flex xs1>

                                                                </v-flex>
                                                                <v-flex xs10>
                                                                    <v-divider class="primary mx-10"></v-divider>
                                                                    <v-divider class="secondary"></v-divider>
                                                                    <v-divider class="primary mx-10"></v-divider>
                                                                </v-flex>
                                                                <v-flex xs1>

                                                                </v-flex>
                                                            </v-layout>
                                                        </v-flex>
                                                    </div>
                                                    <div class="my-7 mx-5">
                                                        <v-layout row wrap>
                                                            <v-flex xs1>
                                                                <div align="right" class="mr-1 mt-1">
                                                                    <v-img width="20px" src="img/a-1.png">
                                                                    </v-img>
                                                                </div>
                                                            </v-flex>
                                                            <v-flex xs10>
                                                                <div align="center">
                                                                    <template v-for="role in team.team_member_roles">
                                                                       | {{ role.team_role.name }} |
                                                                        </template>
                                                                </div>
                                                            </v-flex>
                                                            <v-flex xs1>
                                                                <div class="mt-1">
                                                                    <v-img width="20px" src="img/a-2.png">
                                                                    </v-img>
                                                                </div>
                                                            </v-flex>
                                                        </v-layout>
                                                    </div>

                                                </v-card>
                                            </v-hover>
                                        </v-flex>
                                    </template>
                                </v-layout>
                            </v-flex>
                            <v-flex xs12>
                                <div align="center" class="mt-15">
                                    <v-pagination v-if="length != 0" :length="length" total-visible="10"
                                        v-model="publicTeamPagination.current_page" @input="changePage()" circle>
                                    </v-pagination>
                                </div>
                            </v-flex>
                            <v-flex xs12>
                                <div v-if="length != 0" align="center" class="mt-5">
                                    <b class="primary--text">Total: </b>{{ publicTeamPagination.total | formatNumber }}
                                </div>
                            </v-flex>
                        </v-layout>
                    </div>
                    <div v-else>
                        <v-layout row wrap class="my-16">
                            <v-flex xs12 md3>
                                <div class="mx-1">
                                    <v-skeleton-loader
                                    v-bind="attrs"
                                    type="image, article"
                                    ></v-skeleton-loader>
                                </div>                                
                            </v-flex>
                            <v-flex xs12 md3>
                                <div class="mx-1">
                                    <v-skeleton-loader
                                        v-bind="attrs"
                                        type="image, article"
                                        ></v-skeleton-loader>
                                </div>                                    
                            </v-flex>
                            <v-flex xs12 md3>
                                <div class="mx-1">
                                    <v-skeleton-loader
                                    v-bind="attrs"
                                    type="image, article"
                                    ></v-skeleton-loader>
                                </div>                                
                            </v-flex>
                            <v-flex xs12 md3>
                                <div class="mx-1">
                                    <v-skeleton-loader
                                    v-bind="attrs"
                                    type="image, article"
                                    ></v-skeleton-loader>
                                </div>                                
                            </v-flex>
                            <v-flex xs12 md3>
                                <div class="mx-1">
                                    <v-skeleton-loader
                                    v-bind="attrs"
                                    type="image, article"
                                    ></v-skeleton-loader>
                                </div>                                
                            </v-flex>
                            <v-flex xs12 md3>
                                <div class="mx-1">
                                    <v-skeleton-loader
                                    v-bind="attrs"
                                    type="image, article"
                                    ></v-skeleton-loader>
                                </div>                                
                            </v-flex>
                            <v-flex xs12 md3>
                                <div class="mx-1">
                                    <v-skeleton-loader
                                    v-bind="attrs"
                                    type="image, article"
                                    ></v-skeleton-loader>
                                </div>                                
                            </v-flex>
                            <v-flex xs12 md3>
                                <div class="mx-1">
                                    <v-skeleton-loader
                                    v-bind="attrs"
                                    type="image, article"
                                    ></v-skeleton-loader>
                                </div>                                
                            </v-flex>
                        </v-layout>
                    </div>

                </v-container>
            </v-flex>
        </v-layout>
    </div>
</template>
<style>
.v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .5;
    position: absolute;
    width: 100%;
}
</style>
<script>
import apiCall from "@/utils/api";
import { mapState, mapGetters, mapActions } from "vuex";
import Vue from "vue";
import VClamp from "vue-clamp";

export default {
    components: {
        VClamp
    },
    data() {
        return {
            path: process.env.VUE_APP_API_URL,
            color: "",
            message: "",
            snackbar: false,
            dialog: false,

            profileIndex: 0,
            assignedTeam: null
        };
    },
    mounted() {
        window.onscroll = () => {

        };
    },
    created() {
        window.scrollTo(0, 0);
        this.startTeamLoader();
        this.fetchPublicTeams(this.publicTeamPagination.current_page);
    },
    methods: {
        ...mapActions([
            'fetchPublicTeams',
            'startTeamLoader',
            'stopTeamLoader'
        ]),
        previousProfile(){
            this.profileIndex -= 1
            this.assignedTeam = this.publicTeams[this.profileIndex]
        },
        nextProfile(){
            this.profileIndex += 1
            this.assignedTeam = this.publicTeams[this.profileIndex]
        },
        expandProfile(team, index){
            this.dialog = true
            this.assignedTeam = team
            this.profileIndex = index
        },
        removeTags(str) {
            if ((str===null) || (str===''))
                return false;
            else
                str = str.toString();
            return str.replace( /(<([^>]+)>)/ig, '');
        },
        changePage() {
            window.scrollTo(0, 0);
			this.startTeamLoader();
			this.fetchPublicTeams(this.publicTeamPagination.current_page);
		},
    },
    computed: {
        ...mapGetters(["darkState", "publicTeams", "publicTeamPagination", "teamLoader"]),
        length: function () {
			return Math.ceil(
				this.publicTeamPagination.total / this.publicTeamPagination.per_page
			);
		},
    },
};
</script>
