<template>
    <div class="welcome">
        <v-dialog transition="dialog-bottom-transition" max-width="1500" v-model="dialog">
            <v-card v-if="assignedVideo!=null">
                <v-toolbar color="white" elevation="0">
                    <v-btn icon class="background ml-1" @click="previousVideo()" v-if="vidIndex!=0">
                        <v-icon class="secondary--text">mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn icon class="background ml-1" disabled v-if="vidIndex==0">
                        <v-icon class="secondary--text">mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn icon class="background ml-1" @click="nextVideo()" v-if="(vidIndex + 1) != videos.length">
                        <v-icon class="primary--text">mdi-chevron-right</v-icon>
                    </v-btn>
                    <v-btn icon class="background ml-1" disabled v-if="(vidIndex + 1) == videos.length">
                        <v-icon class="primary--text">mdi-chevron-right</v-icon>
                    </v-btn>

                    <div class="ml-2"> {{ assignedVideo.name }} </div>

                    <v-spacer></v-spacer>
                    <v-btn icon class="background mr-1" @click="closePlayer()">
                        <v-icon class="primary--text">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                
                <video
                        id="videoPlayer"
                        :poster="path + '/storage/video_cover/' + assignedVideo.cover_url"
                        :src="path + '/storage/videos/' + assignedVideo.video_url"
                        width="100%"
                        controls
                        autoplay
                    >
                </video>
            </v-card>
        </v-dialog>
        <v-layout column>
            <v-flex xs12>
                <v-img dark height="250" src="img/page-title-gallery-scaled.jpg">
                    <div align="center" class="font-weight-black display-1 mt-16">
                        <div class="mt-16">Stories</div>
                    </div>
                </v-img>
            </v-flex>
            <v-progress-linear v-if="videoLoader" height="2" indeterminate color="primary"></v-progress-linear>
            <v-flex xs12 class="white">
                <v-container class="mt-12">
                    <v-layout row wrap>
                        <v-flex xs12 md3>
                            <div v-if="allStories.length == 0" class="mx-5 mt-12">
                                <v-skeleton-loader v-bind="attrs"
									type="table-heading, table-heading, table-heading, table-heading"></v-skeleton-loader>
                            </div>
                            <div v-else>
                                <div class="my-16">
                                    <div v-if="activeStory != null" class="my-15">
                                        <v-timeline align-top dense>
                                            <template v-for="(story, index) in allStories">
                                                <v-timeline-item color="primary" small v-if="activeStory.id == story.id">
                                                    <v-row>
                                                        <v-col>
                                                            <a style="text-decoration: none" class="primary--text"
                                                                @click="assignCohort(story)"><strong>{{ story.name }}</strong></a>
                                                            <div class="text-caption primary--text">
                                                                {{ story.year }}
                                                            </div>
                                                        </v-col>
                                                    </v-row>
                                                </v-timeline-item>
                                                <v-timeline-item color="secondary" small v-if="activeStory.id != story.id">
                                                    <v-row>
                                                        <v-col>
                                                            <a style="text-decoration: none" class="black--text"
                                                                @click="assignCohort(story)"><strong>{{ story.name }}</strong></a>
                                                            <div class="text-caption">
                                                                {{ story.year }}
                                                            </div>
                                                        </v-col>
                                                    </v-row>
                                                </v-timeline-item>
                                            </template>
                                        </v-timeline>
                                    </div>
									<div v-else class="mx-5">
										<v-skeleton-loader v-bind="attrs"
											type="table-heading, table-heading, table-heading, table-heading"></v-skeleton-loader>
									</div>
                                </div>
                            </div>
                            
                        </v-flex>
                        <v-flex xs12 md9>
                            <div v-if="videos.length != 0">
                                <v-layout row wrap class="mt-16">
                                    <template v-for="(video, index) in videos">
                                        <v-flex xs12 md3>
                                            <v-hover v-slot="{ hover }">
                                                <v-card class="mx-1 mt-5" color="grey lighten-4" max-width="600"
                                                    elevation="0">
                                                    <v-img contain
                                                        :src="path + '/storage/video_cover/' + video.cover_url"
                                                        lazy-src="img/cohort6/Gift-Chain-Africa-Ltd-700x500.jpg">
                                                        <v-expand-transition>
                                                            <div v-if="hover"
                                                                class="d-flex transition-fast-in-fast-out secondary darken-2 v-card--reveal "
                                                                style="height: 100%;">
                                                                <v-icon large class="white--text"
                                                                    @click="playVid(video, index)">mdi-play-circle-outline</v-icon>
                                                            </div>
                                                        </v-expand-transition>
                                                        <template v-slot:placeholder>
                                                            <v-row
                                                                class="fill-height ma-0"
                                                                align="center"
                                                                justify="center"
                                                            >
                                                                <v-progress-circular
                                                                indeterminate
                                                                color="grey lighten-5"
                                                                ></v-progress-circular>
                                                            </v-row>
                                                        </template>
                                                    </v-img>
                                                    <div class="my-7 mx-5">
                                                    <v-layout row wrap>
                                                        <v-flex xs1>
                                                            <div align="right" class="mr-1 mt-4">
                                                                <v-img width="20px" src="img/a-1.png">
                                                                </v-img>
                                                            </div>
                                                        </v-flex>
                                                        <v-flex xs10>
                                                            <div align="center" class="mb-5 mt-3">
                                                                {{ video.name }}
                                                            </div>
                                                        </v-flex>
                                                        <v-flex xs1>
                                                            <div class="mt-4">
                                                                <v-img width="20px" src="img/a-2.png">
                                                                </v-img>
                                                            </div>
                                                        </v-flex>
                                                    </v-layout>
                                                </div>
                                                <div class="mx-3">
                                                    <v-layout row wrap>
                                                        <v-flex xs6>
                                                            <v-divider class="primary"></v-divider>
                                                        </v-flex>
                                                        <v-flex xs6>
                                                            <v-divider class="secondary"></v-divider>
                                                        </v-flex>
                                                    </v-layout>
                                                </div>
                                                
                                                
                                                </v-card>
                                            </v-hover>
                                        </v-flex>
                                    </template>
                                </v-layout>
                            </div>
                            <div v-else>
                                <v-layout row wrap class="mt-16">
                                    <v-flex xs12 md3>
                                        <div class="mx-1 my-1">
                                            <v-skeleton-loader
                                                v-bind="attrs"
                                                type="card-avatar"
                                                ></v-skeleton-loader>
                                         </div>
                                    </v-flex>
                                    <v-flex xs12 md3>
                                        <div class="mx-1 my-1">
                                            <v-skeleton-loader
                                                v-bind="attrs"
                                                type="card-avatar"
                                                ></v-skeleton-loader>
                                         </div>
                                    </v-flex>
                                    <v-flex xs12 md3>
                                        <div class="mx-1 my-1">
                                            <v-skeleton-loader
                                                v-bind="attrs"
                                                type="card-avatar"
                                                ></v-skeleton-loader>
                                         </div>
                                    </v-flex>
                                    <v-flex xs12 md3>
                                        <div class="mx-1 my-1">
                                            <v-skeleton-loader
                                                v-bind="attrs"
                                                type="card-avatar"
                                                ></v-skeleton-loader>
                                         </div>
                                    </v-flex>
                                    <v-flex xs12 md3>
                                        <div class="mx-1 my-1">
                                            <v-skeleton-loader
                                                v-bind="attrs"
                                                type="card-avatar"
                                                ></v-skeleton-loader>
                                         </div>
                                    </v-flex>
                                    <v-flex xs12 md3>
                                        <div class="mx-1 my-1">
                                            <v-skeleton-loader
                                                v-bind="attrs"
                                                type="card-avatar"
                                                ></v-skeleton-loader>
                                         </div>
                                    </v-flex>
                                    <v-flex xs12 md3>
                                        <div class="mx-1 my-1">
                                            <v-skeleton-loader
                                                v-bind="attrs"
                                                type="card-avatar"
                                                ></v-skeleton-loader>
                                         </div>
                                    </v-flex>
                                    <v-flex xs12 md3>
                                        <div class="mx-1 my-1">
                                            <v-skeleton-loader
                                                v-bind="attrs"
                                                type="card-avatar"
                                                ></v-skeleton-loader>
                                         </div>
                                    </v-flex>
                                </v-layout>
                            </div>

                            <v-layout column class="mt-15">
										<v-flex xs12>
											<div align="left" v-if="videos.length != 0">
												<v-pagination v-if="length != 0" :length="length" total-visible="10" v-model="videoPagination.current_page
			                                        " @input="changeVideoPage()" circle>
												</v-pagination>
											</div>
										</v-flex>
									</v-layout>
                            
                            <div align="center" class="my-15">
                                <a target="_blank" href="https://www.youtube.com/@iLabAfricaSU/videos">
                                    <v-btn depressed class="text-none white--text" color="#c4302b">
                                        <v-icon left>mdi-youtube</v-icon>
                                        Check out our YouTube Channel
                                    </v-btn>
                                </a>
                            </div>
                        </v-flex>
                    </v-layout>



                </v-container>
            </v-flex>
        </v-layout>
    </div>
</template>
<style>
.v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .5;
    position: absolute;
    width: 100%;
}
</style>
<script>
import apiCall from "@/utils/api";
import { mapState, mapGetters, mapActions } from "vuex";
import Vue from "vue";

export default {
    components: {

    },
    data() {
        return {
            path: process.env.VUE_APP_API_URL,
            dialog: false,
            color: "",
            message: "",
            snackbar: false,
            drawer: false,

            vidIndex: 0,
            assignedVideo: null,
            activeStory: null,

            absolute: true,
            overlay: true,
            opacity: 0.3,


        };
    },
    watch: {
		allStories() {
			this.initWorkFlow();
		},
	},
    mounted() {

    },
    created() {
        window.scrollTo(0, 0);
        this.fetchAllStories()
        this.initWorkFlow();
    },
    methods: {
        ...mapActions([
            'fetchAllStories',
            'filterVideos',
            'startVideoLoader',
            'stopVideoLoader'
        ]),
        initWorkFlow() {
			this.activeStory = this.allStories[0]

			this.changeVideoPage()
		},
        changeVideoPage(){
            this.startVideoLoader();
			apiCall({
				url:
					"/api/video?type=public&story_id=" +
					this.activeStory.id +
					"&page=" +
					this.videoPagination.current_page,
				method: "GET",
			})
				.then((resp) => {
					this.stopVideoLoader();
                    this.filterVideos(resp);
				})
				.catch((error) => {
					this.stopVideoLoader();
					this.message = "An Error Occurred";
					this.color = "error";
					this.snackbar = true;

                    console.log(error);
				});
        },

        previousVideo(){
            this.vidIndex -= 1
            this.assignedTeam = this.publicTeams[this.vidIndex]
        },
        nextVideo(){
            this.vidIndex += 1
            this.assignedTeam = this.publicTeams[this.vidIndex]
        },

        assignCohort(cohort) {
            this.activeStory = cohort

            this.changeVideoPage()
        },
        playVid(video, index) {
            this.dialog = true
            this.assignedVideo = video
            this.vidIndex = index
        },
        closePlayer(){
            var video = document.getElementById("videoPlayer");
            video.pause();
            video.currentTime = 0;

            
            // this.vidNo = 0
            this.dialog = false
            this.assignedVideo = null
        }
    },
    computed: {
        ...mapGetters(["darkState", "allStories", "videos", "videoPagination", "videoLoader"]),
        length: function () {
			return Math.ceil(
				this.videoPagination.total /
				this.videoPagination.per_page
			);
		},
    },
};
</script>
