<template>
    <div class="welcome">
      <v-layout column>
            <v-flex xs12>
                <v-img
                    dark
                    height="250"
                    src="img/page-title-cohorts-5-scaled.jpg"
                    >
                    <div align="center" class="font-weight-black display-1 mt-16">
                        About
                    </div>
                    <!-- <div align="center" class="mt-2">
                        Embracing equity in women-led startups
                    </div> -->
                    
                </v-img>
            </v-flex>
            <v-flex xs12 class="white">
                <v-container>
                    <div align="center" class="mt-15">
                        <p class="grey--text text--darken-1">
                            The Standard Chartered Women in Technology Incubator Kenya is Africa’s leading incubator programme for female led startups, aligning with calls for more diversity in technology, entrepreneurship and for more opportunities for women to develop entrepreneurial and leadership excellence. The program is an initiative of Standard Chartered in partnership with @iBizAfrica Incubation Centre, Strathmore University.
                        </p>
                        <p class="grey--text text--darken-1">
                            The program combines world class startup support with local and international experience to provide a holistic startup incubation program focusing on immersive learning, mentorship & coaching, seed financing and business to business linkages for high potential startups tackling the continent’s most relevant challenges.
                        </p>
                    </div>
                </v-container>
            </v-flex>
            <v-flex xs12 class="white">
                <v-container>
                    <v-layout column>
                        <v-flex xs12 class="mt-7">
                            <v-card shaped class="primary" elevation="0">
                                <v-layout row wrap>
                                    <v-flex xs12 md8 class="pa-15">
                                        <div align="center">
                                            <v-icon 
                                            color="white"
                                            size="100px"
                                            >
                                                mdi-earth
                                            </v-icon>
                                        </div>
                                        <div align="center" class="font-weight-black white--text mt-3">
                                            Incubation Component
                                        </div>
                                        <div align="center" class="white--text mt-5">
                                            The methodology for the incubator program will address the following core components of business development.
                                        </div>
                                        <!-- <div align="center">
                                            <v-icon
                                            color="white"
                                            x-large
                                            >
                                                mdi-arrow-right-thick
                                            </v-icon>
                                        </div> -->
                                    </v-flex>
                                    <v-flex xs12 md4>
                                        <div class="mx-3">
                                            <v-img src="img/incubation.jpg" style="border-radius: 4px 4px 20px 4px;"> </v-img>
                                        </div>
                                    </v-flex>
                                </v-layout>
                            </v-card>
                        </v-flex>
                        <v-flex xs12 class="mt-8">
                            <v-card shaped class="secondary" elevation="0">
                                <v-layout row wrap>
                                    <v-flex xs12 md4>
                                        <v-img src="img/learning.jpg" style="border-radius: 20px 4px 4px 4px;" class="mx-3"> </v-img>
                                    </v-flex>
                                    <v-flex xs12 md8 class="pa-13">
                                        <div align="center">
                                            <v-icon 
                                            color="white"
                                            size="100px"
                                            >
                                                mdi-school
                                            </v-icon>
                                        </div>
                                        <div align="center" class="font-weight-black white--text mt-3">
                                            Immersive Learning
                                        </div>
                                        <div align="center" class="white--text mt-5">
                                            Entrepreneurs undergo an in depth evaluation of the current state of their company operation. This informs the methodology of curriculum delivery meeting unique needs of each venture.
                                        </div>
                                        <!-- <div align="center">
                                            <v-icon
                                            color="white"
                                            x-large
                                            >
                                                mdi-arrow-left-thick
                                            </v-icon>
                                        </div> -->
                                    </v-flex>
                                </v-layout>
                            </v-card>
                        </v-flex>
                        <v-flex xs12 class="mt-8">
                            <v-card shaped class="primary" elevation="0">
                                <v-layout row wrap>
                                    <v-flex xs12 md8 class="pa-14">
                                        <div align="center">
                                            <v-icon 
                                            color="white"
                                            size="100px"
                                            >
                                                mdi-account-child-circle
                                            </v-icon>
                                        </div>
                                        <div align="center" class="font-weight-black white--text mt-3">
                                            Mentorship & Coaching
                                        </div>
                                        <div align="center" class="white--text mt-5">
                                            Inhouse mentors coaches walk the incubation jpurney with entrepreneurs by facilitating customized mentorship and coaching sessions addressing each company's needs.
                                        </div>
                                        <!-- <div align="center">
                                            <v-icon
                                            color="white"
                                            x-large
                                            >
                                                mdi-arrow-right-thick
                                            </v-icon>
                                        </div> -->
                                    </v-flex>
                                    <v-flex xs12 md4>
                                        <div class="mx-3">
                                            <v-img src="img/mentor.jpg" style="border-radius: 4px 4px 20px 4px;"> </v-img>
                                        </div>
                                    </v-flex>
                                </v-layout>
                            </v-card>
                        </v-flex>
                        <v-flex xs12 class="mt-8">
                            <v-card shaped class="secondary" elevation="0">
                                <v-layout row wrap>
                                    <v-flex xs12 md4>
                                        <v-img src="img/finance.jpg" style="border-radius: 20px 4px 4px 4px;" class="mx-3"> </v-img>
                                    </v-flex>
                                    <v-flex xs12 md8 class="pa-11">
                                        <div align="center">
                                            <v-icon 
                                            color="white"
                                            size="100px"
                                            >
                                                mdi-cash-100
                                            </v-icon>
                                        </div>
                                        <div align="center" class="font-weight-black white--text mt-3">
                                            Access to Seed Financing 
                                        </div>
                                        <div align="center" class="white--text mt-5">
                                            The top 7 Startups will have the opportunity to secure $10,000 in seed funding to scale their ventures
                                        </div>
                                        <!-- <div align="center">
                                            <v-icon
                                            color="white"
                                            x-large
                                            >
                                                mdi-arrow-left-thick
                                            </v-icon>
                                        </div> -->
                                    </v-flex>
                                </v-layout>
                            </v-card>
                        </v-flex>
                        <v-flex xs12 class="mt-8 mb-16">
                            <v-card shaped class="primary" elevation="0">
                                <v-layout row wrap>
                                    <v-flex xs12 md8 class="pa-14">
                                        <div align="center">
                                            <v-icon 
                                            color="white"
                                            size="100px"
                                            >
                                                mdi-google-circles-communities
                                            </v-icon>
                                        </div>
                                        <div align="center" class="font-weight-black white--text mt-3">
                                            Networking
                                        </div>
                                        <div align="center" class="white--text mt-5">
                                            Companies are able to access a rich network of enablers and partners within and outside the ecosystem; enabling them to create sustainable partnerships for business growth
                                        </div>
                                        <!-- <div align="center">
                                            <v-icon
                                            color="white"
                                            x-large
                                            >
                                                mdi-arrow-right-thick
                                            </v-icon>
                                        </div> -->
                                    </v-flex>
                                    <v-flex xs12 md4>
                                        <div class="mx-3">
                                            <v-img src="img/networking.jpg" style="border-radius: 4px 4px 20px 4px;"> </v-img>
                                        </div>
                                    </v-flex>
                                </v-layout>
                            </v-card>
                        </v-flex>
                    </v-layout>
                </v-container>
                
            </v-flex>
            <v-flex xs12 class="background">
                <v-container class="my-16">
                    <v-layout row wrap class="mx-1 my-2">
                    <v-flex xs12 md6 color="#525355" class="accent pa-16">
                            <div class="title white--text mt-7 font-weight-black">
                                Eligibility Criteria
                            </div>
                            <p class="white--text mt-3">
                                We are seeking to recruit the best entrepreneurial minds, women-led tech startups as key drivers of innovation in business.
                            </p>
                            <p class="white--text mt-3">
                                The Eligibility criteria is as follows:
                            </p>
                            <p class="white--text mt-3 mb-7">
                                <ul>
                                    <li>
                                        The business must be based in Kenya i.e. be aligned with the needs of Kenyans and operating in Kenya
                                    </li>
                                    <li>
                                        The company must be a tech-enabled and/or leveraging on emerging technologies, including but not limited to; Internet of Things (IoT); Artificial Intelligence; Robotics; Augmented & Virtual Reality; 3D & 4D Printing; Cloud Computing; Big Data; Blockchain; Drone Technology and Biometrics.
                                    </li>
                                    <li>
                                        The company must be founded or cofounded by a woman with an equal or majority stake.
                                    </li>
                                    <li>
                                        The founder or co founder must be over 18 years old and Kenyan 
                                    </li>
                                    <li>
                                        Business models must have a clear focus on sustainability and alignment to the Sustainable Development Goals (SDGs).
                                    </li>
                                    <li>
                                        3 months to less than 2 years of operations.
                                    </li>
                                    <li>
                                        Seeking support for proof of concept, customer, product and business model development.
                                    </li>
                                    <li>
                                        Pre-revenue with early traction (users).
                                    </li>
                                    <li>
                                        Post revenue is an added advantage, with the creation of a new business module.
                                    </li>
                                </ul>
                            </p>
                        
                    </v-flex>
                    <v-flex xs12 md6 class="white pa-16">
                            <div class="title black--text mt-7 font-weight-black">
                                Program Benefits
                            </div>
                            <p class="black--text mt-3">
                                <ul>
                                    <li>
                                        12 weeks of an immersive learning experience
                                    </li>
                                    <li>
                                        Access to a leading network of coaching experts
                                    </li>
                                    <li>
                                        Access to networking opportunities
                                    </li>
                                    <li>
                                        Personalized mentorship sessions to address your unique business needs
                                    </li>
                                    <li>
                                        Opportunity to join a supportive Women in Tech Alumni Network
                                    </li>
                                    <li>
                                        $10,000 equity-free grant seed funding for the top 7 finalists
                                    </li>
                                    <li>
                                        9 months post-incubation support from Standard Chartered and @iBizAfrica to assist with the go-to market and scale for the top five finalists
                                    </li>
                                </ul>
                            </p>
                            <div class="title black--text mt-7 font-weight-black">
                                Terms & Conditions
                            </div>
                            <p>
                                
Successful completion of the Women in Tech Incubation Program is compulsory. If a physical meetup is necessary, government regulations on COVID-19 health measures MUST be observed.
                            </p>
                        
                    </v-flex>
                </v-layout>
                </v-container>

            </v-flex>
        </v-layout>
    </div>
</template>
<script>
import apiCall from "@/utils/api";
import { mapState, mapGetters, mapActions } from "vuex";
import Vue from "vue";

export default {
  components: {
    
  },
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      color: "",
      message: "",
      snackbar: false,
      drawer: false,

      absolute: true,
      overlay: true,
      opacity: 0.3,
    };
  },
  mounted() {
    window.onscroll = () => {
      this.changeColor();
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.changeHeaderColor("white");
    this.changeTitleColor("red--text");
    this.changeButtonColor("red--text hidden-sm-and-down text-none caption");
  },
  methods: {
    ...mapActions([
        "changeHeaderColor",
        "changeTitleColor",
        "changeButtonColor"
    ]),
      changeColor() {
        if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
          this.changeHeaderColor("white");
          this.changeTitleColor("black--text");
          this.changeButtonColor("black--text hidden-sm-and-down text-none caption");
        } else {
          this.changeHeaderColor("white");
          this.changeTitleColor("white--text");
          this.changeButtonColor("white--text hidden-sm-and-down text-none caption");
        }
      },
  },
  computed: {
    ...mapGetters(["darkState"]),
  },
};
</script>
