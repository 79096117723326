import apiCall from '@/utils/api'

const state = {
	newsFeeds: [],
	publicNewsFeeds: [],
	allNewsFeeds: [],
    newsFeedPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
	publicNewsFeedPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    newsFeedLoader: false
};

const getters = {
	newsFeeds: (state) => state.newsFeeds,
	publicNewsFeeds: (state) => state.publicNewsFeeds,
	allNewsFeeds: (state) => state.allNewsFeeds,
    newsFeedLoader: (state) => state.newsFeedLoader,
    newsFeedPagination: (state) => state.newsFeedPagination,
	publicNewsFeedPagination: (state) => state.publicNewsFeedPagination
}

const actions = {
	async fetchAllNewsFeeds({commit}, page) {
		const response = await apiCall({url: `/api/news-feed?type=all`, method: 'GET' });
		commit('setAllNewsFeeds', response)
        commit('stopLoader', response)
	},
    async fetchNewsFeeds({commit}, page) {
		const response = await apiCall({url: `/api/news-feed?page=${page}`, method: 'GET' });
		commit('setNewsFeeds', response)
        commit('stopLoader', response)
	},
	async fetchPublicNewsFeeds({commit}, page) {
		const response = await apiCall({url: `/api/news-feed?type=public&page=${page}`, method: 'GET' });
		commit('setPublicNewsFeeds', response)
        commit('stopLoader', response)
	},
    async filterNewsFeeds({commit, state},resp){
		commit('setNewsFeeds', resp)
	},
	async startNewsFeedLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopNewsFeedLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setNewsFeeds: (state, newsFeeds) => {
		state.newsFeeds = newsFeeds.data
		state.newsFeedPagination.current_page = newsFeeds.current_page
		state.newsFeedPagination.total = newsFeeds.total
		state.newsFeedPagination.per_page = newsFeeds.per_page
	},
	setPublicNewsFeeds: (state, newsFeeds) => {
		state.publicNewsFeeds = newsFeeds.data
		state.publicNewsFeedPagination.current_page = newsFeeds.current_page
		state.publicNewsFeedPagination.total = newsFeeds.total
		state.publicNewsFeedPagination.per_page = newsFeeds.per_page
	},
	setAllNewsFeeds: (state, newsFeeds) => {
		state.allNewsFeeds = newsFeeds
	},
    startLoader: (state) => state.newsFeedLoader = true,
	stopLoader: (state) => state.newsFeedLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
