<template>
	<div class="groups" v-if="$can('group_view')">
		<v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
			<span>{{ message }}</span>
		</v-snackbar>
		<v-dialog
            transition="dialog-bottom-transition"
            max-width="600"
            v-model="pdfViewDialog"
        >
            <v-card max-width="600" v-if="assignedApplication!=null">
				<v-toolbar flat>
					{{ docType }}
					<v-spacer></v-spacer>
					<v-btn icon @click="pdfViewDialog = false">
						<v-icon class="primary--text">mdi-close</v-icon>
					</v-btn>
				</v-toolbar>
                <v-card-text>                    
					<div align="right">
						<v-btn icon @click="$refs.myPdfComponent.print()"><v-icon class="primary--text">mdi-printer</v-icon></v-btn>
					</div>
					<pdf v-if="docType == 'CR12'"
						ref="myPdfComponent"
						:src="path+'/storage/docs/'+ assignedApplication.venture.cr12"
						@num-pages="pageCount = $event"
						@page-loaded="currentPage = $event"
					></pdf>
					<pdf v-else
						ref="myPdfComponent"
						:src="path+'/storage/docs/'+ assignedApplication.venture.registration_cetificate"
						@num-pages="pageCount = $event"
						@page-loaded="currentPage = $event"
					></pdf>
                </v-card-text>
            </v-card>
        </v-dialog>
		<v-container>
			<v-layout column>
				<div v-if="view == 'default'">
					<v-card outlined class="mt-5 pa-3" style="border-radius: 25px;">
						<v-flex xs12 class="mb-2">
							<v-tabs show-arrows v-model="activeTab">
									<v-tabs-slider color="primary"></v-tabs-slider>
									<v-tab @click="homeTab = 'home'">
										<div class="text-none primary--text">Home</div>
									</v-tab>
									<v-tab @click="homeTab = 'group'">
										<div class="text-none primary--text">Groups</div>
									</v-tab>
								</v-tabs>
						</v-flex>
						<div v-if="homeTab == 'home'">
							<v-layout column class="pa-15">
								<v-flex xs12>               
									<v-layout row wrap>
										<v-flex xs6>
										<div align="right" class="mt-2 mr-5">
											<v-img max-width="250" src="img/logo.png"> </v-img>
										</div>                      
										</v-flex>
										<v-flex xs6>
										<div align="left" class="ml-5">
											<v-img max-width="250" src="img/ibiz-black.png"> </v-img>
										</div>
										</v-flex>
									</v-layout>
								</v-flex>
								<v-flex xs12 class="my-5">
									Dear {{ getProfile.first_name }} {{ getProfile.last_name }},
								</v-flex>
								<v-flex xs12>
									<p>
										Welcome to the online adjudication for the Standard Chartered Women in Technology Cohort 7 Program. Please find below the adjudication criteria and scoring guidelines.
									</p>
									<p>
										This year, our main focus and emphasis is on <b>SUSTAINABILITY AND TECHNOLOGY.</b> We request you to proceed to the adjudication process only after you have reviewed and understood the guidelines and criteria.
									</p>
									<p>
										You will be part of a judging group of a minimum of two judges per group where you will evaluate the same batch of applications. Please adjudicate all the applications in your batch. When the process is complete <b>top 20 applicants</b> from <b>each of the 4 judging groups</b> will be selected to proceed to the next stage of the selection processes.
									</p>
									<p>										
										<p>
											<div class="title font-weight-black mt-5">
												Adjudication Criteria
											</div>
											<div class="font-weight-black">TOTAL POINTS - 100</div>
											<ol>
												<li><b>Eligibility</b> - Meets the minimum eligibility criteria: female founder with a majority stake in the company, above 18 years, Kenyan nationality - <b>5 points</b></li>
												<li><b>Use of Tech</b> – Is the business leveraging on technology? Is the solution a new technology or a different way of doing things in the market? - <b>10 points</b></li>
												<li><b>Team</b> - Has a team of at least 3 people with demonstrated capacity to execute: clearly defined roles, balanced skillset (team lead, business development, product development, and marketing) - <b>10 points</b></li>
												<li><b>Problem</b> – Is a real problem being addressed? Is there a clear description of this problem with evidence of real-life user pain points? – <b>20 points</b></li>
												<li><b>Solution</b> - Is there a problem/ solution fit? Is the solution innovative? - <b>15 points</b></li>
												<li><b>Customer/users</b> – Does the business have paying customers? Does the business have users of the product or the service? – <b>10 points</b></li>
												<li><b>Market</b> – Is the market opportunity well described: total addressable, target and current market share? Are there high barriers of entry? How big is the market opportunity? - <b>10 points</b></li>
												<li><b>Business model</b> - How does the business make money? Is it sustainable? Is the business scalable? - <b>15 points</b></li>
												<li><b>Social Impact</b> – Does the business address any of the SDG goals? Is it highly relevant for Kenya in terms of social impact? – <b>5 points</b></li>
											</ol>
										</p>
										<p>
											<b>The deadline for completing your adjudication is 12:00 midnight, Nairobi time, Tuesday 28th May 2024.</b>
										</p>
										<p>
											We look forward to receiving your scores before the deadline. On behalf of Standard Chartered Bank and @iBizAfrica, Strathmore University, thank you for your support in achieving this important milestone.
										</p>
										<p>
											<div>Best regards,</div>
											<div><i>Standard Chartered Women in Tech Incubator Team</i></div>
										</p>
									</p>
								</v-flex>
							</v-layout>
						</div>
						<div v-else>
							<v-flex xs12 class="mt-10 mb-2">
								<div class="mx-5">
									<v-layout row wrap>
										<v-flex xs12 md4>
											<h1>Groups</h1>
										</v-flex>
										<v-flex xs12 md4>
											<div class="pa-1">
												<v-text-field class="text_field header" outlined dense label="Search"
													append-icon="mdi-undo-variant" @click:append="resetSearch()" v-on:keyup.enter="search"
													v-model="searchTerm" @input="enableSearch()"></v-text-field>
											</div>

										</v-flex>
										<v-flex xs12 md2>
											<div class="pa-1">
												<v-btn class="secondary white--text text-none" depressed block @click="search"
													:loading="searchLoader" :disabled="searchButtonDisabled">
													Search
													<v-icon right>mdi-magnify</v-icon>
												</v-btn>
											</div>
										</v-flex>
										<v-flex xs12 md2>
											<div class="pa-1">
												<v-btn block depressed class="primary text-none" @click="changeView('create')"
													v-if="$can('group_create')">
													Add Group
													<v-icon right>mdi-plus-circle-outline</v-icon>
												</v-btn>
											</div>
										</v-flex>
									</v-layout>
								</div>
							</v-flex>
							<div class="mx-5 mt-9">
								<v-progress-linear v-if="groupLoader" height="1" indeterminate color="primary"></v-progress-linear>
							</div>
							<v-divider class="mx-5"></v-divider>
							<v-flex xs12 class="mt-5">
								<div v-if="groups.length == 0">
									<v-card elevation="0">
										<v-layout row wrap>
											<v-flex xs12 md1>
												<v-container fill-height fluid>
													<v-row align="center" justify="center">
														<v-col class="text-center">
															<v-icon large class="text--text">
																mdi-alert-circle-outline
															</v-icon>
														</v-col>
													</v-row>
												</v-container>
											</v-flex>
											<v-flex xs12 md11>
												<v-container fill-height fluid>
													<v-row align="center" justify="center">
														<v-col class="text-center"> No Group Found </v-col>
													</v-row>
												</v-container>
											</v-flex>
										</v-layout>
									</v-card>
								</div>
								<div v-else>
									<div class="hidden-sm-and-down">
										<v-card elevation="0" class="pa-2">
											<v-simple-table>
												<template v-slot:default>
													<thead class="header">
														<tr class="tableHeader">
															<th class="text-left text--text">Name</th>

															<th class="text-left text--text">Active</th>
															<th class="text-right text--text">Actions</th>
														</tr>
													</thead>
													<tbody>
														<tr v-for="item in groups" :key="item.id">
															<td>
																{{ item.name }}
															</td>

															<td>
																<div v-if="item.active == 1">Yes</div>
																<div v-else>No</div>
															</td>
															<td>
																<div align="right">
																	<v-tooltip top v-if="$can('group_view')">
																		<template v-slot:activator="{ on, attrs }">
																			<v-btn icon class="button mr-1 universal--text" @click="showGroup(item)">
																				<v-icon small> mdi-eye </v-icon>
																			</v-btn>
																		</template>
																		<span>View</span>
																	</v-tooltip>
																	<v-tooltip top v-if="$can('group_edit')">

																		<template v-slot:activator="{ on, attrs }">
																			<v-btn icon class="button mr-1 success--text" @click="editGroup(item)"
																				v-bind="attrs" v-on="on">
																				<v-icon small> mdi-pencil </v-icon>
																			</v-btn>
																		</template>
																		<span>Edit</span>
																	</v-tooltip>

																	<v-tooltip top v-if="$can('group_archive')">

																		<template v-slot:activator="{ on, attrs }">
																			<v-btn icon class="button mr-1 error--text" @click="deleteGroup(item)"
																				:loading="loading && groupIndex == item.id" v-bind="attrs" v-on="on">
																				<v-icon small> mdi-delete </v-icon>
																			</v-btn>
																		</template>
																		<span>delete</span>
																	</v-tooltip>

																	<!-- <v-tooltip top v-if="$can('group_activate') && item.active == 0">

																		<template v-slot:activator="{ on, attrs }">
																			<v-btn icon class="button mr-1 red--text" @click="activateGroup(item)"
																				:loading="activateLoading && groupIndex == item.id" v-bind="attrs" v-on="on">
																				<v-icon small> mdi-toggle-switch-off-outline
																				</v-icon>
																			</v-btn>
																		</template>
																		<span>Activate Group</span>
																	</v-tooltip> -->
																	<!-- <v-tooltip top v-if="$can('group_deactivate') && item.active == 1">

																		<template v-slot:activator="{ on, attrs }">
																			<v-btn icon class="button mr-1 green--text" @click="deactivateGroup(item)"
																				:loading="deactivateLoading && groupIndex == item.id" v-bind="attrs" v-on="on">
																				<v-icon small> mdi-toggle-switch </v-icon>
																			</v-btn>
																		</template>
																		<span>Deactivate Group</span>
																	</v-tooltip> -->
																	<v-tooltip top v-if="$can('log_view')">

																		<template v-slot:activator="{ on, attrs }">
																			<v-btn icon class="button mr-1 purple--text" @click="viewLog(item)" v-bind="attrs"
																				v-on="on">
																				<v-icon small> mdi-script-text </v-icon>
																			</v-btn>
																		</template>
																		<span>Log</span>
																	</v-tooltip>

																</div>
															</td>
														</tr>
													</tbody>
												</template>
											</v-simple-table>
										</v-card>

									</div>
									<div class="hidden-md-and-up">
										<v-layout column>

											<template v-for="(group, index) in groups">
												<div :key="index">
													<v-flex xs12 class="mb-2">
														<v-card elevation="0">
															<div class="pa-5">
																<v-layout column>
																	<v-flex xs12>
																		<v-layout row wrap>
																			<v-flex xs11>
																				<div class="title primary--text ml-3">
																					<b>{{ group.name }}</b>
																				</div>
																			</v-flex>
																			<v-flex xs1>
																				<v-btn depressed class="red--text" :loading="loading && groupIndex == group.id
			" icon @click="deleteGroup(group)" v-if="$can('group_archive')">
																					<v-icon> mdi-delete </v-icon>
																				</v-btn>
																			</v-flex>
																		</v-layout>
																	</v-flex>
																	<v-divider class="mt-2"></v-divider>
																	<v-flex xs12 class="mt-1">
																		<v-layout column>
																			<v-flex xs12 class="mt-1">
																				<div>
																					<b>Active</b>
																				</div>
																				<div>
																					<div v-if="group.active == 1">Yes</div>
																					<div v-else>No</div>
																				</div>
																			</v-flex>

																			<v-flex xs12 class="mt-5">
																				<v-layout row wrap>
																					<v-flex xs12>
																						<div class="pa-1">
																							<v-btn v-if="$can('group_view')" depressed class="
														primary
														text-none
														white--text
													" block @click="showGroup(group)">View
																								<v-icon right> mdi-eye </v-icon>
																							</v-btn>
																						</div>
																					</v-flex>
																					<v-flex xs12>
																						<div class="pa-1">
																							<v-btn depressed class="blue text-none white--text" block
																								@click="editGroup(group)" v-if="$can('group_edit')">Edit
																								<v-icon right>
																									mdi-border-color
																								</v-icon>
																							</v-btn>
																						</div>
																					</v-flex>
																					<!-- <v-flex xs12>
																						<div class="pa-1">
																							<v-btn depressed class="green text-none white--text" block
																								@click="activateGroup(group)"
																								:loading="activateLoading && groupIndex == group.id"
																								v-if="$can('group_activate') && group.active == 0">Activate
																								Group
																								<v-icon right>
																									mdi-toggle-switch-off-outline
																								</v-icon>
																							</v-btn>
																						</div>
																					</v-flex> -->
																					<!-- <v-flex xs12>
																						<div class="pa-1">
																							<v-btn depressed class="red text-none white--text" block
																								@click="deactivateGroup(group)"
																								:loading="deactivateLoading && groupIndex == group.id"
																								v-if="$can('group_deactivate') && group.active == 1">Deactivate
																								Group
																								<v-icon right>
																									mdi-toggle-switch
																								</v-icon>
																							</v-btn>
																						</div>
																					</v-flex> -->
																					<v-flex xs12>
																						<div class="pa-1">
																							<v-btn v-if="$can('log_view')" depressed class="
														purple
														text-none
														white--text
													" block @click="viewLog(group)">Log
																								<v-icon right> mdi-script-text
																								</v-icon>
																							</v-btn>
																						</div>
																					</v-flex>
																				</v-layout>
																			</v-flex>
																		</v-layout>
																	</v-flex>
																</v-layout>
															</div>
														</v-card>
													</v-flex>
												</div>
											</template>
										</v-layout>
									</div>
								</div>
							</v-flex>
						</div>
					</v-card>

					<v-layout column class="mt-5" v-if="homeTab != 'home'">
						<v-flex xs12>
							<div align="center">
								<v-pagination v-if="length != 0" :length="length" total-visible="10"
									v-model="groupPagination.current_page" @input="changePage()" circle>
								</v-pagination>
							</div>
						</v-flex>
						<v-flex xs12>
							<div v-if="length != 0" align="center" class="mt-5">
								<b class="primary--text">Total: </b>{{ groupPagination.total | formatNumber }}
							</div>
						</v-flex>
					</v-layout>
				</div>
				<div v-if="view == 'create'">
					<v-flex class="mt-5">
						<v-card outlined>
							<v-card-title class="tableHeader text--text">
								<v-spacer></v-spacer>
								<div v-if="newGroup.id == null">Add Group</div>
								<div v-else>Edit Group</div>
								<v-spacer></v-spacer>
								<v-btn icon @click="changeView('default')">
									<v-icon class="text--text"> mdi-close </v-icon>
								</v-btn>
							</v-card-title>
							<v-card-text>
								<v-form v-model="valid" ref="form" lazy-validation>
									<v-layout column>
										<v-flex xs12>
											<v-layout row wrap class="mt-5">
												<v-flex xs12 md8>
													<div class="mt-3 mx-1">
														<div>Group Name<span class="error--text">*</span></div>
														<v-text-field class="text_field background" outlined dense v-model="newGroup.name"
															:rules="inputRules"></v-text-field>
													</div>
												</v-flex>
											</v-layout>
										</v-flex>
									</v-layout>

								</v-form>
							</v-card-text>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn depressed class="primary text-none mt-3" @click="save" :disabled="!valid" :loading="loading">
									Save
									<v-icon right> mdi-content-save </v-icon>
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-flex>
				</div>
				<div v-if="view == 'show'">
					<v-card outlined class="mt-5">
						<v-card-title class="tableHeader text--text">
							View Group
							<v-spacer></v-spacer>
							<v-btn icon @click="changeView('default')">
								<v-icon class="text--text"> mdi-close </v-icon>
							</v-btn>
						</v-card-title>
						<v-card-text>
							<div class="mt-2">
								<v-tabs show-arrows v-model="activeGroupTab">
									<v-tabs-slider color="primary"></v-tabs-slider>
									<v-tab @click="subTab = 'ventures'" v-if="$can('group_venture_view')">
										<div class="text-none primary--text">Ventures</div>
									</v-tab>
									<v-tab @click="subTab = 'judges'" v-if="$can('user_group_view')">
										<div class="text-none primary--text">Judges</div>
									</v-tab>
								</v-tabs>

								<div v-if="subTab == 'ventures'">
									<div v-if="ventureView == 'default'">
										<v-card elevation="0" class="mt-5">
											<v-flex xs12 class="mt-10 mb-2">
												<div class="mx-5">
													<v-layout row wrap>
														<v-flex xs12 md4>
															<div v-if="ventureLength != 0" align="left" class="mt-1">
																<b class="display-1 primary--text">Total: </b>{{ groupVenturePagination.total |	formatNumber }}
															</div>
														</v-flex>
														<v-flex xs12 md4>
															<div class="pa-1">
																<v-text-field class="text_field header" outlined dense label="Search"
																	append-icon="mdi-undo-variant" @click:append="
																	resetVentureSearch()
																	" v-on:keyup.enter="ventureSearch
																	" v-model="groupVentureSearchTerm" @input="
																	enableVentureSearch()
																	"></v-text-field>
															</div>
														</v-flex>
														<v-flex xs12 md2>
															<div class="pa-1">
																<v-btn class="secondary  white--text text-none" depressed block @click="ventureSearch" :loading="ventureSearchLoader
																	" :disabled="ventureSearchButtonDisabled
																	">
																	Search
																	<v-icon class="white--text" small right>mdi-magnify</v-icon>
																</v-btn>
															</div>
														</v-flex>
														<v-flex xs12 md2>
															<div class="pa-1">
																<v-btn block depressed class="primary text-none" v-if="$can('group_assign_venture')" @click="
																	changeVentureView(
																		'create'
																	)
																	">
																	Add Venture
																	<v-icon class="white--text" small right>mdi-plus-circle-outline</v-icon>
																</v-btn>
															</div>
														</v-flex>
													</v-layout>
												</div>
											</v-flex>
											<div class="mx-5 mt-9">
												<v-progress-linear v-if="ventureLoader" height="1" indeterminate color="primary"></v-progress-linear>
											</div>
											<v-divider class="mx-5"></v-divider>
											<v-flex xs12 class="mt-5">
												<div v-if="ventures.length == 0">
													<v-card elevation="0">
														<v-layout row wrap>
															<v-flex xs12 md1>
																<v-container fill-height fluid>
																	<v-row align="center" justify="center">
																		<v-col class="text-center">
																			<v-icon large class="text--text">
																				mdi-alert-circle-outline
																			</v-icon>
																		</v-col>
																	</v-row>
																</v-container>
															</v-flex>
															<v-flex xs12 md11>
																<v-container fill-height fluid>
																	<v-row align="center" justify="center">
																		<v-col class="text-center">
																			No Venture Found
																		</v-col>
																	</v-row>
																</v-container>
															</v-flex>
														</v-layout>
													</v-card>
												</div>
												<div v-else>
													<div class="hidden-sm-and-down">
														<v-card elevation="0" class="pa-2">
															<v-simple-table>
																<template v-slot:default>
																	<thead class="header">
																		<tr class="secondary">
																			<th class="text-left text--text">#</th>
																			<th class="text-left text--text">Images</th>
																			<th class="text-left text--text">Principal Founder</th>
																			<th class="text-left text--text">Venture</th>
																			<th class="text-right text--text">Rating</th>
																			<th class="text-left text--text">Date Added</th>
																			<th class="text-right text--text">Actions</th>
																		</tr>
																	</thead>
																	<tbody>
																		<tr v-for="(item, index) in ventures" :key="item.id">
																			<td>
																				{{ index + 1 }}
																			</td>
																			<td>
																				<v-avatar size="40" class="primary title white--text mx-1"
																					v-if="item.venture.user.prof_pic == null">
																					{{ item.venture.user.first_name[0] }}
																				</v-avatar>
																				<v-avatar size="40" class="primary title mx-1" v-else>
																					<img :src="path + '/storage/profile_pics/' + item.venture.user.prof_pic"
																					>
																				</v-avatar>
																				<v-avatar size="40" class="primary title mx-1 white--text"
																					v-if="item.venture.image == null">
																					{{ item.venture.name[0] }}
																				</v-avatar>
																				<v-avatar size="40" class="primary title mx-1" v-else>
																					<img :src="path + '/storage/venture_logos/' + item.venture.image"
																					>
																				</v-avatar>
																			</td>
																			<td>{{ item.venture.user.first_name }} {{ item.venture.user.middle_name }} {{ item.venture.user.last_name }}</td>
																			<td>{{ item.venture.name }}</td>
																			<td>
																				<div align="right" v-if="item.individualScore==0">
																					<i>Pending</i>
																				</div>
																				<div v-else>
																					<div align="right" class="red--text" v-if="item.individualScore>0 && item.individualScore<=33.33">
																						{{ item.individualScore }}%
																					</div>
																					<div align="right" class="orange--text" v-if="item.individualScore>33.33 && item.individualScore<=66.66">
																						{{ item.individualScore }}%
																					</div>
																					<div align="right" class="green--text" v-if="item.individualScore>66.66">
																						{{ item.individualScore }}%
																					</div>
																				</div>
																			</td>
																			<td>
																				{{
																					item.venture.created_at | moment("DD/MM/YYYY - hh:mm a")
																				}}
																			</td>
																			<td>
																				<div align="right">
																					<v-tooltip top v-if="$can('group_venture_view')">
																						<template v-slot:activator="{ on, attrs }">
																							<v-btn icon class="button mr-1 universal--text"
																								@click="showApplication(item)">
																								<v-icon small> mdi-eye </v-icon>
																							</v-btn>
																						</template>
																						<span>View</span>
																					</v-tooltip>
																					<v-tooltip top v-if="$can('group_venture_archive')">
																						<template v-slot:activator="{ on, attrs }">
																							<v-btn icon class="button mr-1 error--text" @click="deleteGroupVenture(item)"
																								:loading="loading && groupVentureIndex == item.id" v-bind="attrs" v-on="on">
																								<v-icon small> mdi-delete </v-icon>
																							</v-btn>
																						</template>
																						<span>delete</span>
																						</v-tooltip>
																					
																					<v-tooltip top v-if="$can('log_view')">
																						<template v-slot:activator="{ on, attrs }">
																							<v-btn icon class="button mr-1 purple--text"
																								@click="viewLog(item)" v-bind="attrs"
																								v-on="on">
																								<v-icon small> mdi-script-text </v-icon>
																							</v-btn>
																						</template>
																						<span>Log</span>
																					</v-tooltip>
																				</div>
																			</td>
																		</tr>
																	</tbody>
																</template>
															</v-simple-table>
														</v-card>
													</div>
													<div class="hidden-md-and-up">
														<div>
															<v-layout column>
																<template v-for="(
                                                                    venture, index
                                                                    ) in ventures">
																	<div :key="index">
																		<v-flex xs12 class="mb-2">
																			<v-card elevation="0" style="border-radius: 25px;" outlined>
																				<div class="pa-5 mt-2">
																					<v-layout column>
																						<v-flex xs12>
																							<v-layout row wrap>
																								<v-flex xs11>
																									<div class="title primary--text">
																										<b>{{
																											venture.venture.name
																										}}</b>
																									</div>
																								</v-flex>
																								<v-flex xs1>
																									<v-btn depressed v-if="$can('group_venture_archive')"
																										class="red--text" :loading="loading &&
																											groupVentureIndex ==
																											venture.id
																											" icon @click="
																											deleteGroupVenture(
																												venture
																											)
																											">
																										<v-icon>
																											mdi-delete
																										</v-icon>
																									</v-btn>
																								</v-flex>
																							</v-layout>
																						</v-flex>
																						<v-divider class="mt-2"></v-divider>
																						<v-flex xs12 class="mt-1">
																							<v-layout column>
																								<v-flex xs12 class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs12>
																											<b>Principal Founder</b>
																										</v-flex>
																										<v-flex xs12>
																											{{ venture.venture.user.first_name }} {{ venture.venture.user.middle_name }} {{ venture.venture.user.last_name }}
																										</v-flex>
																									</v-layout>
																								</v-flex>
																								<v-flex xs12 class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs12>
																											<b>Rating</b>
																										</v-flex>
																										<v-flex xs12>
																											<div v-if="venture.individualScore==0">
																												<i>Pending</i>
																											</div>
																											<div v-else>
																												<div class="red--text" v-if="venture.individualScore>0 && venture.individualScore<=33.33">
																													{{ venture.individualScore }}%
																												</div>
																												<div class="orange--text" v-if="venture.individualScore>33.33 && venture.individualScore<=66.66">
																													{{ venture.individualScore }}%
																												</div>
																												<div class="green--text" v-if="venture.individualScore>66.66">
																													{{ venture.individualScore }}%
																												</div>
																											</div>
																										</v-flex>
																									</v-layout>
																								</v-flex>
																								<v-flex xs12 class="mt-5">
																									<v-layout row wrap>
																											<v-flex xs12>
																													<b>Date Added</b>
																											</v-flex>
																											<v-flex xs12>
																													{{ venture.venture.created_at | moment("DD/MM/YYYY") }}
																											</v-flex>
																									</v-layout>
																							</v-flex>

																								<v-flex xs12 class="mt-5">
																									<v-layout row wrap>
																										<v-flex xs12>
																											<div>
																												<v-btn v-if="$can('group_venture_view')" rounded depressed
																													class="universal text-none white--text mt-1" block @click="
																														showApplication(
																															venture
																														)
																														">View
																													<v-icon right>
																														mdi-eye
																													</v-icon>
																												</v-btn>
																											</div>
																										</v-flex>
																									</v-layout>
																								</v-flex>
																							</v-layout>
																						</v-flex>
																					</v-layout>
																				</div>
																			</v-card>
																		</v-flex>
																	</div>
																</template>
															</v-layout>
														</div>
													</div>
												</div>
											</v-flex>
										</v-card>
										<v-layout column class="mt-5">
											<v-flex xs12>
												<div align="left">
													<v-pagination v-if="length != 0" :length="ventureLength" total-visible="10" v-model="groupVenturePagination.current_page
														" @input="changeGroupVenturePage()" circle>
													</v-pagination>
												</div>
											</v-flex>
										</v-layout>
									</div>
									<div v-if="ventureView == 'create'">
										<v-flex class="mt-5">
											<v-card-title class="secondary text--text">
												<v-spacer></v-spacer>
												<div v-if="newGroupVenture.id == null">
													Add Venture
												</div>
												<div v-else>Edit Venture</div>
												<v-spacer></v-spacer>
												<v-btn icon @click="
													changeVentureView('default')
													">
													<v-icon class="text--text"> mdi-close </v-icon>
												</v-btn>
											</v-card-title>
											<v-card-text>
												<v-form v-model="valid" ref="ventureForm" lazy-validation>
													<v-layout row wrap class="mt-5">
														<v-flex xs12 md4>
															<div class="pa-3">
																<div>Venture<span class="error--text">*</span></div>
																<v-select :items="formData.ventures" v-model="newGroupVenture.venture_id"
																	class="text_field background my-2" item-value="id" item-text="name" outlined dense
																	:rules="inputRules"></v-select>
															</div>
														</v-flex>
													</v-layout>
												</v-form>
											</v-card-text>
											<v-card-actions>
												<v-spacer></v-spacer>
												<v-btn depressed class="primary text-none mt-3" @click="saveGroupVenture()" :disabled="!valid"
													:loading="loading">
													Save
													<v-icon right> mdi-content-save </v-icon>
												</v-btn>
											</v-card-actions>
										</v-flex>
									</div>
									<div v-if="ventureView == 'venture'">
										<v-card outlined class="mt-5">
											<v-card-title class="tableHeader text--text">
												{{ assignedApplication.venture.user.first_name }} {{ assignedApplication.venture.user.middle_name }} {{ assignedApplication.venture.user.last_name }} - {{ assignedApplication.venture.name }}
												<v-spacer></v-spacer>
												<v-btn icon @click="changeVentureView('default')">
													<v-icon class="text--text"> mdi-close </v-icon>
												</v-btn>
											</v-card-title>
											<v-card-text>
												<div class="mt-2">
													<v-tabs show-arrows v-model="applicantTab">
														<v-tabs-slider color="primary"></v-tabs-slider>

														<v-tab @click="subLevelTab = 'principalFounder'">
															<div class="text-none primary--text">Principal Founder</div>
														</v-tab>
														<v-tab @click="subLevelTab = 'venture'">
															<div class="text-none primary--text">
																Venture
															</div>
														</v-tab>
														<v-tab @click="subLevelTab = 'problemSolution'">
															<div class="text-none primary--text">
																Problem/Solution
															</div>
														</v-tab>
														<v-tab @click="subLevelTab = 'team'">
															<div class="text-none primary--text">
																Team
															</div>
														</v-tab>
														<v-tab @click="subLevelTab = 'rating'">
															<div class="text-none primary--text">
																Rating
															</div>
														</v-tab>
														<v-tab @click="subLevelTab = 'criteria'">
															<div class="text-none primary--text">
																Criteria Guide
															</div>
														</v-tab>
													</v-tabs>
													<div v-if="subLevelTab == 'principalFounder'" class="mx-3 mt-7">
														<v-layout row wrap>
															<v-flex xs12 md3>
																<div align="center" class="mt-7">
																	<v-avatar size="200" class="primary title white--text"
																		v-if="assignedApplication.venture.user.prof_pic == null">
																		{{ assignedApplication.venture.user.first_name[0] }}
																	</v-avatar>
																	<v-avatar size="200" class="primary title" v-else>
																		<img :src="path + '/storage/profile_pics/' + assignedApplication.venture.user.prof_pic"
																		>
																	</v-avatar>
																</div>
															</v-flex>
															<v-flex xs12 md9>
																<div class="pa-5">
																	<v-layout row wrap>
																		<v-flex xs12 md6>
																			<v-layout column>
																				<v-flex xs12 class="mt-7">
																					<v-layout row wrap>
																						<v-flex xs12 md3>
																							<div><b>Name:</b></div>
																						</v-flex>
																						<v-flex xs12 md9>
																							{{ assignedApplication.venture.user.first_name }} {{ assignedApplication.venture.user.middle_name }} {{ assignedApplication.venture.user.last_name }}
																						</v-flex>
																					</v-layout>
																				</v-flex>
																				<v-flex xs12 class="mt-7">
																					<v-layout row wrap>
																						<v-flex xs12 md3>
																							<div><b>Email Address:</b></div>
																						</v-flex>
																						<v-flex xs12 md9>
																							{{ assignedApplication.venture.user.email }}
																						</v-flex>
																					</v-layout>
																				</v-flex>
																				<v-flex xs12 class="mt-7">
																					<v-layout row wrap>
																						<v-flex xs12 md3>
																							<div><b>Phone Number:</b></div>
																						</v-flex>
																						<v-flex xs12 md9>
																							{{ assignedApplication.venture.user.phone }}
																						</v-flex>
																					</v-layout>
																				</v-flex>

																			</v-layout>
																		</v-flex>
																		<v-flex xs12 md6>
																			<v-layout column>
																				<v-flex xs12 class="mt-7">
																					<v-layout row wrap>
																						<v-flex xs12 md3>
																							<div><b>Gender:</b></div>
																						</v-flex>
																						<v-flex xs12 md9>
																							{{ assignedApplication.venture.user.gender.name }}
																						</v-flex>
																					</v-layout>
																				</v-flex>
																				<v-flex xs12 class="mt-7">
																					<v-layout row wrap>
																						<v-flex xs12 md3>
																							<div><b>Nationality:</b></div>
																						</v-flex>
																						<v-flex xs12 md9>
																							{{ assignedApplication.venture.user.country.name }}
																						</v-flex>
																					</v-layout>
																				</v-flex>
																				<v-flex xs12 class="mt-7">
																					<v-layout row wrap>
																						<v-flex xs12 md3>
																							<div><b>Date of Birth:</b></div>
																						</v-flex>
																						<v-flex xs12 md9>
																							{{ assignedApplication.venture.user.dob | moment("DD/MM/YYYY") }}
																						</v-flex>
																					</v-layout>
																				</v-flex>

																			</v-layout>
																		</v-flex>
																		<v-flex xs12 class="mt-10">
																			<v-layout row wrap>
																				<v-flex xs12>
																					<div><b>Socials</b></div>
																				</v-flex>
																			</v-layout>														
																		</v-flex>
																		<v-flex xs12 md6>
																			<v-layout column>
																				<v-flex xs12 class="mt-7">
																					<v-layout row wrap>
																						<v-flex xs12 md3>
																							<div><b>LinkedIn:</b></div>
																						</v-flex>
																						<v-flex xs12 md9>
																							<div v-if="assignedApplication.venture.user.linkedin_url!=null">
																								<a :href="assignedApplication.venture.user.linkedin_url" style="text-decoration: none;">
																									{{ assignedApplication.venture.user.linkedin_url }}
																								</a>
																							</div>
																							<div v-else>
																								N/A
																							</div>	
																						</v-flex>
																					</v-layout>
																				</v-flex>
																			</v-layout>
																		</v-flex>
																		<v-flex xs12 md6>
																			<v-layout column>
																				<v-flex xs12 class="mt-7">
																					<v-layout row wrap>
																						<v-flex xs12 md3>
																							<div><b>X:</b></div>
																						</v-flex>
																						<v-flex xs12 md9>
																							<div v-if="assignedApplication.venture.user.twitter_url!=null">
																								<a :href="assignedApplication.venture.user.twitter_url" style="text-decoration: none;">
																									{{ assignedApplication.venture.user.twitter_url }}
																								</a>
																							</div>
																							<div v-else>
																								N/A
																							</div>	
																						</v-flex>
																					</v-layout>
																				</v-flex>
																			</v-layout>
																		</v-flex>
																		<v-flex xs12 class="mt-10">
																			<v-layout row wrap>
																				<v-flex xs12>
																					<div><b>Bio</b></div>
																				</v-flex>
																			</v-layout>														
																		</v-flex>
																		<v-flex xs12>
																			<v-layout column>
																				<v-flex xs12 class="my-7">
																					<v-layout row wrap>
																						<v-flex xs12>
																							<div v-if="assignedApplication.venture.user.bio != null" v-html="assignedApplication.venture.user.bio"></div>
																							<div v-else>N/A</div>
																						</v-flex>
																					</v-layout>
																				</v-flex>
																			</v-layout>
																		</v-flex>
																	</v-layout>
																	
																</div>
															</v-flex>
														</v-layout>
														
													</div>
													<div v-if="subLevelTab == 'venture'" class="mx-3 mt-7">
														<v-layout row wrap>
															<v-flex xs12 md3>
																<div align="center" class="mt-7">
																	<v-avatar size="200" class="primary title white--text"
																		v-if="assignedApplication.venture.image == null">
																		{{ assignedApplication.venture.name[0] }}
																	</v-avatar>
																	<v-avatar size="200" class="primary title" v-else>
																		<img :src="path + '/storage/venture_logos/' + assignedApplication.venture.image"
																		>
																	</v-avatar>
																</div>
															</v-flex>
															<v-flex xs12 md9>
																<div class="pa-5">
																	<v-layout row wrap>
																		<v-flex xs12 md6>
																			<v-layout column>
																				<v-flex xs12 class="mt-7">
																					<v-layout row wrap>
																						<v-flex xs12 md3>
																							<div><b>Name:</b></div>
																						</v-flex>
																						<v-flex xs12 md9>
																							{{ assignedApplication.venture.name }}
																						</v-flex>
																					</v-layout>
																				</v-flex>
																				<v-flex xs12 class="mt-7">
																					<v-layout row wrap>
																						<v-flex xs12 md3>
																							<div><b>Email Address:</b></div>
																						</v-flex>
																						<v-flex xs12 md9>
																							{{ assignedApplication.venture.email_address }}
																						</v-flex>
																					</v-layout>
																				</v-flex>
																				<v-flex xs12 class="mt-7">
																					<v-layout row wrap>
																						<v-flex xs12 md3>
																							<div><b>Phone Number:</b></div>
																						</v-flex>
																						<v-flex xs12 md9>
																							{{ assignedApplication.venture.phone_number }}
																						</v-flex>
																					</v-layout>
																				</v-flex>
																				<v-flex xs12 class="mt-7">
																					<v-layout row wrap>
																						<v-flex xs12 md3>
																							<div><b>Stage:</b></div>
																						</v-flex>
																						<v-flex xs12 md9>
																							{{ assignedApplication.venture.step.name }}
																						</v-flex>
																					</v-layout>
																				</v-flex>

																			</v-layout>
																		</v-flex>
																		<v-flex xs12 md6>
																			<v-layout column>
																				<v-flex xs12 class="mt-7">
																					<v-layout row wrap>
																						<v-flex xs12 md3>
																							<div><b>Established:</b></div>
																						</v-flex>
																						<v-flex xs12 md9>
																							<div v-if="assignedApplication.venture.year_established != null">
																								{{ assignedApplication.venture.year_established }}
																							</div>
																							<div v-else>
																								N/A
																							</div>
																						</v-flex>
																					</v-layout>
																				</v-flex>
																				<v-flex xs12 class="mt-7">
																					<v-layout row wrap>
																						<v-flex xs12 md3>
																							<div><b>Website:</b></div>
																						</v-flex>
																						<v-flex xs12 md9>
																							<div v-if="assignedApplication.venture.website_url != null">
																								{{ assignedApplication.venture.website_url }}
																							</div>
																							<div v-else>
																								N/A
																							</div>
																						</v-flex>
																					</v-layout>
																				</v-flex>
																				<v-flex xs12 class="mt-7">
																					<v-layout row wrap>
																						<v-flex xs12 md3>
																							<div><b>
																								Video Pitch:</b></div>
																						</v-flex>
																						<v-flex xs12 md9>
																							<div v-if="assignedApplication.venture.video_pitch_url != null">
																								{{ assignedApplication.venture.video_pitch_url }}
																							</div>
																							<div v-else>
																								N/A
																							</div>
																						</v-flex>
																					</v-layout>
																				</v-flex>
																				<v-flex xs12 class="mt-7">
																					<v-layout row wrap>
																						<v-flex xs12 md3>
																							<div><b>Prev Funding:</b></div>
																						</v-flex>
																						<v-flex xs12 md9>
																							<div v-if="assignedApplication.venture.funding=1">
																								Yes
																							</div>
																							<div v-else>
																								No
																							</div>
																							
																						</v-flex>
																					</v-layout>
																				</v-flex>

																			</v-layout>
																		</v-flex>
																		<v-flex xs12 class="mt-10">
																			<v-layout row wrap>
																				<v-flex xs12>
																					<div><b>Slogan</b></div>
																				</v-flex>
																			</v-layout>														
																		</v-flex>
																		<v-flex xs12>
																			<v-layout column>
																				<v-flex xs12 class="mt-7">
																					<v-layout row wrap>
																						<v-flex xs12>
																							<div v-if="assignedApplication.venture.slogan != null">
																								{{ assignedApplication.venture.slogan }}
																							</div>
																							<div v-else>
																								N/A
																							</div>
																						</v-flex>
																					</v-layout>
																				</v-flex>
																			</v-layout>
																		</v-flex>
																		<v-flex xs12 class="mt-7">
																			<v-layout row wrap>
																				<v-flex xs12>
																					<div><b>Physical Address</b></div>
																				</v-flex>
																			</v-layout>														
																		</v-flex>
																		<v-flex xs12>
																			<v-layout column>
																				<v-flex xs12 class="mt-7">
																					<v-layout row wrap>
																						<v-flex xs12>
																							<div v-if="assignedApplication.venture.physical_address != null">
																								{{ assignedApplication.venture.physical_address }}
																							</div>
																							<div v-else>
																								N/A
																							</div>
																						</v-flex>
																					</v-layout>
																				</v-flex>
																			</v-layout>
																		</v-flex>

																		
																		<v-flex xs12 md6>
																			<v-layout column>
																				<v-flex xs12 class="mt-10">
																					<v-layout row wrap>
																						<v-flex xs12 md3>
																							<div><b>Sectors:</b></div>
																						</v-flex>
																						<v-flex xs12 md9>
																							<v-layout column>
																								<template v-for="(sector, index) in assignedApplication.venture.venture_sectors">
																									<v-flex xs12>
																										{{ index+1 }}. {{ sector.sector.name }}
																									</v-flex>
																								</template>
																							</v-layout>
																						</v-flex>
																					</v-layout>
																				</v-flex>
																				<v-flex xs12 class="mt-7">
																					<v-layout row wrap>
																						<v-flex xs12 md3>
																							<div><b>Client Loc(s):</b></div>
																						</v-flex>
																						<v-flex xs12 md9>
																							<v-layout column>
																								<template v-for="(location, index) in assignedApplication.venture.venture_client_locations">
																									<v-flex xs12>
																										{{ index+1 }}. {{ location.client_location.name }}
																									</v-flex>
																								</template>
																							</v-layout>
																						</v-flex>
																					</v-layout>
																				</v-flex>
																			</v-layout>
																		</v-flex>
																		<v-flex xs12 md6>
																			<v-layout column>
																				<v-flex xs12 class="mt-10">
																					<v-layout row wrap>
																						<v-flex xs12 md3>
																							<div><b>Client Type(s):</b></div>
																						</v-flex>
																						<v-flex xs12 md9>
																							<v-layout column>
																								<template v-for="(type, index) in assignedApplication.venture.venture_client_types">
																									<v-flex xs12>
																										{{ index+1 }}. {{ type.client_type.name }}
																									</v-flex>
																								</template>
																							</v-layout>
																						</v-flex>
																					</v-layout>
																				</v-flex>
																				
																			
																			</v-layout>
																		</v-flex>
																		<v-flex xs12 class="mt-7">
																			<div class="my-5 mx-16">
																				<v-divider></v-divider>
																			</div>
																		</v-flex>
																		<v-flex xs12 md6>
																			<div class="mx-1 my-5" align="center">
																				<v-layout row wrap class="mt-2">
																					<v-flex xs12 md6>
																						<div class="mt-2">
																							CR12 Certificate
																						</div>
																					</v-flex>
																					<v-flex xs12 md6>
																						<v-btn depressed color="#F40F02" class="white--text text-none" @click="viewDocument('CR12')">
																							View File <v-icon right small>mdi-file-pdf-box</v-icon>
																						</v-btn>
																					</v-flex>
																				</v-layout>
																			</div>
																		</v-flex>
																		<v-flex xs12 md6>
																			<div class="mx-1 my-5" align="center">
																				<v-layout row wrap class="mt-2">
																					<v-flex xs12 md6>
																						<div class="mt-2">
																							Registration Cetificate
																						</div>
																					</v-flex>
																					<v-flex xs12 md6>
																						<v-btn depressed color="#F40F02" class="white--text text-none" @click="viewDocument('Registration Cetificate')">
																							View File <v-icon right small>mdi-file-pdf-box</v-icon>
																						</v-btn>
																					</v-flex>
																				</v-layout>
																			</div>
																		</v-flex>
																		<v-flex xs12>
																			<div class="mt-8 mb-5 mx-16">
																				<v-divider></v-divider>
																			</div>
																		</v-flex>

																		<v-flex xs12 class="mt-10">
																			<v-layout row wrap>
																				<v-flex xs12>
																					<div><b>Socials</b></div>
																				</v-flex>
																			</v-layout>														
																		</v-flex>
																		<v-flex xs12 md6>
																			<v-layout column>
																				<v-flex xs12 class="mt-7">
																					<v-layout row wrap>
																						<v-flex xs12 md3>
																							<div><b>X:</b></div>
																						</v-flex>
																						<v-flex xs12 md9>
																							<div v-if="assignedApplication.venture.x_url!=null">
																								<a :href="assignedApplication.venture.x_url" style="text-decoration: none;">
																									{{ assignedApplication.venture.x_url }}
																								</a>
																							</div>
																							<div v-else>
																								N/A
																							</div>	
																						</v-flex>
																					</v-layout>
																				</v-flex>
																				<v-flex xs12 class="mt-7">
																					<v-layout row wrap>
																						<v-flex xs12 md3>
																							<div><b>Facebook:</b></div>
																						</v-flex>
																						<v-flex xs12 md9>
																							<div v-if="assignedApplication.venture.facebook_url!=null">
																								<a :href="assignedApplication.venture.facebook_url" style="text-decoration: none;">
																									{{ assignedApplication.venture.facebook_url }}
																								</a>
																							</div>
																							<div v-else>
																								N/A
																							</div>	
																						</v-flex>
																					</v-layout>
																				</v-flex>
																				<v-flex xs12 class="mt-7">
																					<v-layout row wrap>
																						<v-flex xs12 md3>
																							<div><b>LinkedIn:</b></div>
																						</v-flex>
																						<v-flex xs12 md9>
																							<div v-if="assignedApplication.venture.linkedin_url!=null">
																								<a :href="assignedApplication.venture.linkedin_url" style="text-decoration: none;">
																									{{ assignedApplication.venture.linkedin_url }}
																								</a>
																							</div>
																							<div v-else>
																								N/A
																							</div>
																						</v-flex>
																					</v-layout>
																				</v-flex>
																			</v-layout>
																		</v-flex>
																		<v-flex xs12 md6>
																			<v-layout column>
																				<v-flex xs12 class="mt-7">
																					<v-layout row wrap>
																						<v-flex xs12 md3>
																							<div><b>YouTube:</b></div>
																						</v-flex>
																						<v-flex xs12 md9>
																							<div v-if="assignedApplication.venture.youtube_url!=null">
																								<a :href="assignedApplication.venture.youtube_url" style="text-decoration: none;">
																									{{ assignedApplication.venture.youtube_url }}
																								</a>
																							</div>
																							<div v-else>
																								N/A
																							</div>
																						</v-flex>
																					</v-layout>
																				</v-flex>
																				<v-flex xs12 class="mt-7">
																					<v-layout row wrap>
																						<v-flex xs12 md3>
																							<div><b>Instagram:</b></div>
																						</v-flex>
																						<v-flex xs12 md9>
																							<div v-if="assignedApplication.venture.instagram_url!=null">
																								<a :href="assignedApplication.venture.instagram_url" style="text-decoration: none;">
																									{{ assignedApplication.venture.instagram_url }}
																								</a>
																							</div>
																							<div v-else>
																								N/A
																							</div>
																						</v-flex>
																					</v-layout>
																				</v-flex>
																				<v-flex xs12 class="mt-7">
																					<v-layout row wrap>
																						<v-flex xs12 md3>
																							<div><b>TikTok:</b></div>
																						</v-flex>
																						<v-flex xs12 md9>
																							<div v-if="assignedApplication.venture.tiktok_url!=null">
																								<a :href="assignedApplication.venture.tiktok_url" style="text-decoration: none;">
																									{{ assignedApplication.venture.tiktok_url }}
																								</a>
																							</div>
																							<div v-else>
																								N/A
																							</div>
																						</v-flex>
																					</v-layout>
																				</v-flex>
																			</v-layout>
																		</v-flex>
																		<v-flex xs12 class="mt-10">
																			<v-layout row wrap>
																				<v-flex xs12>
																					<div><b>Company Overview</b></div>
																				</v-flex>
																			</v-layout>														
																		</v-flex>
																		<v-flex xs12>
																			<v-layout column>
																				<v-flex xs12 class="mt-7">
																					<v-layout row wrap>
																						<v-flex xs12>
																							<div v-html="assignedApplication.venture.description"> </div>
																						</v-flex>
																					</v-layout>
																				</v-flex>
																			</v-layout>
																		</v-flex>
																	</v-layout>																	
																</div>
															</v-flex>
														</v-layout>
														
													</div>
													<div v-if="subLevelTab == 'problemSolution'" class="mx-3 mt-7">
														<v-layout column>
															<div v-if="questions.length != 0">
																	<template v-for="(question, index) in questions">
																		<div :key="index">
																			<v-flex xs12 class="my-2">
																				<v-card class="mx-auto" elevation="0">
																					<v-card-text>
																						<v-layout row wrap>
																							<v-flex xs1>
																								<div align="center" class="subtitle-1">
																									{{ index + 1 }}.
																								</div>
																							</v-flex>
																							<v-flex xs11>
																								<div class="subtitle-1">
																									<b>{{ question.question }}<span class="red--text" v-if="question.required==1">*</span></b>
																								</div>
																								<div class="font-italic caption">{{ question.description }}</div>
																								<div v-if="question.question_type.name == 'Single Select Multiple Choice Question'">
																									<v-radio-group
																										disabled
																										v-model="singleSelect[question.id]"
																										column>
																										<template v-for="(option, index) in question.multi_choice_options">
																											<div :key="index">
																												<v-radio
																													:label="option.option"
																													:value="option.id"
																													@change="checkOther(question.id, option, question)"></v-radio>
																											</div>
																										</template>
																										<div
																											v-if="otherQuestions[question.id] == true">
																												{{ openEnded[question.id] }}
																										</div>
																										<div v-if="childQuestions.length != 0"
																											class="mt-3">
																											<template
																												v-for="(question, index) in childQuestions[question.id]">
																												<div :key="index">
																													<v-flex xs12
																														class="my-2">
																														<v-card
																															:loading="loading"
																															class="mx-auto"
																															elevation="0">
																															<v-card-text>
																																<v-layout
																																	row
																																	wrap>
																																	<v-flex
																																		xs1>
																																		<div align="center"
																																			class="subtitle-1">
																																			{{ index + 1 }}.
																																		</div>
																																	</v-flex>
																																	<v-flex
																																		xs11>
																																		<div
																																			class="subtitle-1">
																																			<b>{{ question.question }}</b>
																																		</div>
																																		<div v-if="question.question_type.name == 'Single Select Multiple Choice Question'">
																																			<v-radio-group
																																				disabled
																																				v-model="singleSelect[question.id]"
																																				column>
																																				<template
																																					v-for="(option, index) in question.multi_choice_options">
																																					<div
																																						:key="index">
																																						<v-radio
																																							:label="option.option"
																																							:value="option.id"
																																							@change="checkOther(question.id, option, question)"></v-radio>
																																					</div>
																																				</template>
																																				<div
																																					v-if="otherQuestions[question.id] == true">
																																						{{ openEnded[question.id] }}
																																				</div>
																																				<div
																																					v-if="childQuestions.length != 0">
																																					{{ childQuestions[question.id] }}
																																				</div>
																																			</v-radio-group>
																																		</div>
																																		<div v-if="question.question_type.name == 'Multi Part Single Select Multiple Choice Question'">
																																			<div
																																				class="mt-4">
																																				<template
																																					v-for="( multi_question, index ) in question.multi_part_questions">
																																					<div :key="index">{{ multi_question.question }}
																																						<v-radio-group disabled
																																							v-model="multiPartSingleAnswers[multi_question.id]" column>
																																							<template
																																								v-for="(option, index) in multi_question.multi_part_options">
																																								<div
																																									:key="index">
																																									<v-radio
																																										:label="option.option"
																																										:value="option.id"
																																										@change="calculateProgress()"></v-radio>
																																								</div>
																																							</template>
																																						</v-radio-group>
																																					</div>
																																				</template>
																																			</div>
																																		</div>
																																		<div v-if="question.question_type.name == 'Multi Select Multiple Choice Questions'">
																																			<div
																																				class="mt-4">
																																				<template
																																					v-for="(option, index) in question.multi_choice_options">
																																					<v-checkbox
																																						disabled
																																						v-model="multiSelects"
																																						:key="index"
																																						:label="option.option"
																																						:value="option.id"
																																						@change="calculateProgress()"></v-checkbox>
																																				</template>

																																			</div>
																																		</div>
																																		<div v-if="question.question_type.name == 'Multi Part Multi Select Multiple Choice Question'">
																																			<div
																																				class="mt-4">
																																				<template
																																					v-for="(multi_question, index) in question.multi_part_questions">
																																					<div :key="index">{{ multi_question.question }}
																																						<template
																																							v-for="(option, index) in multi_question.multi_part_options">
																																							<v-checkbox
																																								disabled
																																								v-model="multiPartMultiAnswers"
																																								:key="index"
																																								:label="option.option"
																																								:value="option.id"
																																								@change="calculateProgress()"></v-checkbox>
																																						</template>
																																					</div>
																																				</template>
																																			</div>
																																		</div>
																																		<div v-if="question.question_type.name == 'Open Ended'">
																																			<div
																																				class="mt-3 mb-5" v-if="question.length<=25">
																																					<div v-if="openEnded[question.id] != null">
																																						{{ openEnded[question.id] }}
																																					</div>
																																					<div v-else>
																																						N/A
																																					</div>
																																			</div>
																																		</div>
																																	</v-flex>
																																</v-layout>
																															</v-card-text>
																														</v-card>
																													</v-flex>
																												</div>
																											</template>
																										</div>
																									</v-radio-group>
																								</div>
																								<div v-if="question.question_type.name =='Multi Part Single Select Multiple Choice Question'">
																									<div class="mt-4">
																										<template v-for="(multi_question, index) in question.multi_part_questions">
																											<div :key="index">
																												{{ multi_question.question }}
																												<v-radio-group disabled v-model="multiPartSingleAnswers[multi_question.id]" column>
																													<template v-for="(option, index) in multi_question.multi_part_options">
																														<div :key="index">
																															<v-radio
																																:label="option.option"
																																:value="option.id">
																															</v-radio>
																														</div>
																													</template>
																												</v-radio-group>
																												<v-text-field
																													v-if="multi_question.question == 'Other'"
																													class="text_field mb-6"
																													outlined
																													disabled
																													v-model="openEnded[question.id]"
																													label="Please specify.."
																													required></v-text-field>
																											</div>
																										</template>
																									</div>
																								</div>
																								<div v-if="question.question_type.name == 'Multi Select Multiple Choice Questions'">
																									<div class="mt-4">
																										<template v-for="(option, index) in question.multi_choice_options">
																											<v-checkbox
																												disabled
																												v-model="multiSelects"
																												:key="index"
																												:label="option.option"
																												:value="option.id"></v-checkbox>
																										</template>
																									</div>
																								</div>
																								<div v-if="question.question_type.name =='Multi Part Multi Select Multiple Choice Question'">
																									<div class="mt-4">
																										<template v-for="(multi_question, index) in question.multi_part_questions">
																											<div :key="index">
																												{{ multi_question.question }}
																												<template v-for="(option, index) in multi_question.multi_part_options">
																													<v-checkbox
																														disabled
																														v-model="multiPartMultiAnswers"
																														:key="index"
																														:label="option.option"
																														:value="option.id"></v-checkbox>
																												</template>
																											</div>
																										</template>
																									</div>
																								</div>
																								<div v-if="question.question_type.name == 'Open Ended'">
																									<div class="mt-3 mb-5" v-if="question.length<=25 && question.length!=null">
																											<div v-if="openEnded[question.id] != null">
																												{{ openEnded[question.id] }}
																											</div>
																											<div v-else>
																												N/A
																											</div>
																									</div>
																									<div class="mt-3 mb-5" v-else>
																										<div v-html="openEnded[question.id]"></div>																					
																									</div>
																								</div>
																							</v-flex>
																						</v-layout>
																					</v-card-text>
																				</v-card>
																			</v-flex>
																		</div>
																	</template>
																</div>
														</v-layout>
													</div>
													<div v-if="subLevelTab == 'team'" class="mx-3 mt-7">
														<v-layout column>
															<template v-for="(member, index) in assignedApplication.venture.venture_team_members">
																<v-flex xs12 class="mb-13">
																	<v-layout row wrap>
																		<v-flex xs2>
																			<div class="mt-1" align="left">
																				{{ index+1 }}.
																			</div>
																		</v-flex>
																		<v-flex xs10>
																			<v-layout row wrap>
																				<v-flex xs12 md6>
																					<v-layout column>
																						<v-flex xs12 class="mt-7">
																							<v-layout row wrap>
																								<v-flex xs12 md3>
																									<div><b>Name:</b></div>
																								</v-flex>
																								<v-flex xs12 md9>
																									{{ member.first_name }} {{ member.middle_name }} {{ member.last_name }}
																								</v-flex>
																							</v-layout>
																						</v-flex>
																						<v-flex xs12 class="mt-7">
																							<v-layout row wrap>
																								<v-flex xs12 md3>
																									<div><b>Role:</b></div>
																								</v-flex>
																								<v-flex xs12 md9>
																									{{ member.role }}
																								</v-flex>
																							</v-layout>
																						</v-flex>
																						<v-flex xs12 class="mt-7">
																							<v-layout row wrap>
																								<v-flex xs12 md3>
																									<div><b>Shares:</b></div>
																								</v-flex>
																								<v-flex xs12 md9>
																									<div v-if="member.shares != null">
																										{{ member.shares }}
																									</div>
																									<div v-else>
																										N/A
																									</div>
																								</v-flex>
																							</v-layout>
																						</v-flex>
																					</v-layout>
																				</v-flex>
																				<v-flex xs12 md6>
																					<v-layout column>
																						<v-flex xs12 class="mt-7">
																							<v-layout row wrap>
																								<v-flex xs12 md3>
																									<div><b>Gender:</b></div>
																								</v-flex>
																								<v-flex xs12 md9>
																									{{ member.gender.name }}
																								</v-flex>
																							</v-layout>
																						</v-flex>
																						<v-flex xs12 class="mt-7">
																							<v-layout row wrap>
																								<v-flex xs12 md3>
																									<div><b>Years Experience:</b></div>
																								</v-flex>
																								<v-flex xs12 md9>
																									<div v-if="member.years_experience!=null">
																										{{ member.years_experience }}
																									</div>
																									<div v-else>
																										N/A
																									</div>
																								</v-flex>
																							</v-layout>
																						</v-flex>
																						<v-flex xs12 class="mt-7">
																							<v-layout row wrap>
																								<v-flex xs12 md3>
																									<div><b>Is a Founder:</b></div>
																								</v-flex>
																								<v-flex xs12 md9>
																									<div v-if="member.founder == 1">
																										Yes
																									</div>
																									<div v-else>
																										No
																									</div>
																								</v-flex>
																							</v-layout>
																						</v-flex>
																					</v-layout>
																				</v-flex>
																			</v-layout>
																		</v-flex>
																	</v-layout>
																</v-flex>
															</template>
														</v-layout>
													</div>
													<div v-if="subLevelTab == 'rating'">
														<v-layout column class="mt-5">
															<div v-if="formData.ratings.length != 0">
																<template v-for="(rating, index) in formData.ratings">
																	<div :key="index">
																		<v-flex xs12 class="mb-7">
																			<v-layout row wrap>
																				<v-flex xs1>
																					<div align="center">
																						{{ index+1 }}.
																					</div>
																				</v-flex>
																				<v-flex xs11>
																					<v-layout column>
																						<v-flex xs12>
																							<b>{{ rating.name }}</b>
																						</v-flex>
																						<v-flex xs12>
																							{{ rating.description }}
																						</v-flex>
																						<v-flex xs12>
																							Weight: {{ rating.weight }}
																						</v-flex>
																						<v-flex xs12>
																							<div v-if="rating.type=='radio'">
																								<v-layout row wrap>
																									<v-flex xs12 md10>
																										<v-rating
																												background-color="grey lighten-2"
																												color="warning"
																												half-increments
																												hover
																												length="10"
																												size="17"
																												v-model="ventureRating[rating.id]"
																											></v-rating>
																									</v-flex>
																									<v-flex xs12 md2>
																										<div align="right" class="mr-5" v-if="ventureRating[rating.id]">
																											<b>{{ ventureRating[rating.id] }}/10</b>
																										</div>
																										<div align="right" class="mr-5" v-else>
																											<b>-/10</b>
																										</div>
																									</v-flex>
																								</v-layout>
																							</div>
																							<div v-else>
																								<ckeditor :editor="editor" v-model="ventureRating[rating.id]"
																									:config="editorConfig" :rules="inputRules">
																								</ckeditor>
																							</div>
																						</v-flex>
																					</v-layout>
																					
																				</v-flex>
																			</v-layout>
																		</v-flex>
																	</div>
																</template>
															</div>
														</v-layout>
														<div align="right">
															<v-btn depressed class="primary text-none mt-5"
																@click="saveRatings()" :loading="loading">
																Save Rating <v-icon right dark>mdi-content-save-check</v-icon>
															</v-btn>
														</div>
													</div>
													<div v-if="subLevelTab == 'criteria'" class="mx-3 mt-7">
														<p>
															<div class="title font-weight-black mt-5">
																Adjudication Criteria
															</div>
															<div class="font-weight-black">TOTAL POINTS - 100</div>
															<ol>
																<li><b>Eligibility</b> - Meets the minimum eligibility criteria: female founder with a majority stake in the company, above 18 years, Kenyan nationality - <b>5 points</b></li>
																<li><b>Use of Tech</b> – Is the business leveraging on technology? Is the solution a new technology or a different way of doing things in the market? - <b>10 points</b></li>
																<li><b>Team</b> - Has a team of at least 3 people with demonstrated capacity to execute: clearly defined roles, balanced skillset (team lead, business development, product development, and marketing) - <b>10 points</b></li>
																<li><b>Problem</b> – Is a real problem being addressed? Is there a clear description of this problem with evidence of real-life user pain points? – <b>20 points</b></li>
																<li><b>Solution</b> - Is there a problem/ solution fit? Is the solution innovative? - <b>15 points</b></li>
																<li><b>Customer/users</b> – Does the business have paying customers? Does the business have users of the product or the service? – <b>10 points</b></li>
																<li><b>Market</b> – Is the market opportunity well described: total addressable, target and current market share? Are there high barriers of entry? How big is the market opportunity? - <b>10 points</b></li>
																<li><b>Business model</b> - How does the business make money? Is it sustainable? Is the business scalable? - <b>15 points</b></li>
																<li><b>Social Impact</b> – Does the business address any of the SDG goals? Is it highly relevant for Kenya in terms of social impact? – <b>5 points</b></li>
															</ol>
														</p>
													</div>
												</div>
											</v-card-text>
										</v-card>
									</div>
								</div>
								<div v-if="subTab == 'judges'">
									<div v-if="judgeView == 'default'">
										<v-card elevation="0" class="mt-5">
											<v-flex xs12 class="mt-10 mb-2">
												<div class="mx-5">
													<v-layout row wrap>
														<v-flex xs12 md4>
															<div v-if="judgeLength != 0" align="left" class="mt-1">
																<b class="display-1 primary--text">Total: </b>{{ userGroupPagination.total | formatNumber }}
															</div>
														</v-flex>
														<v-flex xs12 md4>
															<div class="pa-1">
																<v-text-field class="text_field header" outlined dense label="Search"
																	append-icon="mdi-undo-variant" @click:append="
																	resetJudgeSearch()
																	" v-on:keyup.enter="userSearch
																	" v-model="userGroupSearchTerm" @input="
																	enableUserSearch()
																	"></v-text-field>
															</div>
														</v-flex>
														<v-flex xs12 md2>
															<div class="pa-1">
																<v-btn class="secondary  white--text text-none" depressed block @click="userSearch" :loading="userSearchLoader
																	" :disabled="userSearchButtonDisabled
																	">
																	Search
																	<v-icon class="white--text" small right>mdi-magnify</v-icon>
																</v-btn>
															</div>
														</v-flex>
														<v-flex xs12 md2>
															<div class="pa-1">
																<v-btn block depressed class="primary text-none" v-if="$can('user_group_create')" @click="
																	changeUserView(
																		'create'
																	)
																	">
																	Add Judge
																	<v-icon class="white--text" small right>mdi-plus-circle-outline</v-icon>
																</v-btn>
															</div>
														</v-flex>
													</v-layout>
												</div>
											</v-flex>
											<v-divider class="mt-9 mx-5"></v-divider>
											<v-flex xs12 class="mt-5">
												<div v-if="users.length == 0">
													<v-card elevation="0">
														<v-layout row wrap>
															<v-flex xs12 md1>
																<v-container fill-height fluid>
																	<v-row align="center" justify="center">
																		<v-col class="text-center">
																			<v-icon large class="primary--text">
																				mdi-alert-circle-outline
																			</v-icon>
																		</v-col>
																	</v-row>
																</v-container>
															</v-flex>
															<v-flex xs12 md11>
																<v-container fill-height fluid>
																	<v-row align="center" justify="center">
																		<v-col class="text-center">
																			No Judge Found
																		</v-col>
																	</v-row>
																</v-container>
															</v-flex>
														</v-layout>
													</v-card>
												</div>
												<div v-else>
													<div class="hidden-sm-and-down">
														<v-card elevation="0" class="pa-2">
															<v-simple-table>
																<template v-slot:default>
																	<thead class="header">
																		<tr class="secondary">
																			<th class="text-left text--text">#</th>
																			<th class="text-left text--text">Profile Pic</th>
																			<th class="text-left text--text">Name</th>
																			<th class="text-right text--text">Progress</th>
																			<th class="text-right text--text">Average</th>
																			<th class="text-right text--text">Actions</th>
																		</tr>
																	</thead>
																	<tbody>
																		<tr v-for="(item, index) in users" :key="item.id">
																			<td>
																				{{ index + 1 }}
																			</td>
																			<td>
																				<v-avatar size="40" class="primary title white--text"
																					v-if="item.user.prof_pic == null">
																					{{ item.user.first_name[0] }}
																				</v-avatar>
																				<v-avatar size="40" class="primary title" v-else>
																					<img :src="path + '/storage/profile_pics/' + item.user.prof_pic"
																					>
																				</v-avatar>
																				
																			</td>
																			<td>{{ item.user.first_name }} {{ item.user.middle_name }} {{ item.user.last_name }}</td>
																			<td>
																				<div align="right" class="red--text" v-if="item.progress>=0 && item.progress<=33.33">
																					{{ item.progress | formatNumber }}%
																				</div>
																				<div align="right" class="orange--text" v-if="item.progress>33.33 && item.progress<=66.66">
																					{{ item.progress | formatNumber }}%
																				</div>
																				<div align="right" class="green--text" v-if="item.progress>66.66">
																					{{ item.progress | formatNumber }}%
																				</div>																				
																			</td>
																			<td>
																				<div align="right" class="red--text" v-if="item.average>=0 && item.average<=33.33">
																					{{ item.average | formatNumber }}%
																				</div>
																				<div align="right" class="orange--text" v-if="item.average>33.33 && item.average<=66.66">
																					{{ item.average | formatNumber }}%
																				</div>
																				<div align="right" class="green--text" v-if="item.average>66.66">
																					{{ item.average | formatNumber }}%
																				</div>	
																			</td>
																			<td>
																				<div align="right">
																					<v-tooltip top v-if="$can('user_group_archive')">
																						<template v-slot:activator="{ on, attrs }">
																							<v-btn icon class="button mr-1 error--text" @click="deleteUser(item)"
																								:loading="loading && userIndex == item.id" v-bind="attrs" v-on="on">
																								<v-icon small> mdi-delete </v-icon>
																							</v-btn>
																						</template>
																						<span>delete</span>
																					</v-tooltip>
																					
																					<v-tooltip top v-if="$can('log_view')">
																						<template v-slot:activator="{ on, attrs }">
																							<v-btn icon class="button mr-1 purple--text"
																								@click="viewLog(item)" v-bind="attrs"
																								v-on="on">
																								<v-icon small> mdi-script-text </v-icon>
																							</v-btn>
																						</template>
																						<span>Log</span>
																					</v-tooltip>
																				</div>
																			</td>
																		</tr>
																	</tbody>
																</template>
															</v-simple-table>
														</v-card>
													</div>
													<div class="hidden-md-and-up">
														<div>
															<v-layout column>
																<template v-for="(
                                                                    user, index
                                                                    ) in users">
																	<div :key="index">
																		<v-flex xs12 class="mb-2">
																			<v-card elevation="0" style="border-radius: 25px;">
																				<div class="pa-5 mt-2">
																					<v-layout column>
																						<v-flex xs12>
																							<v-layout row wrap>
																								<v-flex xs11>
																									<div class="mt-2">
																										<b>{{index+1}}. {{ user.user.first_name }} {{ user.user.middle_name }} {{ user.user.last_name }}</b>
																									</div>
																								</v-flex>
																								<v-flex xs1>
																									<v-btn depressed v-if="$can('user_group_archive')"
																										class="red--text" :loading="loading &&
																											userIndex ==
																											user.id
																											" icon @click="
																											deleteUser(
																												user
																											)
																											" small>
																										<v-icon>
																											mdi-delete
																										</v-icon>
																									</v-btn>
																								</v-flex>
																							</v-layout>
																						</v-flex>
																					</v-layout>
																				</div>
																			</v-card>
																		</v-flex>
																	</div>
																</template>
															</v-layout>
														</div>
													</div>
												</div>
											</v-flex>
										</v-card>
										<v-layout column class="mt-5">
											<v-flex xs12>
												<div align="left">
													<v-pagination v-if="length != 0" :length="judgeLength" total-visible="10" v-model="userGroupPagination.current_page
														" @input="changeUserGroupPage()" circle>
													</v-pagination>
												</div>
											</v-flex>
										</v-layout>
									</div>
									<div v-if="judgeView == 'create'">
										<v-flex class="mt-5">
											<v-card-title class="secondary text--text">
												<v-spacer></v-spacer>
												<div v-if="newUserGroup.id == null">
													Add Judge
												</div>
												<div v-else>Edit Judge</div>
												<v-spacer></v-spacer>
												<v-btn icon @click="
													changeUserView('default')
													">
													<v-icon class="text--text"> mdi-close </v-icon>
												</v-btn>
											</v-card-title>
											<v-card-text>
												<v-form v-model="valid" ref="judgeForm" lazy-validation>
													<v-layout row wrap class="mt-5">
														<v-flex xs12 md4>
															<div class="pa-3">
																<div>Judge<span class="error--text">*</span></div>
																<v-select :items="formData.judges" v-model="newUserGroup.user_id"
																	class="text_field background my-2" item-value="id" item-text="first_name" outlined dense
																	:rules="inputRules"></v-select>
															</div>
														</v-flex>
													</v-layout>
												</v-form>
											</v-card-text>
											<v-card-actions>
												<v-spacer></v-spacer>
												<v-btn depressed class="primary text-none mt-3" @click="saveUserGroup()" :disabled="!valid"
													:loading="loading">
													Save
													<v-icon right> mdi-content-save </v-icon>
												</v-btn>
											</v-card-actions>
										</v-flex>
									</div>
								</div>
							</div>
						</v-card-text>
					</v-card>
				</div>
			</v-layout>
		</v-container>
		<logData v-if="logDataModel" v-model="logDataModel" />
	</div>
</template>

<script>
import apiCall from "@/utils/api";
import { mapGetters, mapActions } from "vuex";
import logData from "@/views/accessControl/logData";
import Vue from "vue";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import uploadAdapter from '@/uploadAdapter.js';
import pdf from 'vue-pdf'

var numeral = require("numeral");
Vue.filter("formatNumber", function (value) {
	return numeral(value).format("0,0");
});

Vue.use(require("vue-moment"));

export default {
	components: {
		logData,
		pdf
	},
	data() {
		return {
			path: process.env.VUE_APP_API_URL,
			view: "default",
			loading: false,
			activateLoading: false,
			deactivateLoading: false,

			activeTab: 'submitted',
			activeGroupTab: null,
			homeTab: 'home',
			subTab: 'ventures',
			subLevelTab: 'principalFounder',
			applicantTab: null,
			ventureView: "default",
			judgeView: "default",

			docType: null,
			pdfViewDialog: false,

			editor: ClassicEditor,
			editorConfig: {
				extraPlugins: [function (editor) {
					editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
						return new uploadAdapter(loader);
					}
				}],
				language: 'en',
			},

			applicationDeadlineDatePicker: false,
			startDatePicker: false,
			endDatePicker: false,
			months: [
				"01",
				"02",
				"03",
				"04",
				"05",
				"06",
				"07",
				"08",
				"09",
				"10",
				"11",
				"12",
			],
			monthNames: [
				{ name: "January" },
				{ name: "February" },
				{ name: "March" },
				{ name: "April" },
				{ name: "May" },
				{ name: "June" },
				{ name: "July" },
				{ name: "August" },
				{ name: "September" },
				{ name: "October" },
				{ name: "November" },
				{ name: "December" },
			],

			valid: true,
			message: "",
			color: "",
			delete: false,
			activate: false,
			confirm: false,

			groupIndex: null,
			groupVentureIndex: null,
			ventureIndex: null,
			userIndex: null,

			snackbar: false,
			inputRules: [
				(v) => !!v || "Input is required"
			],
			searchTerm: null,
			groupVentureSearchTerm: null,
			userGroupSearchTerm: null,

			searchLoader: false,
			ventureLoader: false,
			userLoader: false,

			ventureSearchLoader: false,
			userSearchLoader: false,

			searchButtonDisabled: false,
			ventureSearchButtonDisabled: false,
			userSearchButtonDisabled: false,

			ventures: [],
			users: [],

			filteredGroups: false,
			filteredVentures: false,
			filteredUsers: false,

			openEnded: [],
			singleSelect: [],
			multiPartSingleAnswers: [],
			multiPartMultiAnswers: [],
			multiSelects: [],
			questions: [],

			ventureRating: [],

			newVentureRating:{
				responses: [],
				group_id: null,
				venture_id: null,
			},

			newGroup: {
				id: null,
				name: null,
				application_deadline: null,
				start_date: null,
				end_date: null,
			},
			newGroupVenture: {
				id: null,
				group_id: null,
				venture_id: null,
			},
			newUserGroup: {
				id: null,
				user_id: null,
				group_id: null,
			},

			assignedGroup: null,
			assignedApplication: null,
			formData: null,

			groupVenturePagination: {
				search: " ",
				current_page: 1,
				per_page: 0,
				total: 0,
				visible: 10,
			},
			userGroupPagination: {
				search: " ",
				current_page: 1,
				per_page: 0,
				total: 0,
				visible: 10,
			},

			logData: {
				message: null,
				model: "App\\Models\\Group",
			},
		};
	},
	created() {
		if (this.$can("group_view")) {
			this.startGroupLoader();
			this.fetchGroups(this.groupPagination.current_page);
			this.getFormData();

			this.getQuestions();
		}
	},
	methods: {
		...mapActions([
			"fetchGroups",
			"startGroupLoader",
			"stopGroupLoader",
			"filterGroups",

			"filterLogData",
			"openLogDataModel",
		]),
		enableSearch() {
			this.searchButtonDisabled = false;
		},
		enableVentureSearch() {
			this.ventureSearchButtonDisabled = false;
		},
		enableUserSearch() {
			this.userSearchButtonDisabled = false;
		},

		closeApplicationDeadlineDate() {
			this.applicationDeadlineDatePicker = false;
		},
		closeStartDate() {
			this.startDatePicker = false;
		},
		closeEndDate() {
			this.endDatePicker = false;
		},
		resetSearch() {
			this.searchTerm = null;
			this.filteredGroups = true;
			this.startGroupLoader();
			this.fetchGroups(1);
		},
		resetVentureSearch() {
			this.groupVentureSearchTerm = null;
			this.filteredVentures = false;
			this.ventureLoader = false;
			this.changeGroupVenturePage();
		},
		resetJudgeSearch() {
			this.userGroupSearchTerm = null;
			this.filteredUsers = false;
			this.userLoader = false;
			this.changeUserGroupPage();
		},

		showGroup(group) {
			this.assignedGroup = group;
			this.changeGroupVenturePage()
			this.changeUserGroupPage()
			this.changeView("show");
		},
		showApplication(application) {
			this.assignedApplication = application;
			this.getRatings()
			this.assignAnwers()
			this.changeVentureView("venture");

		},
		assignAnwers() {				
			this.openEnded = this.assignedApplication.answers[0];
			this.singleSelect = this.assignedApplication.answers[1];
			this.multiPartSingleAnswers = this.assignedApplication.answers[2];
			this.multiPartMultiAnswers = this.assignedApplication.answers[3];
			this.multiSelects = this.assignedApplication.answers[4];
		},

		viewDocument(doctype){
			this.docType = doctype
			this.pdfViewDialog = true
		},

		editGroup(group) {
			this.newGroup.id = group.id;
			this.newGroup.name = group.name;
			this.newGroup.application_deadline = group.application_deadline;
			this.newGroup.group = group.group;
			this.newGroup.start_date = group.start_date;
			this.newGroup.end_date = group.end_date;

			this.changeView("create");
		},
		editVenture(venture) {
			this.newGroupVenture.id = venture.id;
			this.newGroupVenture.group_id = venture.group_id;
			this.newGroupVenture.venture_id = venture.venture_id;

			console.log("this.newGroupVenture", this.newGroupVenture)

			this.changeVentureView("create");
		},

		viewLog(data) {
			this.logData.message = data.id;
			this.filterLogData(this.logData);
			this.openLogDataModel();
		},

		getQuestions() {
			apiCall({
				url: "/api/question?type=public",
				method: "GET",
			})
				.then((resp) => {
					this.questions = resp;
				})
				.catch((error) => {
					this.message = "An Error Occurred; Couldn't Get Form Data";
					this.color = "error";
				});
		},

		save() {
			if (this.$refs.form.validate()) {
				if (this.newGroup.id != null) {
					confirm("Are You Sure You Want to Update Group") && (this.confirm = true);
					if (this.confirm) {
						this.loading = true;
						apiCall({
							url: "/api/group/" + this.newGroup.id,
							data: this.newGroup,
							method: "PUT",
						})
							.then((resp) => {
								this.message = "Group Updated Successfully";
								this.color = "orange";
								this.loading = false;
								this.snackbar = true;
								this.confirm = false;
								this.changeView("default");
								this.fetchGroups(this.groupPagination.current_page);
							})
							.catch((error) => {
								this.message = "An Error Occurred";
								this.color = "error";
								this.loading = false;
								this.snackbar = true;
							});
					}
				} else {
					confirm("Are You Sure You Want to Create Group?") && (this.confirm = true);
					if (this.confirm) {
						this.loading = true;
						apiCall({
							url: "/api/group",
							data: this.newGroup,
							method: "POST",
						})
							.then((resp) => {
								if (resp.status == 409) {
									this.message = resp.message
									this.color = "error";
									this.loading = false;
									this.snackbar = true;
									this.alertType = "error";
								} else {
									this.message = "Group Added Successfully";
									this.color = "success";
									this.loading = false;
									this.snackbar = true;
									this.changeView("default");
									this.fetchGroups(1);
								}
							})
							.catch((error) => {
								this.message = "An Error Occurred Email";
								this.color = "error";
								this.loading = false;
								this.snackbar = true;
								this.changeView("default");
								this.fetchGroups(1);
							});
					}
				}
			}
		},
		getRatings(){
			apiCall({
				url: "/api/venture-rating?venture_id="+this.assignedApplication.venture.id,
				method: "GET",
			})
				.then((resp) => {
					this.ventureRating = resp
				})
				.catch((error) => {
					
				});
		},
		saveGroupVenture() {
			if (this.$refs.ventureForm.validate()) {
				if (this.newGroupVenture.id != null) {
					confirm("Are you sure you want to update Venture?") &&
						(this.confirm = true);
					if (this.confirm) {
						this.loading = true;
						apiCall({
							url: "/api/group-venture/" + this.newGroupVenture.id,
							data: this.newGroupVenture,
							method: "PUT",
						})
							.then((resp) => {
								this.message = "Venture Updated Successfully";
								this.color = "orange";
								this.loading = false;
								this.snackbar = true;
								this.confirm = false;
								this.changeVentureView("default");
								this.changeGroupVenturePage(1);
							})
							.catch((error) => {
								this.message = "An Error Occurred";
								this.color = "error";
								this.loading = false;
								this.snackbar = true;
							});
					}
				} else {
					confirm("Are you sure you want to add Venture?") &&
						(this.confirm = true);
					if (this.confirm) {
						this.newGroupVenture.group_id = this.assignedGroup.id
						this.loading = true;
						apiCall({
							url: "/api/group-venture",
							data: this.newGroupVenture,
							method: "POST",
						})
							.then((resp) => {
								this.message = "Venture Added Successfully";
								this.color = "success";
								this.loading = false;
								this.snackbar = true;
								this.confirm = false;
								this.changeVentureView("default");
								this.changeGroupVenturePage();
							})
							.catch((error) => {
								this.message = "An Error Occurred";
								this.color = "error";
								this.loading = false;
								this.snackbar = true;
							});
					}
				}
			}
		},
		saveUserGroup() {
			if (this.$refs.judgeForm.validate()) {
				if (this.newUserGroup.id != null) {
					confirm("Are you sure you want to update Judge?") &&
						(this.confirm = true);
					if (this.confirm) {
						this.loading = true;
						apiCall({
							url: "/api/user-group/" + this.newUserGroup.id,
							data: this.newUserGroup,
							method: "PUT",
						})
							.then((resp) => {
								this.message = "Judge Updated Successfully";
								this.color = "orange";
								this.loading = false;
								this.snackbar = true;
								this.confirm = false;
								this.changeUserView("default");
								this.changeUserGroupPage(1);
							})
							.catch((error) => {
								this.message = "An Error Occurred";
								this.color = "error";
								this.loading = false;
								this.snackbar = true;
							});
					}
				} else {
					confirm("Are you sure you want to add Judge?") &&
						(this.confirm = true);
					if (this.confirm) {
						this.newUserGroup.group_id = this.assignedGroup.id
						this.loading = true;
						apiCall({
							url: "/api/user-group",
							data: this.newUserGroup,
							method: "POST",
						})
							.then((resp) => {
								this.message = "Judge Added Successfully";
								this.color = "success";
								this.loading = false;
								this.snackbar = true;
								this.confirm = false;
								this.changeUserView("default");
								this.changeUserGroupPage();
							})
							.catch((error) => {
								this.message = "An Error Occurred";
								this.color = "error";
								this.loading = false;
								this.snackbar = true;
							});
					}
				}
			}
		},

		saveRatings(){
			confirm("Are you sure you want to Submit your Rating?") && (this.confirm = true);
				if (this.confirm) {
					this.newVentureRating.responses = this.ventureRating
					this.newVentureRating.group_id = this.assignedGroup.id
					this.newVentureRating.venture_id = this.assignedApplication.venture.id

					this.loading = true;					
					apiCall({ url: "/api/venture-rating", data: this.newVentureRating, method: "POST" })
						.then((resp) => {
						window.scrollTo(0, 0);
						this.message = resp.message;
						this.color = "green";
						this.loading = false;
						this.snackbar = true;
						this.confirm = false;

						this.changeGroupVenturePage()

						this.changeVentureView('default')
						this.subLevelTab = 'principalFounder'
						this.subTab = 'ventures'
						})
						.catch((error) => {
							this.message = "An error occurred.";
							this.color = "error";
							this.loading = false;
							this.snackbar = true;
						});
				}
		},

		search() {
			if (this.searchTerm == null) {
				this.searchButtonDisabled = true;
			} else {
				this.searchLoader = true;
				this.searchButtonDisabled = false;
				this.startGroupLoader();
				apiCall({
					url: "/api/group?type=search&search=" + this.searchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.filterGroups(resp);
						this.searchLoader = false;
						this.stopGroupLoader();
						this.filteredGroups = true;
					})
					.catch((error) => {
						console.log(error.response);
						this.searchLoader = false;
						this.stopGroupLoader();
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			}
		},
		ventureSearch() {
			if (this.groupVentureSearchTerm == null) {
				this.ventureSearchButtonDisabled = true;
			} else {
				this.ventureSearchLoader = true;
				this.ventureSearchButtonDisabled = false;
				this.startPartLoader();
				apiCall({
					url:
						"/api/learning-area-part?type=search&search=" +
						this.groupVentureSearchTerm +
						"&part_id=" +
						this.assignedGroup.id,
					method: "GET",
				})
					.then((resp) => {
						this.ventures = resp.data;
						this.groupVenturePagination.current_page =
							resp.current_page;
						this.groupVenturePagination.total = resp.total;
						this.groupVenturePagination.per_page = resp.per_page;

						this.ventureSearchLoader = false;
						this.filteredVentures = true;
					})
					.catch((error) => {
						this.ventureSearchLoader = false;
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						console.log(error);
					});
			}
		},
		userSearch() {
			if (this.userGroupSearchTerm == null) {
				this.userSearchButtonDisabled = true;
			} else {
				this.userSearchLoader = true;
				this.userSearchButtonDisabled = false;
				this.startPartLoader();
				apiCall({
					url:
						"/api/user-group?type=search&search=" +
						this.userGroupSearchTerm +
						"&group_id=" +
						this.assignedGroup.id,
					method: "GET",
				})
					.then((resp) => {
						this.users = resp.data;
						this.userGroupPagination.current_page =
							resp.current_page;
						this.userGroupPagination.total = resp.total;
						this.userGroupPagination.per_page = resp.per_page;

						this.userSearchLoader = false;
						this.filteredVentures = true;
					})
					.catch((error) => {
						this.userSearchLoader = false;
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						console.log(error);
					});
			}
		},

		changeView(view) {
			if (view == "default") {
				this.lockEmail = false;
				this.newGroup.id = null;
				this.newGroup.name = null;
				this.newGroup.application_deadline = null;
				this.newGroup.group = null;
				this.newGroup.start_date = null;
				this.newGroup.end_date = null;

				this.changeVentureView('default')
			} else if (view == "create") {
				this.getFormData();
			} else if (view == "show") {

			}
			this.view = view;
		},
		changeVentureView(view) {
			if (view == "default") {
				this.subLevelTab = 'principalFounder'
				this.applicantTab = 0;
				this.newGroupVenture.id = null;
				this.newGroupVenture.group_id = null;
				this.newGroupVenture.venture_id = null;
			} else if (view == "create") {
			} else if (view == "show") {
			}
			this.ventureView = view;
		},
		changeUserView(view) {
			if (view == "default") {
				this.newUserGroup.id = null;
				this.newUserGroup.user_id = null;
				this.newUserGroup.group_id = null;
			} else if (view == "create") {
			} else if (view == "show") {
			}
			this.judgeView = view;
		},

		changePage() {
			this.startGroupLoader();
			if (this.filteredGroups == true) {
				this.searchButtonDisabled = false;
				apiCall({
					url:
						"/api/group?page=" +
						this.groupPagination.current_page +
						"&type=search&search=" +
						this.searchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.filterGroups(resp);
						this.stopGroupLoader();
					})
					.catch((error) => {
						this.stopGroupLoader();
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			} else {
				this.fetchGroups(this.groupPagination.current_page);
			}
		},
		changeGroupVenturePage() {
			this.ventureLoader = true;
			if (this.filteredVentures == true) {
				this.ventureSearchButtonDisabled = false;
				apiCall({
					url:
						"/api/group-venture?group_id=" +
						this.assignedGroup.id +
						"&page=" +
						this.groupVenturePagination.current_page +
						"&type=search&search=" +
						this.groupVentureSearchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.ventureLoader = false;

						this.ventures = resp.data;
						this.groupVenturePagination.current_page =
							resp.current_page;
						this.groupVenturePagination.total = resp.total;
						this.groupVenturePagination.per_page = resp.per_page;
					})
					.catch((error) => {
						console.log(error);
						this.ventureLoader = false;
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			} else {
				apiCall({
					url:
						"/api/group-venture?group_id=" +
						this.assignedGroup.id +
						"&page=" +
						this.groupVenturePagination.current_page,
					method: "GET",
				})
					.then((resp) => {
						this.ventureLoader = false;

						this.ventures = resp.data;
						this.groupVenturePagination.current_page =
							resp.current_page;
						this.groupVenturePagination.total = resp.total;
						this.groupVenturePagination.per_page = resp.per_page;
					})
					.catch((error) => {
						console.log(error);
						this.ventureLoader = false;
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			}
		},
		changeUserGroupPage() {
			this.userLoader = true;
			if (this.filteredUsers == true) {
				this.userSearchButtonDisabled = false;
				apiCall({
					url:
						"/api/user-group?group_id=" +
						this.assignedGroup.id +
						"&page=" +
						this.userGroupPagination.current_page +
						"&type=search&search=" +
						this.userGroupSearchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.userLoader = false;

						this.users = resp.data;
						this.userGroupPagination.current_page =
							resp.current_page;
						this.userGroupPagination.total = resp.total;
						this.userGroupPagination.per_page = resp.per_page;
					})
					.catch((error) => {
						console.log(error);
						this.userLoader = false;
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			} else {
				apiCall({
					url:
						"/api/user-group?group_id=" +
						this.assignedGroup.id +
						"&page=" +
						this.userGroupPagination.current_page,
					method: "GET",
				})
					.then((resp) => {
						this.userLoader = false;

						this.users = resp.data;
						this.userGroupPagination.current_page =
							resp.current_page;
						this.userGroupPagination.total = resp.total;
						this.userGroupPagination.per_page = resp.per_page;
					})
					.catch((error) => {
						// console.log(error);
						// this.userLoader = false;
						// this.message = "An Error Occurred";
						// this.color = "error";
						// this.snackbar = true;
					});
			}
		},

		deleteGroup(item) {
			confirm("Are You Sure You Want to Delete Group?") && (this.delete = true);
			if (this.delete) {
				this.loading = true;
				this.groupIndex = item.id;
				apiCall({ url: "/api/group/" + item.id, method: "DELETE" })
					.then((resp) => {
						this.message = "Group Deleted Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.groupIndex = null;
						this.fetchGroups(this.groupPagination.current_page);
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.groupIndex = null;
						console.log(error.response);
					});
			}
		},
		deleteGroupVenture(item) {
			confirm("Are You Sure You Want to Remove Venture from Group?") && (this.delete = true);
			if (this.delete) {
				this.loading = true;
				this.groupVentureIndex = item.id;
				apiCall({ url: "/api/group-venture/" + item.id, method: "DELETE" })
					.then((resp) => {
						this.message = "Venture Removed Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.groupVentureIndex = null;
						this.changeGroupVenturePage(this.groupVenturePagination.current_page);
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.groupVentureIndex = null;
						console.log(error.response);
					});
			}
		},
		deleteVenture(item) {
			confirm("Are You Sure You Want to Remove Venture?") &&
				(this.delete = true);
			if (this.delete) {
				this.loading = true;
				this.ventureIndex = item.id;
				apiCall({
					url: "/api/learning-area-part/" + item.id,
					method: "DELETE",
				})
					.then((resp) => {
						this.message = "Venture Deleted Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.ventureIndex = null;
						this.changeGroupVenturePage();
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.ventureIndex = null;
						console.log(error);
					});
			}
		},
		deleteUser(item) {
			confirm("Are You Sure You Want to Remove Judge?") &&
				(this.delete = true);
			if (this.delete) {
				this.loading = true;
				this.userIndex = item.id;
				apiCall({
					url: "/api/user-group/" + item.id,
					method: "DELETE",
				})
					.then((resp) => {
						this.message = "Judge Removed Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.userIndex = null;
						this.changeUserGroupPage();
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.userIndex = null;
						console.log(error);
					});
			}
		},

		getFormData() {
			apiCall({
				url: "/api/group?type=formData",
				method: "GET",
			})
				.then((resp) => {
					this.formData = resp;
				})
				.catch((error) => {
					this.message = "An Error Occurred; Couldn't Get Form Data";
					this.color = "error";
				});
		},
	},
	computed: {
		...mapGetters(["getProfile", "groups", "groupPagination", "groupLoader", "logDataModel"]),

		formatApplicationDeadlineDate() {
			if (this.newGroup.application_deadline != null) {
				const d = new Date(this.newGroup.application_deadline);
				const year = d.getFullYear();
				const date = d.getDate();
				const month = d.getMonth();

				return date + " " + this.monthNames[month].name + " " + year;
			}
		},
		formatStartDate() {
			if (this.newGroup.start_date != null) {
				const d = new Date(this.newGroup.start_date);
				const year = d.getFullYear();
				const date = d.getDate();
				const month = d.getMonth();

				return date + " " + this.monthNames[month].name + " " + year;
			}
		},
		formatEndDate() {
			if (this.newGroup.end_date != null) {
				const d = new Date(this.newGroup.end_date);
				const year = d.getFullYear();
				const date = d.getDate();
				const month = d.getMonth();

				return date + " " + this.monthNames[month].name + " " + year;
			}
		},
		dateToday() {
			var today = new Date();

			var timestamp =
				today.getFullYear() +
				"-" +
				this.months[today.getMonth()] +
				"-" +
				("0" + today.getDate()).slice(-2);
			return timestamp;
		},

		length: function () {
			return Math.ceil(
				this.groupPagination.total / this.groupPagination.per_page
			);
		},
		ventureLength: function () {
			return Math.ceil(
				this.groupVenturePagination.total /
				this.groupVenturePagination.per_page
			);
		},
		judgeLength: function () {
			return Math.ceil(
				this.userGroupPagination.total /
				this.userGroupPagination.per_page
			);
		},
	},
};
</script>