<template>
  <div class="welcome">
    <v-layout column>
      <v-flex xs12>
        <div class="hidden-md-and-up" style="overflow: hidden;">
          <v-row align="center" justify="center">
            <v-card elevation="0">
              <v-row justify="center">
                <div style="overflow: hidden">
                  <video poster="img/poster.jpg" src="vid/vid3.mp4" height="1000" muted loop autoplay style="
                      margin-top: -10.5%;
                      margin-bottom: 1.5%;
                      margin-left: -650px;
                      z-index: -500;
                    "></video>
                </div>
                <v-overlay :absolute="absolute" :value="overlay" :opacity="opacity" style="z-index: 2">
                  <div class="display-2 font-weight-black ml-14" style="font-family: 'SC Prosper Sans Regular';">
                    Because She Can</div>
                  <div style="margin-top: -50px" class="ml-6">
                    <v-img width="30px" src="img/a-1.png">
                    </v-img>
                  </div>
                  <div align="right" style="margin-top: -16px" class="mr-6">
                    <v-img width="30px" src="img/a-2.png">
                    </v-img>
                  </div>
                </v-overlay>
              </v-row>
            </v-card>
          </v-row>
        </div>
        <div class="hidden-sm-and-down" style="margin-top: -100px">
          <v-row align="center" justify="center">
            <v-card elevation="0">
              <v-row justify="center">
                <video poster="img/poster.jpg" width="100%" src="vid/vid3.mp4" muted loop autoplay
                  style="z-index: 1"></video>

                <v-overlay :absolute="absolute" :value="overlay" :opacity="opacity" style="z-index: 2;">

                  <div class="display-4 font-weight-black ml-7 mr-7" style="font-family: 'SC Prosper Sans Regular';">                    
                    Because She Can
                  </div>
                  <div style="margin-top: -50px">
                    <v-img width="30px" src="img/a-1.png">
                    </v-img>
                  </div>
                  <div align="right" style="margin-top: -16px">
                    <v-img width="30px" src="img/a-2.png">
                    </v-img>
                  </div>
                </v-overlay>
              </v-row>
            </v-card>
          </v-row>
        </div>
      </v-flex>
      <v-flex xs12 class="mt-3 primary" style="margin-top: -100px; z-index: 3;">
        <div align="center" class="white--text headline font-weight-black my-10"
          style="font-family: 'SC Prosper Sans Regular';">
          <i>Growing Women-led Tech Startups</i>
        </div>
      </v-flex>
      <v-flex xs12 class="white">
        <v-container class="my-16">
          <v-layout column class="my-16">
            <v-flex xs12 class="my-16">
              <div class="display-2 grey--text" align="center" style="font-family: 'SC Prosper Sans Regular';">
                About Standard Chartered Women in Technology (WIT)
              </div>
              <div align="center" class="mt-10">
                The Standard Chartered Women in Technology Incubator Kenya is Africa’s leading incubator programme for
                female founded businesses, aligning with calls for more diversity in technology, entrepreneurship and for
                more opportunities for women to develop entrepreneurial and leadership excellence. The program is an
                initiative of Standard Chartered in partnership with @iBizAfrica Centre, Strathmore University.
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
      <v-flex xs12 class="secondary">
        <div align="center" class="white--text title font-weight-black mt-10"
          style="font-family: 'SC Prosper Sans Regular';">
          Standard Chartered Women In Tech Incubator Program COHORT 7 Open
        </div>
        <div align="center" class="mb-10">
            <v-btn depressed class="mt-5 font-weight-black" router to="/cohort-7">
              APPLY NOW!
              <v-icon right>mdi-file-document-plus</v-icon>
            </v-btn>
        </div>
      </v-flex>
      <v-flex xs12 class="background">
        <v-container class="my-16">
          <div align="center" class="display-2 primary--text font weight-black mt-16 mb-5">
            What You Get
          </div>
          <div class="mt-10 mb-16">
            <v-layout row wrap class="mb-16">
              <v-flex xs12 md4>
                <v-card elevation="0" class="primary mx-2 my-1" shaped height="500">
                  <v-card-text>
                    <v-layout column class="my-10">
                      <v-flex xs12>
                        <div align="center">
                          <v-icon size="150px" class="white--text">
                            mdi-human-male-board-poll
                          </v-icon>
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div align="center" class="white--text mt-5 title"
                          style="font-family: 'SC Prosper Sans Regular';">
                          IMMERSIVE LEARNING
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div align="center" class="white--text mt-5">
                          Entrepreneurs undergo an in depth evaluation of the current state of their company
                          operation.this informs the methodology of curriculum delivery meeting unique needs of each
                          venture
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 md4>
                <v-card elevation="0" class="secondary mx-2 my-1" shaped height="500">
                  <v-card-text>
                    <v-layout column class="my-10">
                      <v-flex xs12>
                        <div align="center">
                          <v-icon size="150px" class="white--text">
                            mdi-account-child
                          </v-icon>
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div align="center" class="white--text mt-5 title"
                          style="font-family: 'SC Prosper Sans Regular';">
                          MENTORSHIP AND COACHING
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div align="center" class="white--text mt-5">
                          In-house mentor coaches walk the incubation journey by facilitating customized mentorship and
                          coaching sessions addressing each Companys needs
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 md4>
                <v-card elevation="0" class="primary mx-2 my-1" shaped height="500">
                  <v-card-text>
                    <v-layout column class="my-10">
                      <v-flex xs12>
                        <div align="center">
                          <v-icon size="150px" class="white--text">
                            mdi-cash-100
                          </v-icon>
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div align="center" class="white--text mt-5 title"
                          style="font-family: 'SC Prosper Sans Regular';">
                          ACCESS TO SEED FUNDING
                        </div>
                      </v-flex>
                      <v-flex xs12>
                        <div align="center" class="white--text mt-5">
                          The top 7 Startups will have the opportunity to secure $10,000 in seed funding to scale their ventures
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </div>
          <div align="center" class="my-10">
            <v-btn outlined class="text-none secondary--text" router to="/about">
              View More
              <v-icon right>mdi-information-variant</v-icon>
            </v-btn>
          </div>
        </v-container>
      </v-flex>
      <v-flex xs12>
        <v-parallax dark height="800" src="img/image.jpg">
          <!-- <div align="center" class="font-weight-black headline mt-16">
            Women In Tech
          </div>
          <div align="center">
            Cohort 6 Incubation Timeline
          </div> -->
          <div align="center" class="my-16">
            <v-img width="800px" src="img/incubation-timeline-1-3.png">
            </v-img>
          </div>
          <div align="center" class="mb-16">
            <v-img width="300px" src="img/women-in-tech-process.png">
            </v-img>
          </div>

        </v-parallax>
      </v-flex>

      <v-flex xs12 class="primary">
        <v-container>
          <div align="center" class="display-2 white--text font weight-black mt-16 mb-5">
            News Feed
          </div>
          <div class="mt-13">
            <v-flex xs12>
                <v-layout row wrap>
                    <v-flex xs5>

                    </v-flex>
                    <v-flex xs2>
                        <v-divider class="white mx-10"></v-divider>
                        <v-divider class="white"></v-divider>
                        <v-divider class="white mx-10"></v-divider>
                    </v-flex>
                    <v-flex xs5>

                    </v-flex>
                </v-layout>
            </v-flex>
          </div>
          <div v-if="publicNewsFeeds.length != 0">
            <v-carousel cycle height="625" hide-delimiter-background show-arrows-on-hover class="my-16">
              <v-carousel-item>
                <div class="mx-2 my-5">
                  <v-layout row wrap>
                    <template v-for="(publicNewsFeed, index) in publicNewsFeedArray[0]">
                      <v-flex xs12 md3>
                        <v-card class="d-flex flex-column mx-1 mt-2" color="grey lighten-4" max-width="600" elevation="0"
                          height="550" style="border-radius: 20px 20px 20px 20px;">
                          <v-img max-height="280" contain :src="path + '/storage/news_feed_pics/' + publicNewsFeed.image" class="blue lighten-5" />
                          <v-card-text class="pt-6" style="position: relative;">
                            <div class="font-weight-light mb-2" align="center">

                              <a style="text-decoration: none" class="primary--text" target="_blank"
                                :href="publicNewsFeed.source">
                                {{ publicNewsFeed.subject }}
                              </a>


                            </div>
                            <v-divider class="my-5 secondary"></v-divider>
                            <div class="font-weight-light mb-2" align="center">
                              <v-clamp autoresize :max-lines="4">
                                {{ removeTags(publicNewsFeed.body) }}
                              </v-clamp>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-flex>
                    </template>                  
                  </v-layout>
                </div>
              </v-carousel-item>
              <v-carousel-item>
                <div class="mx-2 my-5">
                  <v-layout row wrap>
                    <template v-for="(publicNewsFeed, index) in publicNewsFeedArray[1]">
                      <v-flex xs12 md3>
                        <v-card class="d-flex flex-column mx-1 mt-2" color="grey lighten-4" max-width="600" elevation="0"
                          height="550" style="border-radius: 20px 20px 20px 20px;">
                          <v-img max-height="280" contain :src="path + '/storage/news_feed_pics/' + publicNewsFeed.image" class="blue lighten-5" />
                          <v-card-text class="pt-6" style="position: relative;">
                            <div class="font-weight-light mb-2" align="center">

                              <a style="text-decoration: none" class="primary--text" target="_blank"
                                :href="publicNewsFeed.source">
                                {{ publicNewsFeed.subject }}
                              </a>


                            </div>
                            <v-divider class="my-5 secondary"></v-divider>
                            <div class="font-weight-light mb-2" align="center">
                              <v-clamp autoresize :max-lines="4">
                                {{ removeTags(publicNewsFeed.body) }}
                              </v-clamp>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-flex>
                    </template>
            
                  </v-layout>
                </div>
              </v-carousel-item>
              <v-carousel-item>
                <div class="mx-2 my-5">
                  <v-layout row wrap>
                    <template v-for="(publicNewsFeed, index) in publicNewsFeedArray[2]">
                      <v-flex xs12 md3>
                        <v-card class="d-flex flex-column mx-1 mt-2" color="grey lighten-4" max-width="600" elevation="0"
                          height="550" style="border-radius: 20px 20px 20px 20px;">
                          <v-img max-height="280" contain :src="path + '/storage/news_feed_pics/' + publicNewsFeed.image" class="blue lighten-5" />
                          <v-card-text class="pt-6" style="position: relative;">
                            <div class="font-weight-light mb-2" align="center">

                              <a style="text-decoration: none" class="primary--text" target="_blank"
                                :href="publicNewsFeed.source">
                                {{ publicNewsFeed.subject }}
                              </a>


                            </div>
                            <v-divider class="my-5 secondary"></v-divider>
                            <div class="font-weight-light mb-2" align="center">
                              <v-clamp autoresize :max-lines="4">
                                {{ removeTags(publicNewsFeed.body) }}
                              </v-clamp>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-flex>
                    </template>
                  </v-layout>
                </div>
              </v-carousel-item>
            </v-carousel>
          </div>
          <div v-else>
            <div class="mt-10 mb-5">
              <v-layout row wrap>
                <v-flex xs12 md3>
                  <div class="mx-1">
                    <v-skeleton-loader
                      v-bind="attrs"
                      type="image, article"
                    ></v-skeleton-loader>
                  </div>
                </v-flex>
                <v-flex xs12 md3>
                  <div class="mx-1">
                    <v-skeleton-loader
                      v-bind="attrs"
                      type="image, article"
                    ></v-skeleton-loader>
                  </div>
                </v-flex>
                <v-flex xs12 md3>
                  <div class="mx-1">
                    <v-skeleton-loader
                      v-bind="attrs"
                      type="image, article"
                    ></v-skeleton-loader>
                  </div>
                </v-flex>
                <v-flex xs12 md3>
                  <div class="mx-1">
                    <v-skeleton-loader
                      v-bind="attrs"
                      type="image, article"
                    ></v-skeleton-loader>
                  </div>
                </v-flex>
              </v-layout>
            </div>
          </div>
          <div align="center" class="my-10">
            <v-btn depressed class="text-none white primary--text" router to="/news-feed">
              View More
              <v-icon right>mdi-page-next</v-icon>
            </v-btn>
          </div>

        </v-container>
      </v-flex>



    </v-layout>
  </div>
</template>
<style>
/*option 1:this makes the video responsive*/
.videoDiv {
  width: 70%;
  /*or whatever % you prefer*/
  margin: 0 auto;
  display: block;
}

/* option 2* does not make the video responsive*/

.videoDiv {
  margin: 0 auto;
  display: block;
}
</style>
<script>
import apiCall from "@/utils/api";
import { mapState, mapGetters, mapActions } from "vuex";
import Vue from "vue";
import VClamp from "vue-clamp";

export default {
  components: {
    VClamp
  },
  data() {
    return {
      path: process.env.VUE_APP_API_URL,
      color: "",
      message: "",
      snackbar: false,
      drawer: false,

      absolute: true,
      overlay: true,
      opacity: 0.3,

      originalArray: [],
			publicNewsFeedArray: [],
    };
  },
  watch: {
		publicNewsFeeds() {
			this.chunkPublicNewsFeeds();
		},
	},
  mounted() {
    window.onscroll = () => {
      this.changeColor();
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.changeHeaderColor("transparent");
    this.changeTitleColor("red--text");
    this.changeButtonColor("red--text hidden-sm-and-down text-none caption");

    this.fetchPublicNewsFeeds(this.publicNewsFeedPagination.current_page)

    if(this.publicNewsFeeds.length!=0){
			this.chunkPublicNewsFeeds()
		}

  },
  methods: {
    ...mapActions([
      "changeHeaderColor",
      "changeTitleColor",
      "changeButtonColor",

      "fetchPublicNewsFeeds"
    ]),
    changeColor() {
        if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
          this.changeHeaderColor("white");
          this.changeTitleColor("black--text");
          this.changeButtonColor("black--text hidden-sm-and-down text-none caption");
        } else {
          this.changeHeaderColor("transparent");
          this.changeTitleColor("white--text");
          this.changeButtonColor("white--text hidden-sm-and-down text-none caption");
        }
    },
    removeTags(str) {
        if ((str===null) || (str===''))
            return false;
        else
            str = str.toString();
        return str.replace( /(<([^>]+)>)/ig, '');
    },
    chunkPublicNewsFeeds() {
			this.publicNewsFeedArray = [];

			for (var i = 0; i < this.publicNewsFeeds.length; i++) {
				this.originalArray.push(this.publicNewsFeeds[i])
			}

			let result = [];
			for (let i = 3; i > 0; i--) {
				result.push(this.originalArray.splice(0, Math.ceil(this.originalArray.length / i)));
			}
			this.publicNewsFeedArray = result;
		},

  },
  computed: {
    ...mapGetters(["darkState", "publicNewsFeeds", "publicNewsFeedPagination"]),
  },
};
</script>
