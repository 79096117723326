<template>
	<div class="teams" v-if="$can('team_view')">
		<v-dialog transition="dialog-bottom-transition" max-width="600" v-model="imageDialog">
			<v-card max-width="600">
				<v-toolbar flat>
					Upload Image
					<v-spacer></v-spacer>
					<v-btn icon @click="imageDialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar>

				<v-form ref="imageForm" v-model="imageValid" lazy-validation>
					<v-layout column>
						<v-flex xs12>
							<v-file-input class="mx-2 mt-3" :loading="imageLoading" :rules="fileRules" filled dense show-size chips
								accept="image/*" v-model="imageFile" label="Select Image"></v-file-input>
						</v-flex>
						<v-flex xs12>
							<div align="right" class="mb-3 mx-3">
								<v-btn depressed block class="primary text-none" @click="uploadImage" :loading="imageLoading"
									:disabled="!imageValid">
									Submit <v-icon right dark>mdi-upload</v-icon>
								</v-btn>
							</div>
						</v-flex>
					</v-layout>
				</v-form>
			</v-card>
		</v-dialog>
		<v-snackbar v-model="snackbar" :timeout="4000" bottom right :color="color">
			<span>{{ message }}</span>
		</v-snackbar>
		<v-container>
			<v-layout column>
				<div v-if="view == 'default'">
					<v-card outlined class="mt-5 pa-3" style="border-radius: 25px;">
						<v-flex xs12 class="mt-10 mb-2">
							<div class="mx-5">
								<v-layout row wrap>
									<v-flex xs12 md4>
										<h1>Team Members</h1>
									</v-flex>
									<v-flex xs12 md4>
										<div class="pa-1">
											<v-text-field class="text_field header" outlined dense label="Search"
												append-icon="mdi-undo-variant" @click:append="resetSearch()" v-on:keyup.enter="search"
												v-model="searchTerm" @input="enableSearch()"></v-text-field>
										</div>

									</v-flex>
									<v-flex xs12 md2>
										<div class="pa-1">
											<v-btn class="secondary white--text text-none" depressed block @click="search"
												:loading="searchLoader" :disabled="searchButtonDisabled">
												Search
												<v-icon right>mdi-magnify</v-icon>
											</v-btn>
										</div>
									</v-flex>
									<v-flex xs12 md2>
										<div class="pa-1">
											<v-btn block depressed class="primary text-none" @click="changeView('create')"
												v-if="$can('team_create')">
												Add Team
												<v-icon right>mdi-plus-circle-outline</v-icon>
											</v-btn>
										</div>
									</v-flex>
								</v-layout>
							</div>
						</v-flex>
						<div class="mx-5 mt-9">
							<v-progress-linear v-if="teamLoader" height="1" indeterminate color="primary"></v-progress-linear>
						</div>
						<v-divider class="mx-5"></v-divider>
						<v-flex xs12 class="mt-5">
							<div v-if="teams.length == 0">
								<v-card elevation="0">
									<v-layout row wrap>
										<v-flex xs12 md1>
											<v-container fill-height fluid>
												<v-row align="center" justify="center">
													<v-col class="text-center">
														<v-icon large class="text--text">
															mdi-alert-circle-outline
														</v-icon>
													</v-col>
												</v-row>
											</v-container>
										</v-flex>
										<v-flex xs12 md11>
											<v-container fill-height fluid>
												<v-row align="center" justify="center">
													<v-col class="text-center"> No Team Member Found </v-col>
												</v-row>
											</v-container>
										</v-flex>
									</v-layout>
								</v-card>
							</div>
							<div v-else>
								<div class="hidden-sm-and-down">
									<v-card elevation="0" class="pa-2">
										<v-simple-table>
											<template v-slot:default>
												<thead class="header">
													<tr class="tableHeader">
														<th class="text-left text--text">#</th>
														<th class="text-left text--text">Image</th>
														<th class="text-left text--text">Name</th>
														<th class="text-left text--text">Role</th>
														<th class="text-left text--text">Active</th>
														<th class="text-left text--text">Date Added</th>
														<th class="text-right text--text">Actions</th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="(item, index) in teams" :key="item.id">
														<td>
															{{ index + 1 }}
														</td>
														<td>
															<v-avatar size="40" class="primary title white--text"
																v-if="item.image == null">
																{{ item.name[0] }}
															</v-avatar>
															<v-avatar size="40" class="primary title" v-else>
																<img :src="path + '/storage/team_pics/' + item.image"
																>
															</v-avatar>
														</td>
														<td>{{ item.name }} </td>
														<td>
															<template v-for="(team_member_role, index) in item.team_member_roles">
																<div :key="index">
																	<v-flex xs12>
																		{{index+1}}. {{ team_member_role.team_role.name }}
																	</v-flex>
																</div>
															</template>															
														</td>
														<td>
															<div v-if="item.active == 1">Yes</div>
															<div v-else>No</div>
														</td>
														<td>
															{{
		item.created_at | moment("DD/MM/YYYY - hh:mm a")
	}}
														</td>
														<td>
															<div align="right">
																<v-tooltip top v-if="$can('team_view')">
																	<template v-slot:activator="{ on, attrs }">
																		<v-btn icon class="button mr-1 universal--text" @click="showTeam(item)">
																			<v-icon small> mdi-eye </v-icon>
																		</v-btn>
																	</template>
																	<span>View</span>
																</v-tooltip>
																<v-tooltip top v-if="$can('team_edit')">

																	<template v-slot:activator="{ on, attrs }">
																		<v-btn icon class="button mr-1 success--text" @click="editTeam(item)" v-bind="attrs"
																			v-on="on">
																			<v-icon small> mdi-pencil </v-icon>
																		</v-btn>
																	</template>
																	<span>Edit</span>
																</v-tooltip>

																<v-tooltip top v-if="$can('team_archive')">

																	<template v-slot:activator="{ on, attrs }">
																		<v-btn icon class="button mr-1 error--text" @click="deleteTeam(item)"
																			:loading="loading && teamIndex == item.id" v-bind="attrs" v-on="on">
																			<v-icon small> mdi-delete </v-icon>
																		</v-btn>
																	</template>
																	<span>delete</span>
																</v-tooltip>

																<v-tooltip top v-if="$can('team_activate') && item.active == 0">

																	<template v-slot:activator="{ on, attrs }">
																		<v-btn icon class="button mr-1 red--text" @click="activateTeam(item)"
																			:loading="activateLoading && teamIndex == item.id" v-bind="attrs" v-on="on">
																			<v-icon small> mdi-toggle-switch-off-outline
																			</v-icon>
																		</v-btn>
																	</template>
																	<span>Activate Team</span>
																</v-tooltip>
																<v-tooltip top v-if="$can('team_deactivate') && item.active == 1">

																	<template v-slot:activator="{ on, attrs }">
																		<v-btn icon class="button mr-1 green--text" @click="deactivateTeam(item)"
																			:loading="deactivateLoading && teamIndex == item.id" v-bind="attrs" v-on="on">
																			<v-icon small> mdi-toggle-switch </v-icon>
																		</v-btn>
																	</template>
																	<span>Deactivate Team</span>
																</v-tooltip>
																<v-tooltip top v-if="$can('log_view')">

																	<template v-slot:activator="{ on, attrs }">
																		<v-btn icon class="button mr-1 purple--text" @click="viewLog(item)" v-bind="attrs"
																			v-on="on">
																			<v-icon small> mdi-script-text </v-icon>
																		</v-btn>
																	</template>
																	<span>Log</span>
																</v-tooltip>

															</div>
														</td>
													</tr>
												</tbody>
											</template>
										</v-simple-table>
									</v-card>

								</div>
								<div class="hidden-md-and-up">
									<v-layout column>

										<template v-for="(team, index) in teams">
											<div :key="index">
												<v-flex xs12 class="mb-2">
													<v-card elevation="0">
														<div class="pa-5">
															<v-layout column>
																<v-flex xs12>
																	<v-layout row wrap>
																		<v-flex xs11>
																			<div class="title primary--text ml-3">
																				<b>{{ team.name }} </b>
																			</div>
																		</v-flex>
																		<v-flex xs1>
																			<v-btn depressed class="red--text" :loading="loading && teamIndex == team.id
		" icon @click="deleteTeam(team)" v-if="$can('team_archive')">
																				<v-icon> mdi-delete </v-icon>
																			</v-btn>
																		</v-flex>
																	</v-layout>
																</v-flex>
																<v-divider class="mt-2"></v-divider>
																<v-flex xs12 class="mt-1">
																	<v-layout column>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Role(s)</b>
																			</div>
																			<div>
																				<template v-for="(team_member_role, index) in team.team_member_roles">
																					<div :key="index">
																						<v-flex xs12>
																							{{index+1}}. {{ team_member_role.team_role.name }}
																						</v-flex>
																					</div>
																				</template>		
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Active</b>
																			</div>
																			<div>
																				<div v-if="team.active == 1">Yes
																				</div>
																				<div v-else>No</div>
																			</div>
																		</v-flex>
																		<v-flex xs12 class="mt-1">
																			<div>
																				<b>Date Added</b>
																			</div>
																			<div>
																				{{
		team.created_at
		| moment("DD/MM/YYYY - hh:mm a")
	}}
																			</div>
																		</v-flex>



																		<v-flex xs12 class="mt-5">
																			<v-layout row wrap>
																				<v-flex xs12>
																					<div class="pa-1">
																						<v-btn v-if="$can('team_view')" depressed class="
																							primary
																							text-none
																							white--text
																							" block @click="showTeam(team)">View
																							<v-icon right> mdi-eye
																							</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																				<v-flex xs12>
																					<div class="pa-1">
																						<v-btn depressed class="blue text-none white--text" block
																							@click="editTeam(team)" v-if="$can('team_edit')">Edit
																							<v-icon right>
																								mdi-border-color
																							</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																				<v-flex xs12>
																					<div class="pa-1">
																						<v-btn depressed class="green text-none white--text" block
																							@click="activateTeam(team)"
																							:loading="activateLoading && teamIndex == team.id"
																							v-if="$can('team_activate') && team.active == 0">Activate
																							Team
																							<v-icon right>
																								mdi-toggle-switch-off-outline
																							</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																				<v-flex xs12>
																					<div class="pa-1">
																						<v-btn depressed class="red text-none white--text" block
																							@click="deactivateTeam(team)"
																							:loading="deactivateLoading && teamIndex == team.id"
																							v-if="$can('team_deactivate') && team.active == 1">Deactivate
																							Team
																							<v-icon right>
																								mdi-toggle-switch
																							</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																				<v-flex xs12>
																					<div class="pa-1">
																						<v-btn v-if="$can('log_view')" depressed class="
                                                  purple
                                                  text-none
                                                  white--text
                                                " block @click="viewLog(team)">Log
																							<v-icon right>
																								mdi-script-text
																							</v-icon>
																						</v-btn>
																					</div>
																				</v-flex>
																			</v-layout>
																		</v-flex>
																	</v-layout>
																</v-flex>
															</v-layout>
														</div>
													</v-card>
												</v-flex>
											</div>
										</template>
									</v-layout>
								</div>
							</div>
						</v-flex>
					</v-card>

					<v-layout column class="mt-5">
						<v-flex xs12>
							<div align="center">
								<v-pagination v-if="length != 0" :length="length" total-visible="10"
									v-model="teamPagination.current_page" @input="changePage()" circle>
								</v-pagination>
							</div>
						</v-flex>
						<v-flex xs12>
							<div v-if="length != 0" align="center" class="mt-5">
								<b class="primary--text">Total: </b>{{ teamPagination.total | formatNumber }}
							</div>
						</v-flex>
					</v-layout>
				</div>
				<div v-if="view == 'create'">
					<v-flex class="mt-5">
						<v-card outlined>
							<v-card-title class="tableHeader text--text">
								<v-spacer></v-spacer>
								<div v-if="newTeam.id == null">Add Team</div>
								<div v-else>Edit Team</div>
								<v-spacer></v-spacer>
								<v-btn icon @click="changeView('default')">
									<v-icon class="text--text"> mdi-close </v-icon>
								</v-btn>
							</v-card-title>
							<v-card-text>
								<v-form v-model="valid" ref="form" lazy-validation>
									<v-layout column>
										<v-flex xs12>
											<v-layout row wrap class="mt-1">
												<v-flex xs12 md6>
													<div class="mx-1 mt-5">
														<div>Name<span class="error--text">*</span></div>
														<v-text-field class="text_field background" outlined dense v-model="newTeam.name"
															:rules="inputRules"></v-text-field>
													</div>
												</v-flex>
												<v-flex xs12 md6>
													<div class="mx-1 mt-5">
														<div>Phone Number</div>
														<v-text-field class="text_field background" outlined dense v-model="newTeam.phone_number"></v-text-field>
													</div>
												</v-flex>
												<v-flex xs12 md6>
													<div class="mx-1 mt-5">
														<div>Email Address</div>
														<v-text-field class="text_field background" outlined dense v-model="newTeam.email_address"></v-text-field>
													</div>
												</v-flex>
												<v-flex xs12 md6>
													<div class="mx-1 mt-5">
														<div>Website</div>
														<v-text-field class="text_field background" outlined dense v-model="newTeam.website_url"></v-text-field>
													</div>
												</v-flex>
												<v-flex xs12>
													<div class="mx-1 mt-5">
														<div>Bio<span class="error--text">*</span></div>
														<div class="black--text">
															<ckeditor :editor="editor" v-model="newTeam.bio" :config="editorConfig"
																:rules="inputRules">
															</ckeditor>
														</div>
													</div>
												</v-flex>
												<v-flex xs12>
															<div class="mx-1 mt-5" v-if="formData != null">
																<div>Role(s)<span class="red--text">*</span></div>
																<v-row>
																	<template v-for="(
																				teamRole, index
																				) in formData.teamRoles">
																		<v-col cols="12" sm="4" md="4" :key="index">
																			<v-checkbox v-model="newTeam.roles" :key="index" :value="teamRole.id"
																				:label="teamRole.name"></v-checkbox>
																		</v-col>
																	</template>
																</v-row>
															</div>
														</v-flex>
														<v-flex xs12 md4>
															<div class="mx-1 mt-5">
																<div>X URL</div>
																<v-text-field outlined dense v-model="newTeam.x_url" class="text_field background"
																	prepend-inner-icon="mdi-twitter"></v-text-field>
															</div>
														</v-flex>
														<v-flex xs12 md4>
															<div class="mx-1 mt-5">
																<div>LinkedIn URL</div>
																<v-text-field outlined dense v-model="newTeam.linkedin_url" class="text_field background"
																	prepend-inner-icon="mdi-linkedin"></v-text-field>
															</div>
														</v-flex>
														<v-flex xs12 md4>
															<div class="mx-1 mt-5">
																<div>Facebook URL</div>
																<v-text-field outlined dense v-model="newTeam.facebook_url" class="text_field background"
																	prepend-inner-icon="mdi-facebook"></v-text-field>
															</div>
														</v-flex>
														<v-flex xs12 md4>
															<div class="mx-1 mt-5">
																<div>YouTube URL</div>
																<v-text-field outlined dense v-model="newTeam.youtube_url" class="text_field background"
																	prepend-inner-icon="mdi-youtube"></v-text-field>
															</div>
														</v-flex>
														<v-flex xs12 md4>
															<div class="mx-1 mt-5">
																<div>Instagram URL</div>
																<v-text-field outlined dense v-model="newTeam.instagram_url" class="text_field background"
																	prepend-inner-icon="mdi-instagram"></v-text-field>
															</div>
														</v-flex>
														<v-flex xs12 md4>
															<div class="mx-1 mt-5">
																<div>TikTok URL</div>
																<v-text-field outlined dense v-model="newTeam.tiktok_url" class="text_field background"
																	prepend-inner-icon="mdi-alpha-t"></v-text-field>
															</div>
														</v-flex>


											</v-layout>
										</v-flex>
									</v-layout>

								</v-form>
							</v-card-text>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn depressed class="primary text-none mt-3" @click="save" :disabled="!valid" :loading="loading">
									Save
									<v-icon right> mdi-content-save </v-icon>
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-flex>
				</div>
				<div v-if="view == 'show'">
					<v-card outlined class="mt-5">
						<v-card-title class="tableHeader text--text">
							{{ assignedTeam.name }}
							<v-spacer></v-spacer>
							<v-btn icon @click="changeView('default')">
								<v-icon class="text--text"> mdi-close </v-icon>
							</v-btn>
						</v-card-title>
						<v-card-text>
							<div class="mt-2 pa-5">
								<v-layout row wrap>
									<v-flex xs12 md3>
										<v-layout column>
											<v-flex xs12>
												<div align="center" class="mt-5">
													<v-avatar size="200" class="primary title white--text" v-if="assignedTeam.image == null">
														{{ assignedTeam.name[0] }}
													</v-avatar>
													<v-avatar size="200" class="primary title" v-else>
														<img :src="path + '/storage/team_pics/' + assignedTeam.image">
													</v-avatar>
												</div>
											</v-flex>
											<v-flex xs12>
												<div align="center">
													<v-btn depressed class="text-none mt-5 secondary white--text" @click="imageDialog = true">
														Upload Pic
													</v-btn>
												</div>
											</v-flex>
										</v-layout>
									</v-flex>
									<v-flex xs12 md9>
										<v-layout row wrap>
									<v-flex xs12 md6>
										<v-layout column class="ml-6">
											<v-flex xs12 class="mt-10">
												<v-layout row wrap>
													<v-flex xs12 md3>
														<div><b>Name:</b></div>
													</v-flex>
													<v-flex xs12 md9>
														{{ assignedTeam.name }}
													</v-flex>
												</v-layout>
											</v-flex>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs12 md3>
														<div><b>Phone Number:</b></div>
													</v-flex>
													<v-flex xs12 md9>
														{{ assignedTeam.phone_number }}
													</v-flex>
												</v-layout>
											</v-flex>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs12 md3>
														<div><b>Email Address:</b></div>
													</v-flex>
													<v-flex xs12 md9>
														{{ assignedTeam.email_address }}
													</v-flex>
												</v-layout>
											</v-flex>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs12 md3>
														<div><b>Website:</b></div>
													</v-flex>
													<v-flex xs12 md9>
														{{ assignedTeam.website_url }}
													</v-flex>
												</v-layout>
											</v-flex>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs12 md3>
														<div><b>Role(s):</b></div>
													</v-flex>
													<v-flex xs12 md9>
														<v-layout column>
															<div v-if="assignedTeam.team_member_roles.length == 0">
																<template v-for="(team_member_role, index) in assignedTeam.team_member_roles">
																	<div :key="index">
																		<v-flex xs12 class="mb-2">
																			{{index+1}} {{ team_member_role.team_role.name }}
																		</v-flex>
																	</div>
																</template>
															</div>
															<div v-else>
																N/A
															</div>
														</v-layout>
													</v-flex>
												</v-layout>
											</v-flex>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs12 md3>
														<div><b>Active:</b></div>
													</v-flex>
													<v-flex xs12 md9>
														<div v-if="assignedTeam.active == 1">Yes</div>
														<div v-else>No</div>
													</v-flex>
												</v-layout>
											</v-flex>
										</v-layout>
									</v-flex>
									<v-flex xs12 md6>
										<v-layout column class="ml-6">
											<v-flex xs12 class="mt-10">
												<v-layout row wrap>
													<v-flex xs12 md3>
														<div><b>X:</b></div>
													</v-flex>
													<v-flex xs12 md9>
														{{ assignedTeam.x_url }}
													</v-flex>
												</v-layout>
											</v-flex>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs12 md3>
														<div><b>LinkedIn:</b></div>
													</v-flex>
													<v-flex xs12 md9>
														{{ assignedTeam.linkedin_url }}
													</v-flex>
												</v-layout>
											</v-flex>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs12 md3>
														<div><b>Facebook:</b></div>
													</v-flex>
													<v-flex xs12 md9>
														{{ assignedTeam.facebook_url }}
													</v-flex>
												</v-layout>
											</v-flex>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs12 md3>
														<div><b>Youtube:</b></div>
													</v-flex>
													<v-flex xs12 md9>
														{{ assignedTeam.youtube_url }}
													</v-flex>
												</v-layout>
											</v-flex>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs12 md3>
														<div><b>Instagram:</b></div>
													</v-flex>
													<v-flex xs12 md9>
														{{ assignedTeam.instagram_url }}
													</v-flex>
												</v-layout>
											</v-flex>
											<v-flex xs12 class="mt-5">
												<v-layout row wrap>
													<v-flex xs12 md3>
														<div><b>TikTok:</b></div>
													</v-flex>
													<v-flex xs12 md9>
														{{ assignedTeam.tiktok_url }}
													</v-flex>
												</v-layout>
											</v-flex>
										</v-layout>
									</v-flex>
									<v-flex xs12 class="mt-10">
										<v-layout row wrap class="ml-3">
											<v-flex xs12>
												<div><b>Bio</b></div>
											</v-flex>
											<v-flex xs12>
												<div v-html="assignedTeam.bio"></div>
											</v-flex>
										</v-layout>
									</v-flex>
								</v-layout>
									</v-flex>
								</v-layout>
								
							</div>
						</v-card-text>
					</v-card>
				</div>
			</v-layout>
		</v-container>
		<logData v-if="logDataModel" v-model="logDataModel" />
	</div>
</template>

<script>
import apiCall from "@/utils/api";
import { mapGetters, mapActions } from "vuex";
import logData from "@/views/accessControl/logData";
import Vue from "vue";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import uploadAdapter from '@/uploadAdapter.js';
var numeral = require("numeral");
Vue.filter("formatNumber", function (value) {
	return numeral(value).format("0,0");
});

Vue.use(require("vue-moment"));

export default {
	components: {
		logData,
	},
	data() {
		return {
			path: process.env.VUE_APP_API_URL,
			view: "default",
			activeTab: 'details',
			saleTab: null,

			editor: ClassicEditor,
			editorConfig: {
				extraPlugins: [function (editor) {
					editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
						return new uploadAdapter(loader);
					}
				}],
				language: 'en',
			},

			loading: false,
			activateLoading: false,
			deactivateLoading: false,

			albumLoader: false,
			imageLoader: false,

			albumDatePicker: false,

			valid: true,
			message: "",
			color: "",
			delete: false,
			activate: false,
			confirm: false,
			lockEmail: false,

			teamIndex: null,

			snackbar: false,
			inputRules: [
				(v) => !!v || "Input is required"
			],
			emailRules: [
				(v) =>
					!v ||
					/^[a-zA-Z0-9\.!#$%&'*+/=?^_~-]+@([a-zA-Z0-9]+\.)+[a-zA-Z]{2,3}$/.test(
						v
					) ||
					"E-mail must be valid",
				(v) => !v || (v && v.length <= 50) || "Maximum 50 characters",
			],
			fileRules: [(v) => !!v || "Kindly Select a File"],

			imageDialog: false,
			imageLink: '',
			imageName: "",
			imageUrl: "",
			imageFile: "",
			imageValid: true,
			imageLoading: false,

			months: [
				"01",
				"02",
				"03",
				"04",
				"05",
				"06",
				"07",
				"08",
				"09",
				"10",
				"11",
				"12",
			],
			monthNames: [
				{ name: "January" },
				{ name: "February" },
				{ name: "March" },
				{ name: "April" },
				{ name: "May" },
				{ name: "June" },
				{ name: "July" },
				{ name: "August" },
				{ name: "September" },
				{ name: "October" },
				{ name: "November" },
				{ name: "December" },
			],

			images: [],

			searchTerm: null,

			searchLoader: false,

			searchButtonDisabled: false,

			filteredTeams: false,

			newTeam: {
				id: null,
				name: null,
				bio: null,
				image: null,
				email_address: null,
				phone_number: null,
				x_url: null,
				facebook_url: null,
				website_url: null,
				linkedin_url: null,
				roles: []
			},

			assignedTeam: null,

			formData: null,
			logData: {
				message: null,
				model: "App\\Models\\Team",
			},
		};
	},
	created() {
		if (this.$can("team_view")) {
			this.startTeamLoader();
			this.fetchTeams(this.teamPagination.current_page);
			this.getFormData();
		}
	},
	methods: {
		...mapActions([
			"fetchTeams",
			"startTeamLoader",
			"stopTeamLoader",
			"filterTeams",

			"filterLogData",
			"openLogDataModel",
		]),
		closeAlbumDate() {
			this.albumDatePicker = false;
		},

		enableSearch() {
			this.searchButtonDisabled = false;
		},

		resetSearch() {
			this.searchTerm = null;
			this.filteredTeams = true;
			this.startTeamLoader();
			this.fetchTeams(1);
		},

		showTeam(team) {
			this.assignedTeam = team;

			this.changeView("show");
		},
		editTeam(team) {
			this.newTeam.id = team.id;
			this.newTeam.name = team.name;
			this.newTeam.bio = team.bio;
			this.newTeam.image = team.image;
			this.newTeam.email_address = team.email_address;
			this.newTeam.phone_number = team.phone_number;
			this.newTeam.x_url = team.x_url;
			this.newTeam.facebook_url = team.facebook_url;
			this.newTeam.website_url = team.website_url;
			this.newTeam.linkedin_url = team.linkedin_url;
			this.newTeam.youtube_url = team.youtube_url;
			this.newTeam.instagram_url = team.instagram_url;
			this.newTeam.tiktok_url = team.tiktok_url;
			this.newTeam.roles = [];

			for (var x = 0; x < team.team_member_roles.length; x++) {
					this.newTeam.roles.push(team.team_member_roles[x].team_role_id)
				}

			this.changeView("create");
		},

		viewLog(data) {
			this.logData.message = data.id;
			this.filterLogData(this.logData);
			this.openLogDataModel();
		},

		uploadImage() {
			if (this.$refs.imageForm.validate()) {
				this.imageLoading = true;
				let formData = new FormData();

				formData.append("id", this.assignedTeam.id)
				formData.append("files", this.imageFile, this.imageFile.name);

				apiCall({
					url: "/api/team?type=image",
					data: formData,
					method: "POST",
				})
					.then((resp) => {
						this.snackbar = true;
						this.message = "Image Uploaded Successfully";
						this.color = "success";
						this.fetchTeams(this.teamPagination.current_page);
						this.imageLoading = false;
						this.imageDialog = false;
						this.imageFile = null

						this.assignedTeam = resp
					})
					.catch((error) => {
						this.imageLoading = false;
					});
			}
		},

		save() {
			if (this.$refs.form.validate()) {
				if (this.newTeam.id != null) {
					if(this.newTeam.bio == null){
						this.message = 'Kindly add a bio'
						this.color = "error";
						this.loading = false;
						this.snackbar = true;
					}else if(this.newTeam.roles.length == 0){
						this.message = 'Kindly pick a role'
						this.color = "error";
						this.loading = false;
						this.snackbar = true;
					}else{
						confirm("Are You Sure You Want to Update Team") && (this.confirm = true);
						if (this.confirm) {
							this.loading = true;
							apiCall({
								url: "/api/team/" + this.newTeam.id,
								data: this.newTeam,
								method: "PUT",
							})
								.then((resp) => {
									this.message = "Team Updated Successfully";
									this.color = "orange";
									this.loading = false;
									this.snackbar = true;
									this.confirm = false;
									this.changeView("default");
									this.fetchTeams(this.teamPagination.current_page);
								})
								.catch((error) => {
									this.message = "An Error Occurred";
									this.color = "error";
									this.loading = false;
									this.snackbar = true;
								});
						}
					}
				} else {
					if(this.newTeam.bio == null){
						this.message = 'Kindly add a bio'
						this.color = "error";
						this.loading = false;
						this.snackbar = true;
					}else if(this.newTeam.roles.length == 0){
						this.message = 'Kindly pick a role'
						this.color = "error";
						this.loading = false;
						this.snackbar = true;
					}else{
						confirm("Are You Sure You Want to Add Team Member?") && (this.confirm = true);
					if (this.confirm) {
						this.loading = true;
						apiCall({
							url: "/api/team",
							data: this.newTeam,
							method: "POST",
						})
							.then((resp) => {
								if (resp.status == 409) {
									this.message = resp.message
									this.color = "error";
									this.loading = false;
									this.snackbar = true;
								} else {
									this.message = "Team Added Successfully";
									this.color = "success";
									this.loading = false;
									this.snackbar = true;
									this.changeView("default");
									this.fetchTeams(1);
								}
							})
							.catch((error) => {
								this.message = "An Error Occurred Email";
								this.color = "error";
								this.loading = false;
								this.snackbar = true;
								this.changeView("default");
								this.fetchTeams(1);
							});
					}
					}
					
				}
			}
		},

		search() {
			if (this.searchTerm == null) {
				this.searchButtonDisabled = true;
			} else {
				this.searchLoader = true;
				this.searchButtonDisabled = false;
				this.startTeamLoader();
				apiCall({
					url: "/api/team?type=search&search=" + this.searchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.filterTeams(resp);
						this.searchLoader = false;
						this.stopTeamLoader();
						this.filteredTeams = true;
					})
					.catch((error) => {
						console.log(error.response);
						this.searchLoader = false;
						this.stopTeamLoader();
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			}
		},
		changeView(view) {
			if (view == "default") {
				this.newTeam.id = null;
				this.newTeam.name = null;
				this.newTeam.bio = null;
				this.newTeam.image = null;
				this.newTeam.email_address = null;
				this.newTeam.phone_number = null;
				this.newTeam.x_url = null;
				this.newTeam.facebook_url = null;
				this.newTeam.website_url = null;
				this.newTeam.linkedin_url = null;
				this.newTeam.youtube_url = null;
				this.newTeam.instagram_url = null;
				this.newTeam.tiktok_url = null;
				this.newTeam.roles = [];
			} else if (view == "create") {
				this.getFormData();
			} else if (view == "show") {
			}
			this.view = view;
		},

		changePage() {
			this.startTeamLoader();
			if (this.filteredTeams == true) {
				this.searchButtonDisabled = false;
				apiCall({
					url:
						"/api/team?page=" +
						this.teamPagination.current_page +
						"&type=search&search=" +
						this.searchTerm,
					method: "GET",
				})
					.then((resp) => {
						this.filterTeams(resp);
						this.stopTeamLoader();
					})
					.catch((error) => {
						this.stopTeamLoader();
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
					});
			} else {
				this.fetchTeams(this.teamPagination.current_page);
			}
		},

		deleteTeam(item) {
			confirm("Are You Sure You Want to Delete Team?") && (this.delete = true);
			if (this.delete) {
				this.loading = true;
				this.teamIndex = item.id;
				apiCall({ url: "/api/team/" + item.id, method: "DELETE" })
					.then((resp) => {
						this.message = "Team Deleted Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.teamIndex = null;
						this.fetchTeams(this.teamPagination.current_page);
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.loading = false;
						this.delete = false;
						this.teamIndex = null;
						console.log(error.response);
					});
			}
		},

		activateTeam(item) {
			confirm("Are You Sure You Want to Activate Team?") && (this.activate = true);
			if (this.activate) {
				this.activateLoading = true;
				this.teamIndex = item.id;
				this.newTeam.id = item.id;
				apiCall({
					url: "/api/team?type=activate",
					data: this.newTeam,
					method: "POST"
				})
					.then((resp) => {
						this.message = "Team Activated Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.activateLoading = false;
						this.activate = false;
						this.teamIndex = null;
						this.newTeam.id = null;
						this.fetchTeams(this.teamPagination.current_page);
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.activateLoading = false;
						this.activate = false;
						this.teamIndex = null;
						this.newTeam.id = null;
						console.log(error.response);
					});
			}
		},
		deactivateTeam(item) {
			confirm("Are You Sure You Want to Deactivate Team?") && (this.activate = true);
			if (this.activate) {
				this.deactivateLoading = true;
				this.teamIndex = item.id;
				this.newTeam.id = item.id;
				apiCall({
					url: "/api/team?type=deactivate",
					data: this.newTeam,
					method: "POST"
				})
					.then((resp) => {
						this.message = "Team  Deactivated Succesfully";
						this.color = "success";
						this.snackbar = true;
						this.deactivateLoading = false;
						this.activate = false;
						this.teamIndex = null;
						this.newTeam.id = null;
						this.fetchTeams(this.teamPagination.current_page);
					})
					.catch((error) => {
						this.message = "An Error Occurred";
						this.color = "error";
						this.snackbar = true;
						this.deactivateLoading = false;
						this.activate = false;
						this.teamIndex = null;
						this.newTeam.id = null;
						console.log(error);
					});
			}
		},

		getFormData() {
			apiCall({
				url: "/api/team?type=formData",
				method: "GET",
			})
				.then((resp) => {
					this.formData = resp;
				})
				.catch((error) => {
					this.message = "An Error Occurred; Couldn't Get Form Data";
					this.color = "error";
				});
		},
	},
	computed: {
		...mapGetters(["teams", "teamPagination", "teamLoader", "logDataModel"]),
		length: function () {
			return Math.ceil(
				this.teamPagination.total / this.teamPagination.per_page
			);
		},
		formatAlbumDate() {
			if (this.newAlbum.date != null) {
				const d = new Date(this.newAlbum.date);
				const year = d.getFullYear();
				const date = d.getDate();
				const month = d.getMonth();

				return date + " " + this.monthNames[month].name + " " + year;
			}
		},
		dateToday() {
			var today = new Date();

			var timestamp =
				today.getFullYear() +
				"-" +
				this.months[today.getMonth()] +
				"-" +
				("0" + today.getDate()).slice(-2);
			return timestamp;
		},
	},
};
</script>