<template>
    <v-footer
      inset
      relative 
      dark
      padless
      width="auto"
      color="#525355"
      height="auto"
    >
    <v-layout column>
      <v-flex xs12 class="secondary">
        <div align="center" class="mt-2 mb-1 font-weight-black">
          <a href="https://x.com/search?q=%23SCWomenInTech&src=typed_query" style="text-decoration: none;" target="_blank" class="white--text">#SCWomenInTech</a> / <a href="https://x.com/search?q=%23SheIsInCharge&src=typed_query&f=top" style="text-decoration: none;" target="_blank" class="white--text">#SheIsInCharge</a> / <a href="https://x.com/search?q=%23WomenInTechKe&src=typed_query&f=top" style="text-decoration: none;" target="_blank" class="white--text">#WomenInTechKe</a>
        </div>
      </v-flex>
      <v-flex xs12 class="black">
        
          <v-container class="my-16">
            <v-layout row wrap class="my-16">
              <v-flex xs12 md3>
                <div>
                  <v-layout row wrap>
                    <!-- <v-flex xs12 md6>
                      <div class="pa-1" align="center">
                        <a href="https://www.sc.com/ke/" style="text-decoration: none;" target="_blank"><v-img max-width="300" class="mt-5 hidden-md-and-down" src="img/logo-white.png">
                        </v-img> </a>
                        <a href="https://www.sc.com/ke/" style="text-decoration: none;" target="_blank"><v-img max-width="52" class="mt-4 hidden-lg-and-up" src="img/logo-white.png">
                        </v-img> </a>
                       </div> 
                    </v-flex>
                    <v-flex xs12 md6>
                      <div class="pa-1" align="center">
                        <a href="http://www.ilabafrica.ac.ke/" style="text-decoration: none;" target="_blank"><v-img max-width="100" class="mt-4 hidden-md-and-down" src="img/ibiz-white.png">
                        </v-img></a>
                        <a href="http://www.ilabafrica.ac.ke/" style="text-decoration: none;" target="_blank"><v-img class="mt-4 hidden-lg-and-up" max-width="90" src="img/ibiz-white.png">
                        </v-img></a>
                       </div> 
                    </v-flex> -->
                    <v-flex xs12 class="mt-6">
                      <div align="center">
                      <a href="http://www.ilabafrica.ac.ke/" style="text-decoration: none;" target="_blank"><v-img class="hidden-md-and-down" max-width="400" src="img/ibiz-white.png">
                        </v-img></a>
                        <a href="http://www.ilabafrica.ac.ke/" style="text-decoration: none;" target="_blank"><v-img class="hidden-lg-and-up" max-width="400" src="img/ibiz-white.png">
                        </v-img></a>
                      </div>
                    </v-flex>
                    <v-flex xs12>
                      <div align="center">
                        <template>
                          <a
                            target="_blank"
                            href="https://www.facebook.com/iBizAfrica"
                            style="text-decoration: none"
                          >
                            <v-btn class="mx-2" icon>
                              <v-icon size="25px" color="white"> mdi-facebook </v-icon>
                            </v-btn>
                          </a>
                          <a
                            target="_blank"
                            href="https://twitter.com/iBizAfrica"
                            style="text-decoration: none"
                          >
                            <v-btn class="mx-2" icon>
                              <v-icon size="25px" color="white"> mdi-twitter </v-icon>
                            </v-btn>
                          </a>
                          <a
                            target="_blank"
                            href="https://www.youtube.com/c/iLabAfricaSU/videos"
                            style="text-decoration: none"
                          >
                            <v-btn class="mx-2" icon>
                              <v-icon size="25px" color="white"> mdi-youtube </v-icon>
                            </v-btn>
                          </a>
                          <a
                            target="_blank"
                            href="https://www.instagram.com/iBizAfrica/"
                            style="text-decoration: none"
                          >
                            <v-btn class="mx-2" icon>
                              <v-icon size="25px" color="white"> mdi-instagram </v-icon>
                            </v-btn>
                          </a>
                        </template>
                      </div>                      
                    </v-flex>
                  </v-layout>
                </div>
              </v-flex>
              <v-flex xs12 md3>
                <div class="mx-3 mt-5">
                  <div class="font-weight-black white--text my-5">ABOUT US</div>
                  <a class="white--text caption" href="/#/" style="text-decoration: none">Home</a>
                  <v-divider class="my-2 white"></v-divider>
                  <a class="white--text caption" href="/#/about" style="text-decoration: none">About</a>
                  <v-divider class="my-2 white"></v-divider>
                  <a class="white--text caption" href="/#/faqs" style="text-decoration: none">Frequently Asked Questions</a>
                  <v-divider class="my-2 white"></v-divider>
                </div>
                <div class="mx-3 mt-11">
                  <div class="font-weight-black white--text my-5">OUR TEAM</div>
                  <a class="white--text caption" href="/#/team" style="text-decoration: none">Team</a>
                  <v-divider class="my-2 white"></v-divider>
                </div>
              </v-flex>
              <v-flex xs12 md3>
                <div class="mx-3 mt-5">
                  <div class="font-weight-black white--text my-5">COHORTS</div>
                  <a class="white--text caption" href="/#/past-cohorts/cohort-1" style="text-decoration: none">Cohort 1</a>
                  <v-divider class="my-2 white"></v-divider>
                  <a class="white--text caption" href="/#/past-cohorts/cohort-2" style="text-decoration: none">Cohort 2</a>
                  <v-divider class="my-2 white"></v-divider>
                  <a class="white--text caption" href="/#/past-cohorts/cohort-3" style="text-decoration: none">Cohort 3</a>
                  <v-divider class="my-2 white"></v-divider>
                  <a class="white--text caption" href="/#/past-cohorts/cohort-4" style="text-decoration: none">Cohort 4</a>
                  <v-divider class="my-2 white"></v-divider>
                  <a class="white--text caption" href="/#/past-cohorts/cohort-5" style="text-decoration: none">Cohort 5</a>
                  <v-divider class="my-2 white"></v-divider>
                  <a class="white--text caption" href="/#/past-cohorts/cohort-6" style="text-decoration: none">Cohort 6</a>
                  <v-divider class="my-2 white"></v-divider>
                </div>
              </v-flex>
              <v-flex xs12 md3>
                <div class="mx-3 mt-5">
                  <div class="font-weight-black white--text my-5">Media</div>
                  <a class="white--text caption mt-5" href="/#/news-feed" style="text-decoration: none">News Feed</a>
                  <v-divider class="my-2 white"></v-divider>
                  <a class="white--text caption" href="/#/stories" style="text-decoration: none">Stories</a>
                  <v-divider class="my-2 white"></v-divider>
                  <a class="white--text caption" href="/#/gallery" style="text-decoration: none">Gallery</a>
                  <v-divider class="my-2 white"></v-divider>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
      </v-flex>
        <v-flex xs12 class="#525355">
          <v-divider class="primary"></v-divider>
          <v-divider class="primary"></v-divider>
            <v-container class="my-5">
              <v-layout row wrap>
                  <v-flex xs12 md6>
                    <div align="center">
                      <a class="caption white--text hidden-md-and-up" href="/#/terms-and-conditions" style="text-decoration: none;">Terms and Conditions</a>
                    </div>
                    </v-flex>
                  <v-flex xs12 md6>
                  <div align="center" class="caption white--text hidden-md-and-up">
                      © {{ new Date().getFullYear() }} • All Rights Reserved
                  </div>
                  </v-flex>
                  <v-flex xs12 md8>
                    <a align="left" class="caption white--text hidden-sm-and-down" href="/#/terms-and-conditions" style="text-decoration: none;">Terms and Conditions</a>
                  </v-flex>
                  <v-flex xs12 md4>
                  <div align="right" class="caption white--text hidden-sm-and-down">
                      © {{ new Date().getFullYear() }} • All Rights Reserved
                  </div>
                  </v-flex>
              </v-layout>
            </v-container>
        </v-flex>
    </v-layout>
    
          
    </v-footer>
  </template>
  <script>
    export default {
      data: () => ({
        socials: [
          {icon: 'mdi-facebook', link: 'https://www.facebook.com/iLabAfricaStrathmore/?fref=ts'},
          {icon: 'mdi-twitter', link: 'https://twitter.com/ilabafrica'},
          {icon: 'mdi-linkedin', link: 'https://www.linkedin.com/company/2596334?trk=tyah&trkInfo=clickedVertical%3Acompany%2CclickedEntityId%3A2596334%2Cidx%3A2-1-2%2CtarId%3A1465459099320%2Ctas%3AILABAFRICA'},
          {icon: 'mdi-youtube', link: 'https://www.youtube.com/channel/UCeo0kj1SRiVkje78cyxnAzQ'},
          {icon: 'mdi-instagram', link: 'https://www.instagram.com/ilabafrica/?hl=en'},
        ],
      }),
    }
  </script>