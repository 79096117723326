import apiCall from '@/utils/api'

const state = {
	groups: [],
	allGroups: [],
    groupPagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    groupLoader: false
};

const getters = {
	groups: (state) => state.groups,
	allGroups: (state) => state.allGroups,
    groupLoader: (state) => state.groupLoader,
    groupPagination: (state) => state.groupPagination
}

const actions = {
	async fetchAllGroups({commit}, page) {
		const response = await apiCall({url: `/api/group?type=all`, method: 'GET' });
		commit('setAllGroups', response)
        commit('stopLoader', response)
	},
    async fetchGroups({commit}, page) {
		const response = await apiCall({url: `/api/group?page=${page}`, method: 'GET' });
		commit('setGroups', response)
        commit('stopLoader', response)
	},
    async filterGroups({commit, state},resp){
		commit('setGroups', resp)
	},
	async startGroupLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopGroupLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setGroups: (state, groups) => {
		state.groups = groups.data
		state.groupPagination.current_page = groups.current_page
		state.groupPagination.total = groups.total
		state.groupPagination.per_page = groups.per_page
	},
	setAllGroups: (state, groups) => {
		state.allGroups = groups
	},
    startLoader: (state) => state.groupLoader = true,
	stopLoader: (state) => state.groupLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
