<template>
	<div class="welcome">
		<v-layout column>
			<v-flex xs12>
				<v-img dark height="250" src="img/banner.jpg">
					<div align="center" class="font-weight-black display-1 mt-16" style="font-family: 'SC Prosper Sans Regular';">
						{{ pastCohort.name }}
					</div>
					<div align="center" class="mt-2">
						Accelerating the Digital Economy through Women Owned Businesses
					</div>

				</v-img>
			</v-flex>
			<v-progress-linear v-if="competitorLoader" height="2" indeterminate color="primary"></v-progress-linear>
			<v-flex xs12 class="white">
				<v-container class="my-12">
					<div v-if="competitors.length!=0">
						<v-layout column class="my-12">
							<v-flex xs12>
								<v-layout row wrap>
									<template v-for="(competitor, index) in competitors">
										<v-flex xs12 md3>
											<v-hover v-slot="{ hover }">
												<v-card class="mx-1 my-1" color="grey lighten-4" max-width="600" elevation="0">
													<v-img max-height="310" contain :src="path + '/storage/competitor_pics/' + competitor.image">
														<v-expand-transition>
															<div v-if="hover" class="d-flex transition-fast-in-fast-out secondary darken-2 v-card--reveal"
																style="height: 100%;">
																<a :href="competitor.link"
																	style="text-decoration: none;" target="_blank" class="white--text">Catapult Studios</a>
															</div>
														</v-expand-transition>
													</v-img>
													<v-card-text class="pt-6" style="position: relative;">
														<div class="font-weight-light primary--text mb-2">
															<a :href="competitor.link"
																style="text-decoration: none;" target="_blank">{{ competitor.name }}</a>
														</div>
														<v-divider class="my-5 secondary"></v-divider>
														<div class="font-weight-light mb-2">
															<v-clamp autoresize :max-lines="4">{{ removeTags(competitor.description) }}
                              </v-clamp>
														</div>

														<div align="center">
															<a v-if="competitor.facebook_url != null" :href="competitor.facebook_url"
																	target="_blank" style="text-decoration: none">
																	<v-btn class="mx-1" icon>
																			<v-icon size="20px" color="#3b5998"> mdi-facebook
																			</v-icon>
																	</v-btn>
															</a>
															<a v-if="competitor.x_url != null" :href="competitor.x_url"
																	target="_blank" style="text-decoration: none">
																	<v-btn class="mx-1" icon>
																			<v-icon size="20px" color="#1DA1F2"> mdi-twitter
																			</v-icon>
																	</v-btn>
															</a>
															<a v-if="competitor.instagram_url != null" :href="competitor.instagram_url"
																	target="_blank" style="text-decoration: none">
																	<v-btn class="mx-1" icon>
																			<v-icon size="20px" color="#8a3ab9"> mdi-instagram
																			</v-icon>
																	</v-btn>
															</a>
															<a v-if="competitor.linkedin_url != null" :href="competitor.linkedin_url"
																	target="_blank" style="text-decoration: none">
																	<v-btn class="mx-1" icon>
																			<v-icon size="20px" color="#0e76a8"> mdi-linkedin
																			</v-icon>
																	</v-btn>
															</a>
															<a v-if="competitor.link != null" :href="competitor.link"
																	target="_blank" style="text-decoration: none">
																	<v-btn class="mx-1" icon>
																			<v-icon size="20px" color="secondary"> mdi-web
																			</v-icon>
																	</v-btn>
															</a>
															<a v-if="competitor.youtube_url != null" :href="competitor.youtube_url"
																	target="_blank" style="text-decoration: none">
																	<v-btn class="mx-1" icon>
																			<v-icon size="20px" color="#cc181e"> mdi-youtube
																			</v-icon>
																	</v-btn>
															</a>
														</div>
													</v-card-text>
												</v-card>
											</v-hover>
										</v-flex>
									</template>
								</v-layout>
							</v-flex>
							<v-flex xs12 class="mt-10">
								<v-pagination v-if="length != 0" 
										:length="length" 
										total-visible="10"
										v-model="competitorPagination.current_page"
										circle
										@input="getCompetitors()"
								>
								</v-pagination>
						</v-flex>
						<v-flex xs12>
								<div v-if="length != 0" align="center" class="mt-5">
										<b class="primary--text">Total: </b>{{ competitorPagination.total | formatNumber }}
								</div>
						</v-flex>
						</v-layout>
					</div>
					<div v-else>
						<v-layout row wrap class="my-16">
								<v-flex xs12 md3>
										<div class="mx-1 my-1">
												<v-skeleton-loader
														v-bind="attrs"
														type="card-avatar"
														></v-skeleton-loader>
											</div>
								</v-flex>
								<v-flex xs12 md3>
										<div class="mx-1 my-1">
												<v-skeleton-loader
														v-bind="attrs"
														type="card-avatar"
														></v-skeleton-loader>
											</div>
								</v-flex>
								<v-flex xs12 md3>
										<div class="mx-1 my-1">
												<v-skeleton-loader
														v-bind="attrs"
														type="card-avatar"
														></v-skeleton-loader>
											</div>
								</v-flex>
								<v-flex xs12 md3>
										<div class="mx-1 my-1">
												<v-skeleton-loader
														v-bind="attrs"
														type="card-avatar"
														></v-skeleton-loader>
											</div>
								</v-flex>
								<v-flex xs12 md3>
										<div class="mx-1 my-1">
												<v-skeleton-loader
														v-bind="attrs"
														type="card-avatar"
														></v-skeleton-loader>
											</div>
								</v-flex>
								<v-flex xs12 md3>
										<div class="mx-1 my-1">
												<v-skeleton-loader
														v-bind="attrs"
														type="card-avatar"
														></v-skeleton-loader>
											</div>
								</v-flex>
								<v-flex xs12 md3>
										<div class="mx-1 my-1">
												<v-skeleton-loader
														v-bind="attrs"
														type="card-avatar"
														></v-skeleton-loader>
											</div>
								</v-flex>
								<v-flex xs12 md3>
										<div class="mx-1 my-1">
												<v-skeleton-loader
														v-bind="attrs"
														type="card-avatar"
														></v-skeleton-loader>
											</div>
								</v-flex>
						</v-layout>
					</div>
				</v-container>
			</v-flex>
		</v-layout>
	</div>
</template>
<style>
.v-card--reveal {
	align-items: center;
	bottom: 0;
	justify-content: center;
	opacity: .5;
	position: absolute;
	width: 100%;
}
</style>
<script>
import apiCall from "@/utils/api";
import { mapState, mapGetters, mapActions } from "vuex";
import Vue from "vue";
import competitor from "../store/modules/competitor";
import VClamp from "vue-clamp";

export default {
	components: {
		VClamp
	},
	data() {
		return {
			path: process.env.VUE_APP_API_URL,
			color: "",
			message: "",
			snackbar: false,
			drawer: false,

			absolute: true,
			overlay: true,
			opacity: 0.3,
		};
	},
	watch: {
    pastCohort() {
      this.getCompetitors();
    },
  },
	mounted() {
		window.onscroll = () => {
			this.changeColor();
		};
	},
	created() {
		window.scrollTo(0, 0);
		this.changeHeaderColor("white");
		this.changeTitleColor("red--text");
		this.changeButtonColor("red--text hidden-sm-and-down text-none caption");
		this.getCompetitors()
	},
	methods: {
		...mapActions([
			"changeHeaderColor",
			"changeTitleColor",
			"changeButtonColor",
			"filterCompetitors",
			"startCompetitorLoader",
			"stopCompetitorLoader"
		]),
		changeColor() {
			if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
				this.changeHeaderColor("white");
				this.changeTitleColor("black--text");
				this.changeButtonColor("black--text hidden-sm-and-down text-none caption");
			} else {
				this.changeHeaderColor("white");
				this.changeTitleColor("white--text");
				this.changeButtonColor("white--text hidden-sm-and-down text-none caption");
			}
		},
		getCompetitors() {
			this.startCompetitorLoader()
			apiCall({
				url:
					"/api/competitor?type=public&page=" + this.competitorPagination.current_page + "&pastCohort=" +
					this.$route.params.slug,
				method: "GET",
			})
				.then((resp) => {
					this.filterCompetitors(resp);
					this.stopCompetitorLoader()
				})
				.catch((error) => {
					this.message = "An Error Occurred";
					this.color = "error";
					this.snackbar = true;
					this.stopCompetitorLoader()
				});
		},
		removeTags(str) {
			if ((str===null) || (str===''))
				return false;
			else
				str = str.toString();
			return str.replace( /(<([^>]+)>)/ig, '');
		}
	},
	computed: {
		...mapGetters([
			"darkState",
			"competitors",
			"competitorPagination",
			"competitorLoader"
		]),
		length: function () {
			return Math.ceil(
				this.competitorPagination.total / this.competitorPagination.per_page
			);
		},
		pastCohort(){
			return this.$store.getters.allPastCohorts.find((allPastCohort) => allPastCohort.slug == this.$route.params.slug);
		}
	},
};
</script>
