import apiCall from '@/utils/api'

const state = {
	images: [],
	allImages: [],
    imagePagination: {
		search: ' ',
		current_page: 1,
		per_page: 0,
	    total: 0,
	    visible: 10
	},
    imageLoader: false
};

const getters = {
	images: (state) => state.images,
	allImages: (state) => state.allImages,
    imageLoader: (state) => state.imageLoader,
    imagePagination: (state) => state.imagePagination
}

const actions = {
	async fetchAllImages({commit}, page) {
		const response = await apiCall({url: `/api/image?type=all`, method: 'GET' });
		commit('setAllImages', response)
        commit('stopLoader', response)
	},
    async fetchImages({commit}, page) {
		const response = await apiCall({url: `/api/image?page=${page}`, method: 'GET' });
		commit('setImages', response)
        commit('stopLoader', response)
	},
    async filterImages({commit, state},resp){
		commit('setImages', resp)
	},
	async startImageLoader({commit, state},resp){
		commit('startLoader', resp)
	},
	async stopImageLoader({commit, state},resp){
		commit('stopLoader', resp)
	},
};

const mutations = {
	setImages: (state, images) => {
		state.images = images.data
		state.imagePagination.current_page = images.current_page
		state.imagePagination.total = images.total
		state.imagePagination.per_page = images.per_page
	},
	setAllImages: (state, images) => {
		state.allImages = images
	},
    startLoader: (state) => state.imageLoader = true,
	stopLoader: (state) => state.imageLoader = false,
};

export default {
	state,
	getters,
	actions,
	mutations
}
